import {
    PaymentElement
} from '@stripe/react-stripe-js'
import { useState, useEffect } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { showError, ExternalDomain } from '../../../common/Common';

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const confirmationNumber = Math.floor((Math.random() * 1000000) + 1);
    const subDomainUrl = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl;
    const isAutoPay = props.props.isAutoPay;
    const isIframe = props.props.isIframe;
    const pi = props.props.payment_intent;
    const pics = props.props.payment_intent_client_secret;
    const PaymentId = props.props.paymentId;
    const policyNumber = props.props.policyNumber;
    const fullName = props.props.fullName;
    const invoiceNumber = props.props.invoiceNumber;
    const isPFPaid = props.props.isPFPaid
    const isDBPaid = props.props.isDBPaid
    const isPaidInFull = props.props.isPaidInFull
    const isPayFull = props.props.isPayFull
    const isDownPayment = props.props.isDownPayment
    const isMonthlyPayment = props.props.isMonthlyPayment
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);
        if (isIframe === false) {
            if (subDomainUrl.toLowerCase() === "xgen") {
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        // return customer to payment status after a payment,
                        return_url: `${window.location.origin}/payment-status?pN=` + policyNumber + '&fN=' + fullName + '&paymentId=' + PaymentId + '&textActive=' + props.props.textActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&invoiceNumber=' + invoiceNumber 
                    },
                });

                if (error) {
                    showError(error.message);
                } else {
                    //showError("An unexpected error occured.");
                }

                setIsLoading(false);
            }
            else {
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        // return customer to payment status after a payment,
                        return_url: `${window.location.origin}/${subDomainUrl.toLowerCase()}/payment-status?pN=` + policyNumber + '&fN=' + fullName + '&paymentId=' + PaymentId + '&textActive=' + props.props.textActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&invoiceNumber=' + invoiceNumber + '&isPFPaid=' + isPFPaid + '&isDBPaid=' + isDBPaid + '&isPaidInFull=' + isPaidInFull +'&isPayFull=' + isPayFull + '&isDownPayment=' + isDownPayment + '&isMonthlyPayment=' + isMonthlyPayment
                    },
                });
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Otherwise, customer will be redirected to
                // `return_url`. For some payment methods like iDEAL, customer will
                // be redirected to an intermediate site first to authorize the payment, then
                // redirected to the `return_url`.
                if (error) {
                    showError(error.message);
                } else {
                    //showError("An unexpected error occured.");
                }

                setIsLoading(false);
            }
        }
        else {
            if (subDomainUrl.toLowerCase() === "xgen") {
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        // return customer to payment status after a payment,
                        return_url: '',
                    },
                    redirect: 'if_required'
                });
                if (error) {
                    showError(error.message);
                } else {
                    //showError("An unexpected error occured.");
                }
                
                parent.$("iframe#paymentresult").each(function (iel, el) {
                    if (el.contentWindow === window) {
                        /*setIsLoading(false);*/
                        parent.$("iframe#paymentresult").attr("src", window.location.origin + '/payment-widget-status?pN=' + props.props.policyNumber + '&fN=' + props.props.fullName + '&paymentId=' + props.props.paymentId + '&textActive=' + props.props.textActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&payment_intent=' + pi + '&payment_intent_client_secret=' + pics + '&email_address=' + props.props.emailAddress + '&agentId=' + props.props.agentId + '&invoiceNumber=' + props.props.invoiceNumber + '&redirect_status=succeeded');
                    }
                });
                parent.$("iframe#iframecodewoparameters").each(function (iel, el) {
                    if (el.contentWindow === window) {
                        /*setIsLoading(false);*/
                        parent.$("iframe#iframecodewoparameters").attr("src", window.location.origin + '/payment-widget-status?pN=' + props.props.policyNumber + '&fN=' + props.props.fullName + '&paymentId=' + props.props.paymentId + '&textActive=' + props.props.textActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&payment_intent=' + pi + '&payment_intent_client_secret=' + pics + '&email_address=' + props.props.emailAddress + '&agentId=' + props.props.agentId + '&invoiceNumber=' + props.props.invoiceNumber + '&redirect_status=succeeded');
                    }
                });
                parent.$("iframe#iframecodeparameters").each(function (iel, el) {
                    if (el.contentWindow === window) {
                        /*setIsLoading(false);*/
                        parent.$("iframe#iframecodeparameters").attr("src", window.location.origin + '/payment-widget-status?pN=' + props.props.policyNumber + '&fN=' + props.props.fullName + '&paymentId=' + props.props.paymentId + '&textActive=' + props.props.textActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&payment_intent=' + pi + '&payment_intent_client_secret=' + pics + '&email_address=' + props.props.emailAddress + '&agentId=' + props.props.agentId + '&invoiceNumber=' + props.props.invoiceNumber + '&redirect_status=succeeded');
                    }
                });
            }
            else {
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        // return customer to payment status after a payment,
                        return_url: '',
                    },
                    redirect: 'if_required'
                });
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Otherwise, customer will be redirected to
                // `return_url`. For some payment methods like iDEAL, customer will
                // be redirected to an intermediate site first to authorize the payment, then
                // redirected to the `return_url`.
                if (error) {
                    showError(error.message);
                } else {
                    //showError("An unexpected error occured.");
                }

                parent.$("iframe#paymentresult").each(function (iel, el) {
                    if (el.contentWindow === window) {
                        /*setIsLoading(false);*/
                        parent.$("iframe#paymentresult").attr("src", window.location.origin + '/' + subDomainUrl.toLowerCase() + '/payment-widget-status?pN=' + props.props.policyNumber + '&fN=' + props.props.fullName + '&paymentId=' + props.props.paymentId + '&textActive=' + props.props.textActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&payment_intent=' + pi + '&payment_intent_client_secret=' + pics + '&email_address=' + props.props.emailAddress + '&agentId=' + props.props.agentId + '&invoiceNumber=' + props.props.invoiceNumber + '&redirect_status=succeeded');
                    }
                });
                parent.$("iframe#iframecodewoparameters").each(function (iel, el) {
                    if (el.contentWindow === window) {
                        /*setIsLoading(false);*/
                        parent.$("iframe#iframecodewoparameters").attr("src", window.location.origin + '/' + subDomainUrl.toLowerCase() + '/payment-widget-status?pN=' + props.props.policyNumber + '&fN=' + props.props.fullName + '&paymentId=' + props.props.paymentId + '&textActive=' + props.props.textActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&payment_intent=' + pi + '&payment_intent_client_secret=' + pics + '&email_address=' + props.props.emailAddress + '&agentId=' + props.props.agentId + '&invoiceNumber=' + props.props.invoiceNumber +  '&redirect_status=succeeded');
                    }
                });
                parent.$("iframe#iframecodeparameters").each(function (iel, el) {
                    if (el.contentWindow === window) {
                        /*setIsLoading(false);*/
                        parent.$("iframe#iframecodeparameters").attr("src", window.location.origin + '/' + subDomainUrl.toLowerCase() + '/payment-widget-status?pN=' + props.props.policyNumber + '&fN=' + props.props.fullName + '&paymentId=' + props.props.paymentId + '&textActive=' + props.props.textActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&payment_intent=' + pi + '&payment_intent_client_secret=' + pics + '&email_address=' + props.props.emailAddress + '&agentId=' + props.props.agentId + '&invoiceNumber=' + props.props.invoiceNumber + '&redirect_status=succeeded');
                    }
                });
            }
        }
        
    }


    var Button = ExternalDomain(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutColor, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutHoverFocus, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId);

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <Button disabled={isLoading || !stripe || !elements} id="submit" className="btn btn-primary btn-large mt-2">
                <span id="button-text">
                    {isLoading ? <div className="spinner-border"></div> : "Pay now"}
                </span>
            </Button>
        </form>
    )
}