import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { showError, authHeader, subDomain, ExternalDomain, LoggedOutDetails } from '../../../common/Common';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from '../../layout/SiteFooter';
import { GetPaymentLinksById, GetTextActiveByPaymentId, GetPaymentIntent, UpdatePaymentLinkExpiry, GetEmbedLinkDocuSign, SaveSignedStatus, UpdateAutoPay, GetPolicyFees, GetRemainingBalance, GetDownPaymentPaidStatus, CheckPremiumFinance, GetFPPaymentModel, CheckDirectBilling, CheckMultiplePolicies, GetPolicyByCompanyName } from '../../../services/PaymentServices';
import { GetAISResponseByPaymentId } from '../../../services/AISServices';
import { GetUserProfile } from '../../../services/UserServices';
import { Error } from '../../../common/Setting';
import { FlowGroups, PartnerId } from '../../../common/Enum';
import validator from 'validator';
import $ from 'jquery';
import { LoaderContents } from "../../layout/LoaderContents";
import { CustomCurrencyFormat, DateFormat, PublishableKey } from "../../../common/Setting";
import CurrencyFormat from 'react-currency-format';
//import StripeCheckout from "react-stripe-checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Modal from 'react-modal';

const stripePromise = loadStripe(PublishableKey);
import CheckoutForm from './CheckoutForm';
import { GetStripeCustomerNonAuthorized } from '../../../services/PaymentServices';
import { Buffer } from 'buffer';

class MakePayment extends Component {
    constructor(props) {
        super(props);
        this.IsAutoPay = this.IsAutoPay.bind(this);
        this.state = {
            UserDetails: authHeader(), // by local storage 
            queryParams: new URLSearchParams(window.location.search),
            mdl: [],
            CreditCardExpiryMonth: "",
            Errors: {},
            SaveLoaderStatus: false,
            IsAutoPay: true,
            UpdateAutoPayMdl: {
                IsAutoPay: true,
                PaymentId: ''
            },
            UserProfileMdl: [],
            fullName: "",
            PaymentAmountOptions: 1,
            apr: null,
            PFCoverageType: null,
            DBCoverageType: null,
            //insuranceCompany: null,
            address: null,
            policyEffectiveDate: null,
            termLength: 0,
            pmtAmount: null,
            phoneNumber: null,
            SubDomain: subDomain(),
            IsTextActive: false,
            IsTermsandConditions: false,
            PFIsTermsandConditions: false,
            PIFIsTermsandConditions: false,
            isTextAct: false,
            clientSecret: '',
            transferGroup: '',
            customerId: '',
            isExpired: false,
            isPaid: false,
            RemainingBalance: null,
            RemainingPayments: null,
            LoaderStatus: false,
            CustomerDetailMdl: {},
            CreatedByName: null,
            premiumFinanceAgreementLink: null,
            totalpremium: null,
            url: "",
            DowPaymentPaidStatus: false,
            ispremiumfinance: false,
            PFAMonthlyAmount: null,
            PFATotalOfPayments: null,
            PFACurrentAPR: null,
            PFATotalPremiumAmount: null,
            PFATotalDownPayAmount: null,
            paymentProcessId: 1,
            fppUrl: "",
            achMemoId: Math.random().toString().substring(2, 8),
            urlEncodedValue: "",
            SelectedType: null,
            isdirectbilling: false,
            DBIsTermsandConditions: false,
            DBDisclosureAgreementIsTermsandConditions: false,
            DBPaymentAmount: null,
            DBDownPayAmount: null,
            DBTermLength: 0,
            DBReport: null,
            PFPaymentAmount: null,
            PFDownPayAmount: null,
            EmbedLink: null,
            PFTermLength: 0,
            PFReport: null,
            PFAPR: 0,
            isMultiplePolicies: false,
            AllCompanyData: [],
            selectedpolicy: [],
            paymentmode: null,
            checked: true,
            checkedItems: {},
            showModal: false,
            modalMessage: "",
            isPFPaid: false,
            isDBPaid: false,
            isPaidInFull: false,
            isPFShow: false,
            isDBShow: false,
            LoggedOutDetails: LoggedOutDetails(),
            isPayFull: false,
            isDownPayment: false,
            isMonthlyPayment: false,
            showIframe: false,
            agreementStatus: "You have not yet agreed to the terms.",
            docuSignUrl: "",
            isSignedAgreement: false,
            dbdocuSignUrl: "",
            dbisSignedAgreement: false,
            DBDocusignLoaderStatus: false,
            ServiceFee: 5,
            isDirectBilling: false,
            isPremiumFinance: false,
            AllPolicyTaxes: [],
            TaxesAmount: 0,
            paymentAccount: '',
            scheduleDate: '',
            paymentAmount: '',
            otherAmount: '',
            AlreadyAutoPay: false,
            billingAccountNumber: null,
            linknotexists: false 
        }
        this.handleChangePaymentData = this.handleChangePaymentData.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleOtherAmountChange = this.handleOtherAmountChange.bind(this);
    }

    // this is a onload method
    componentDidMount() {
        this.setState({ LoaderStatus: true });
        let pmtAmt;

        if (this.state.queryParams.has("PaymentId")) {
            UpdatePaymentLinkExpiry(this.state.queryParams.get("PaymentId")).then(data => {});

            GetPaymentLinksById(this.state.queryParams.get("PaymentId")).then(async data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        this.setState({ linknotexists: false });
                        let res = response.data;
                        this.setState({ mdl: res });
                        pmtAmt = res.dbDownPayAmount != null ? (res.dbDownPayAmount).toFixed(2) : 0;
                        this.setState({
                            pmtAmount: pmtAmt, totalpremium: res.paymentAmount, fullName: res.name, isExpired: res.isExpired, isPaid: res.paymentStatus, phoneNumber: res.phoneNumber, apr: res.apr, termLength: res.termLength,
                            address: res.address, policyEffectiveDate: res.policyEffectiveDate, CreatedByName: res.createdByName, url: res.subDomain, paymentProcessId: res.paymentProcessId,
                            DBPaymentAmount: res.dbPaymentAmount, DBDownPayAmount: res.dbDownPayAmount, DBTermLength: res.dbTermLength, DBReport: res.dbReport,
                            PFPaymentAmount: res.pfPaymentAmount, PFDownPayAmount: res.pfDownPayAmount, PFTermLength: res.pfTermLength, PFReport: res.pfReport, PFAPR: res.pfapr, billingAccountNumber: res.billingAccountNumber,
                            isPFShow: res.isPFPaid, isDBShow: res.isDBPaid, isPaidInFull: res.isPaidInFull, EmbedLink: res.embedLink, isSignedAgreement: res.isSignedAgreement, DBCoverageType: res.dbCoverageType,
                            PFCoverageType: res.pfCoverageType, isDirectBilling: res.isDirectBilling, isPremiumFinance: res.isPremiumFinance, DBEmbedLink: res.dbembedLink, isSignedAgreement: res.dbisSignedAgreement
                        });
                        if (res.isPFPaid) {
                            this.setState({ totalpremium: (res.pfPaymentAmount * res.termLength) });
                        }
                        else if (res.isDBPaid) {
                            this.setState({ totalpremium: (res.dbPaymentAmount * res.termLength) });
                        }
                        else if (res.isPaidInFull) {
                            this.setState({ totalpremium: (res.remainingBalance) });
                        }
                        else {
                            this.setState({ totalpremium: (res.paymentAmount) });
                        }

                        if (res.paymentStatus) {
                            this.setState({ RemainingBalance: res.remainingBalance, RemainingPayments: res.remainingPayments });
                        }
                        else {
                            this.setState({ RemainingBalance: res.paymentAmount, RemainingPayments: res.termLength });
                        }
                        if (!res.paymentStatus) {
                            if ((JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isFinanceProPF && !JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isAISDB) || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "knight") || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "rt")) {
                                if (!res.isSignedAgreement) {
                                    let response = await GetEmbedLinkDocuSign(this.state.queryParams.get("PaymentId"), res.name, res.emailAddress, res.embedLink, res.clickwrapId, true);
                                    if (response.message !== Error) {
                                        if (response.status === true) {
                                            this.setState({ docuSignUrl: response.data });
                                        } else {
                                            showError(response.message);
                                        }
                                    }
                                }
                            }
                            if ((JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isFinanceProPF && JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isAISDB) || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "knight") || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "rt")) {
                                if (!res.dbIsSignedAgreement) {
                                    let response = await GetEmbedLinkDocuSign(this.state.queryParams.get("PaymentId"), res.name, res.emailAddress, res.dbembedLink, res.dbClickwrapId, false);
                                    if (response.message !== Error) {
                                        if (response.status === true) {
                                            this.setState({ dbdocuSignUrl: response.data });
                                        } else {
                                            showError(response.message);
                                        }
                                    }
                                }
                            }
                        }

                        this.setState(Object.assign(this.state.mdl, { emailAddress: res.emailAddress }));
                        this.setState(Object.assign(this.state.UpdateAutoPayMdl, { IsAutoPay: res.isAutoPay, AlreadyAutoPay: res.isAutoPay }));
                        this.setState({ ispremiumfinance: res.premiumFinance, isdirectbilling: res.directBillingFinance, DownPaymentPaidStatus: res.paymentStatus });
                        this.setState({ LoaderStatus: false });
                    } else {
                        this.setState({ linknotexists: true });
                    }
                }
            });

            GetTextActiveByPaymentId(this.state.queryParams.get("PaymentId")).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        let res = response.data;
                        this.setState({ IsTextActive: res, isTextAct: res });
                    } else {
                        showError(response.message);
                    }
                }
            })
            GetPolicyFees(this.state.queryParams.get("PaymentId")).then(res => {
                let response = res;
                if (response.status === true) {
                    this.setState({ AllPolicyTaxes: response.data})
                    //this.setAmountAndDescription(response.data);
                } else {
                    showError(response.message);
                }
            })
        }
        else {
            if (this.state.UserDetails != null) {
                this._GetUserProfile();
            }
        }

        $("input[name='phoneNumber']").keyup(function () {
            $(this).val($(this).val().replace(/^(\d{3})(\d{3})(\d+)$/, "($1)$2-$3"));
        });
    }

    setAmountAndDescription = (data) => {
        let amount = 0;
        data.forEach((item) => {
            amount += parseFloat(item.amount);
        });
        this.setState({ TaxesAmount: amount });
    }

    _GetPaymentIntent = async () => {
        const amount = this.state.pmtAmount;
        this._encodeUrlValues(amount);
        await GetPaymentIntent(amount * 100, this.state.fullName, this.state.emailAddress, this.state.phoneNumber, this.state.UpdateAutoPayMdl, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl).then((res) => {
            this.setState({ clientSecret: { clientSecret: res.clientSecret }, transferGroup: res.transferGroup, customerId: res.customerId });
            this.setState({ SaveLoaderStatus: false });
        });
    }

    _UpdateAutoPay = (updateAutoPayMdl) => {
        UpdateAutoPay(updateAutoPayMdl).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState(Object.assign(this.state.UpdateAutoPayMdl, { IsAutoPay: response.data.isAutoPay }));
                } else {
                    showError(response.message);
                }
            }
        });
    }

    _PayNext_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this.onButtonEvent("PayNext");
        };
    };

    _ModelPayment_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._SelectPaymentMethod();

        };
    };
    _SelectPaymentMethod = () => {
        this.setState({ clientSecret: '' });
        this.setState({ SaveLoaderStatus: true });
        this._GetPaymentIntent();
        window.$('#modalPayment').modal('show');
    }
    //for select payment amount option
    onChangePaymentAmountOption = (event) => {
        let SelectedOption = parseInt(event.target.value);
        this.setState({ PaymentAmountOption: SelectedOption });
    }
    Validation = (mdl) => {
        let errors = {};
        let count = 0;

        //if (this.state.SelectedType === "DirectBill") {
        //    if (!this.state.IsTermsandConditions) {
        //        errors['IsEmpty_IsTermsandConditions'] = true;
        //        errors['IsTermsandConditionsEmptyMsg'] = "Please agree to terms and condition";
        //        count++;
        //    }
        //}

        if (!this.state.IsTextActive && !this.state.isTextAct) {
            errors['IsEmpty_IsTextActive'] = true;
            errors['IsTextActiveEmptyMsg'] = "Please agree to recieve automated promotional messages";
            count++;
        }

        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }

    // get user profile
    _GetUserProfile = async () => {
        GetUserProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.setState({ UserProfileMdl: response.data });
                        this.setState({ mdl: response.data.userInfoMdl });
                        this.setState(Object.assign(this.state.mdl, { emailAddress: response.data.email }));
                        this.setState({ fullName: response.data.userInfoMdl.fullName });
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });
    }
    // data bind
    handleChangePaymentData(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(Object.assign(this.state.mdl, { [name]: value }));
    }
    //for select payment type
    onChangeValue = (event) => {
        let PaymentType = parseInt(event.target.value);
        this.setState({ Errors: {} });
    }


    IsAutoPay = (event) => {

        this.setState(Object.assign(this.state.UpdateAutoPayMdl, { IsAutoPay: !this.state.IsAutoPay }));
        this.setState(Object.assign(this.state.UpdateAutoPayMdl, { PaymentId: this.state.queryParams.get("PaymentId") }));
        this._UpdateAutoPay(this.state.UpdateAutoPayMdl);
    }
    IsTextActive = (event) => {
        if (event.target.checked) {
            this.setState({ IsTextActive: true });
        }
        else {
            this.setState({ IsTextActive: false });
        }
    }
    IsTermsandConditions = (event) => {
        if (event.target.checked) {
            window.$('#modalTermsandConditions').modal('show');
            //this.setState({ IsTermsandConditions: true });
        }
        else {
            this.setState({ IsTermsandConditions: false });
        }
    }

    handleAccountChange(event) {
        this.setState({ paymentAccount: event.target.value });
    }

    handleDateChange(event) {
        this.setState({ scheduleDate: event.target.value });
    }

    handleAmountChange(event, pm, process) {
        if (process === "PFMonthly") {
            this.setState({ paymentmode: "PF" });
            this.setState({ isPFPaid: true, isDBPaid: false, isPaidInFull: false });
            this.setState({ isPayFull: false, isDownPayment: true, isMonthlyPayment: false, pmtAmount: this.state.PFDownPayAmount + this.state.ServiceFee });
            if (this.state.DownPaymentPaidStatus === true) {
                this.setState({ isPayFull: false, isDownPayment: false, isMonthlyPayment: true, pmtAmount: this.state.PFPaymentAmount + this.state.ServiceFee });
            }
        }
        else if (process === "DBMonthly") {
            this.setState({ SelectedType: "DirectBill" });
            this.setState({ isPFPaid: false });
            this.setState({ isDBPaid: true });
            this.setState({ isPaidInFull: false });
            this.setState({ pmtAmount: (this.state.DBDownPayAmount + this.state.ServiceFee) });
            this.setState({ isPayFull: false });
            this.setState({ isDownPayment: true });
            this.setState({ isMonthlyPayment: false });
            if (this.state.DownPaymentPaidStatus === true) {
                this.setState({ pmtAmount: this.state.DBPaymentAmount + this.state.ServiceFee });
                this.setState({ paymentAmount: this.state.DBPaymentAmount + this.state.ServiceFee });
                this.setState({ isPayFull: false });
                this.setState({ isDownPayment: false });
                this.setState({ isMonthlyPayment: true });
            }
        }
        else if (process === "PIF") {
            this.setState({ paymentmode: "PIF" });
            this.setState({ SelectedType: "" });
            this.setState({ isPFPaid: false });
            this.setState({ isDBPaid: false });
            this.setState({ isPaidInFull: true });
            this.setState({ isPayFull: true });
            this.setState({ isDownPayment: false });
            this.setState({ isMonthlyPayment: false });
            //this.setState({ Errors: {} });
            this.setState({ pmtAmount: (this.state.totalpremium + this.state.ServiceFee) });
        }
        //{ CustomCurrencyFormat(this.state.pmtAmount) ?? '' }
        this.setState({ paymentAmount: event.target.value, otherAmount: '', errorMessage: '', pmtAmount: pm });
    }

    handleOtherAmountChange(event, pm) {
        const value = event.target.value;
        if (value > this.state.totalpremium + this.state.ServiceFee) {
            this.setState({ errorMessage: 'Other amount should not exceed the total balance due.' });
        } else {
            this.setState({ otherAmount: value, errorMessage: '', pmtAmount: value });
        }
    }

    PFIsTermsandConditions = (event) => {
        if (event.target.checked) {
            this.setState({ PFIsTermsandConditions: true });
        }
        else {
            this.setState({ PFIsTermsandConditions: false });
        }
    }
    PIFIsTermsandConditions = (event) => {
        if (event.target.checked) {
            this.setState({ PIFIsTermsandConditions: true });
        }
        else {
            this.setState({ PIFIsTermsandConditions: false });
        }
    }
    DBIsTermsandConditions = (event) => {
        if (event.target.checked) {
            this.setState({ DBIsTermsandConditions: true });
        }
        else {
            this.setState({ DBIsTermsandConditions: false });
        }
    }

    DBDisclosureAgreementIsTermsandConditions = (event) => {
        if (event.target.checked) {
            this.setState({ DBDisclosureAgreementIsTermsandConditions: true });
            if (this.state.dbdocuSignUrl) {
                this.setState({ DBDocusignLoaderStatus: true });
                if ((JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isFinanceProPF && JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isAISDB) || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "knight") || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "rt")) {
                    if (this.state.dbisSignedAgreement) {
                        this.setState({ showIframe: false, DBDocusignLoaderStatus: false });
                    }
                    else {
                        this.setState({ showIframe: true });
                        docuSignClick.Clickwrap.render({
                            agreementUrl: this.state.dbdocuSignUrl,
                            onAgreed: () => {
                                SaveSignedStatus(this.state.queryParams.get("PaymentId"), false, true).then(data => {
                                    let response = data;
                                    this.setState({ DBDocusignLoaderStatus: false });
                                    this.setState({ dbisSignedAgreement: true });
                                });
                            }
                        }, "#db-terms-of-service");
                    }
                }
                else {
                    this.setState({ showIframe: false, DBDocusignLoaderStatus: false });
                }
            }
        }
        else {
            this.setState({ showIframe: false, DBDocusignLoaderStatus: false });
            this.setState({ DBDisclosureAgreementIsTermsandConditions: false });
        }
    }
    onTermsandConditionsAccept = () => {
        window.$('#modalTermsandConditions').modal('hide');
        this.setState({ IsTermsandConditions: true });
    }

    onButtonEvent = (NextStep) => {
        if (NextStep === 'NameBack') {
            $('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
            $('.step-makepayment-fullname').removeClass('form-dismiss').addClass('form-selected');
            $('.step-makepayment-fullname').addClass('form-selected-Out');
        }
        if (NextStep === 'PayNext') {
            if (this.state.DownPaymentPaidStatus === true) {
                this.setState({ pmtAmount: this.state.mdl.monthlyAmount });
            }
            $('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
            $('.step-makepayment-pay').removeClass('form-dismiss').addClass('form-selected');
        }
        if (NextStep === 'PayFull') {
            
            let errors = {};
            let count = 0;
            if (!this.state.PIFIsTermsandConditions) {
                errors['IsEmpty_PIFIsTermsandConditions'] = true;
                errors['PIFIsTermsandConditionsEmptyMsg'] = "Please agree to terms and condition";
                count++;
            }
            this.setState({ paymentmode: "PIF" });
            this.setState({ SelectedType: "" });
            this.setState({ isPFPaid: false });
            this.setState({ isDBPaid: false });
            this.setState({ isPaidInFull: true });
            this.setState({ Errors: errors });
            if (count === 0) {
                this.setState({ isPayFull: true });
                this.setState({ isDownPayment: false });
                this.setState({ isMonthlyPayment: false });
                //this.setState({ Errors: {} });
                this.setState({ pmtAmount: (this.state.totalpremium + this.state.ServiceFee) });
                $('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
                $('.payment-details-form').removeClass('form-dismiss').addClass('form-selected PayFull');
                return true;
            }
        }
        if (NextStep === 'DirectBill') {
            this.setState({ SelectedType: "DirectBill" });
            let errors = {};
            let count = 0;
            if (!this.state.DBIsTermsandConditions) {
                errors['IsEmpty_DBIsTermsandConditions'] = true;
                errors['DBIsTermsandConditionsEmptyMsg'] = "Please agree to terms and condition";
                count++;
            }
            this.setState({ isPFPaid: false });
            this.setState({ isDBPaid: true });
            this.setState({ isPaidInFull: false });
            this.setState({ Errors: errors });
            if (count === 0) {
                this.setState({ pmtAmount: (this.state.DBDownPayAmount + this.state.ServiceFee) });
                this.setState({ isPayFull: false });
                this.setState({ isDownPayment: true });
                this.setState({ isMonthlyPayment: false });
                if (this.state.DownPaymentPaidStatus === true) {
                    this.setState({ pmtAmount: this.state.DBPaymentAmount + this.state.ServiceFee });
                    this.setState({ isPayFull: false });
                    this.setState({ isDownPayment: false });
                    this.setState({ isMonthlyPayment: true });
                }
                $('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
                $('.step-makepayment-pay').removeClass('form-dismiss').addClass('form-selected');
                return true;
            }
        }
        if (NextStep === 'PayMonthly') {
            this.setState({ paymentmode: "PF" });
            let errors = {};
            let count = 0;
            if (!this.state.PFIsTermsandConditions) {
                errors['IsEmpty_PFIsTermsandConditions'] = true;
                errors['PFIsTermsandConditionsEmptyMsg'] = "Please agree to terms and condition";
                count++;
            }

            this.setState({ isPFPaid: true, isDBPaid: false, isPaidInFull: false, Errors: errors });
            if (count === 0) {
                this.setState({ isPayFull: false, isDownPayment: true, isMonthlyPayment: false, pmtAmount: this.state.PFDownPayAmount + this.state.ServiceFee });
                if (this.state.DownPaymentPaidStatus === true) {
                    this.setState({ isPayFull: false, isDownPayment: false, isMonthlyPayment: true, pmtAmount: this.state.PFPaymentAmount + this.state.ServiceFee });
                }
                //$('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
                //$('.step-makepayment-pay').removeClass('form-dismiss').addClass('form-selected PF');

                if (this.state.docuSignUrl) {
                    this.setState({ LoaderStatus: true });
                    if ((JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isFinanceProPF && !JSON.parse(localStorage.getItem("LoggedOutDetails"))?.isAISDB) || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "knight") || (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl == "rt")) {
                        if (this.state.isSignedAgreement) {
                            this._SelectPaymentMethod();
                            this.setState({ showIframe: false, LoaderStatus: false });
                        }
                        else {
                            this.setState({ showIframe: true });
                            docuSignClick.Clickwrap.render({
                                agreementUrl: this.state.docuSignUrl,
                                onAgreed: () => {
                                    SaveSignedStatus(this.state.queryParams.get("PaymentId"), true, false).then(data => {
                                        let response = data;
                                        this.setState({ LoaderStatus: false });
                                        this.setState({ isSignedAgreement: true });
                                        this._SelectPaymentMethod()
                                    });
                                }
                            }, "#ds-terms-of-service");
                        }
                    }
                    else {
                        this.setState({ showIframe: false, LoaderStatus: false });
                        this._SelectPaymentMethod();
                    }
                }
                else {
                    this._SelectPaymentMethod();
                    this.setState({ LoaderStatus: true });
                    this.setState({ showIframe: false, LoaderStatus: false });
                }

                return true;
            }


        }
        if (NextStep === 'NextPaymentType') {
            $('.step-makepayment-fullname').removeClass('form-selected').addClass('form-dismiss');
            $('.step-makepayment-paymentType').removeClass('form-dismiss').addClass('form-selected');

            // document.getElementById("step-makepayment-pay").style.display = 'block';
            // document.getElementById("step-makepayment-fullname").style.display = 'none';
        }
        if (NextStep === 'PaymentTypeBack') {
            this.setState({ IsTextActive: false });
            this.setState({ IsTermsandConditions: false });
            this.setState({ PFIsTermsandConditions: false });
            this.setState({ PIFIsTermsandConditions: false });
            this.setState({ DBIsTermsandConditions: false }); 
            this.setState({ DBDisclosureAgreementIsTermsandConditions: false });
            this.setState(Object.assign(this.state.Errors, { IsEmpty_IsTermsandConditions: false }));
            this.setState(Object.assign(this.state.Errors, { IsEmpty_IsTextActive: false }));
            $(".step-makepayment-pay").removeClass("PayFull");
            $(".step-makepayment-pay").removeClass("PF");
            $('.step-makepayment-pay').removeClass('form-selected').addClass('form-dismiss');
            $('.step-makepayment-paymentType').removeClass('form-dismiss').addClass('form-selected');
            $('.step-makepayment-paymentType').addClass('form-selected-Out');

        }

    }

    ExpireLink = () => {
        return (
            <>
                <div className="container form-space p-space">
                    <div className="card">
                        <div className="Paymentcard">
                            <h1 className="pt-2 pb-2 text-center">Link Expired !</h1>
                            <p className="text-center">Your payment link is expired.</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    LinkNotFound = () => {
        return (
            <>
            <div className="container form-space p-space">
                <div className="card">
                    <div className="Paymentcard">
                        <h1 className="pt-2 pb-2 text-center">Does Not Exist !</h1>
                        <p className="text-center">The payment link does not exist.</p>
                    </div>
                </div>
            </div>
            </>
        );
    }


    PaymentPaid = () => {
        return (
            <>
                <div className="container form-space p-space">
                    <div className="card">
                        <div className="Paymentcard">
                            <h1 className="pt-2 pb-2 text-center">Already Paid !</h1>
                            <p className="text-center">This payment is already done.</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    openTermsAndConditionsModal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        window.$('#modalTermsandConditions').modal('show');
    };


    PaymentRefund = () => {
        return (
            <>
                <div className="card card-body dashboard-card-newDesign">
                <h1 className="pt-2 pb-2 text-center">Refund Initiated </h1>
                <p className="text-center">You are due a refund of {CustomCurrencyFormat(this.state.RemainingBalance * -1)}. You will receive this refund via check within 7 - 10 days. If you have any questions please call us <a href="tel:(800)624-6308">800-624-6308</a></p>
                </div>
            </>
        );
    }
    StripePaymentForm = () => (
        <Elements stripe={stripePromise}>
            <PaymentForm />
        </Elements>
    );

    _encodeUrlValues = (amount) => {
        var urlparamenters = {
            amount: amount,
            paymentId: this.state.paymentId,
            id: this.state.achMemoId,
            policyNumber: this.state.isPaid ? (this.state.isDirectBilling ? this.state.mdl.policyNumberDB : this.state.mdl.policyNumberFP) : (this.state.isPFPaid ? this.state.mdl.policyNumberFP : this.state.mdl.policyNumberDB),
            fullName: this.state.fullName,
            invoiceNumber: this.state.mdl.invoiceNumber
        };
        const jsonString = JSON.stringify(urlparamenters)
        this.state.urlEncodedValue = Buffer.from(jsonString).toString('base64');
    };

    toggleModal = (message) => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
            modalMessage: message || ""
        }), () => {
            setTimeout(this.closeModal, 2000);
        });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    onClickHandler = () => {
        const hiddenElement = document.querySelector(".section-policytaxes");
        hiddenElement.className.indexOf("collapse show") > -1 ? hiddenElement.classList.remove("show") : hiddenElement.classList.add("show");
    };
    
    render() {
        
        const paymentDetail = {
            policyNumber: this.state.isPaid ? (this.state.isDirectBilling ? this.state.mdl.policyNumberDB : this.state.mdl.policyNumberFP) : (this.state.isPFPaid ? this.state.mdl.policyNumberFP : this.state.mdl.policyNumberDB),
            textActive: this.state.IsTextActive,
            paymentId: this.state.mdl.paymentId,
            fullName: this.state.fullName,
            transferGroup: this.state.transferGroup,
            subDomainUrl: (window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === "make-payment" ? "Xgen" : window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')),
            color: this.state.color,
            hoverFocus: this.state.hoverFocus,
            subdomainid: this.state.subdomainid,
            customerId: this.state.customerId,
            isAutoPay: this.state.UpdateAutoPayMdl.IsAutoPay,
            isIframe: false,
            invoiceNumber: this.state.invoiceNumber,
            isPFPaid: this.state.isPFPaid,
            isDBPaid: this.state.isDBPaid,
            isPaidInFull: this.state.isPaidInFull,
            isPayFull: this.state.isPayFull,
            isDownPayment: this.state.isDownPayment,
            isMonthlyPayment: this.state.isMonthlyPayment

        }
        var Button = ExternalDomain(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutColor, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutHoverFocus, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId);

        return (
            <>
                <div id="ds-terms-of-service"></div>
                <div id="db-terms-of-service"></div>
                {this.state.UserDetails !== null ?
                    <div className="main-wrapper-newDesign logged-in-payment-card">
                        <InternalLayoutHeader />	 {/*internal header user wise */}
                        <>
                            {(!this.state.isExpired && (parseInt(this.state.RemainingBalance) > 0)) && this.PaymentForm()}
                            {this.state.isExpired && this.ExpireLink()}
                            {this.state.linknotexists && this.LinkNotFound()}
                            {(parseInt(this.state.RemainingBalance) == 0) && this.PaymentPaid()}
                            {(parseInt(this.state.RemainingBalance) < 0) && this.PaymentRefund()}
                        </>
                        <SiteFooter />  {/*this call for site footer */}
                    </div>
                    :
                    <>

                        <div className="main-wrapper-newDesign not-logged-in-payment-card">
                        {/*<InternalLayoutHeader />	 /!*internal header user wise *!/*/}
                        <>
                            {(!this.state.isExpired && (parseInt(this.state.RemainingBalance)) > 0) && this.PaymentForm()}
                            {this.state.isExpired && this.ExpireLink()}
                            {this.state.linknotexists && this.LinkNotFound()}
                            {(parseInt(this.state.RemainingBalance) == 0) && this.PaymentPaid()}
                            {(parseInt(this.state.RemainingBalance) < 0) && this.PaymentRefund()}
                        </>
                        <SiteFooter />  {/*this call for site footer */}
                    </div>
                    </>
                }
                <div className="modal fade" tabIndex="-1" id="modalPayment" aria-labelledby="modal Payment methode selection" aria-hidden="true">
                    <div className={"modal-dialog modal-dialog-centered " + (this.state.paymentProcessId === 1 ? '' : 'fppwidth')}>
                        {/*<div className="modal-dialog modal-dialog-centered">*/}
                        <div className="modal-content">
                            <div className="modal-header justify-content-center pt-4 border-0">
                                <h5 className="modal-title-special">How would you like to make your payment?</h5>
                                {(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId == 3) &&
                                    <h7 className="modal-title-special">Credit Card</h7>
                                }
                                {(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId != 3) &&
                                    <h7 className="modal-title-special">ACH or Credit Card</h7>
                                }
                                <button type="button" id="btncloseMdl" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="pt20px">
                                    { /*loader show befoure load contents*/}
                                    {this.state.SaveLoaderStatus && <LoaderContents />}
                                </div>
                                <div className="mx - auto mb-3 row Invoicedetails">
                                    {/*<div className="col-6"><span><b>Invoice Number</b> {this.state.mdl.invoiceNumber ?? ''}</span></div>*/}
                                    <div className="pull-right"><span><b>Amount</b> {CustomCurrencyFormat(this.state.pmtAmount) ?? ''}</span></div>
                                </div>
                                <div className="w-100 m-auto modal-size" id="frmpaymentstatus">
                                    <div className="mx-auto mb-3" >
                                        <div className='input-wrapper-radio row payment-element-form mb-0'>
                                            <div className="col-md-12 col-sm-12 col-12 p-0 position-relative">
                                                {this.state.clientSecret &&
                                                    <Elements stripe={stripePromise} options={this.state.clientSecret}>
                                                        <CheckoutForm props={paymentDetail} />
                                                    </Elements>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.showModal}
                    onRequestClose={this.closeModal}
                    contentLabel="Modal"
                    className="modal-content-DirectBill"
                    overlayClassName=""
                >
                    <div>{this.state.modalMessage}</div>
                </Modal>
            </>
        );
        

    }
    PaymentForm = () => {
        var Button = ExternalDomain(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutColor, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutHoverFocus, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId);
        return (
            <>
                <form className="position-relative m-auto step-makepayment-paymentType form-selected dashboard-card-newDesign">
                    {!this.state.DownPaymentPaidStatus &&
                        <>
                            <fieldset className="step-form flex-wrap px-3 row payment-form-field" data-field="paymentType">
                            <>
                                <div className="pt20px">
                                    {this.state.LoaderStatus && <LoaderContents />}
                                </div>
                                {((this.state.ispremiumfinance && this.state.DownPaymentPaidStatus && this.state.isPFShow) || (this.state.ispremiumfinance && !this.state.DownPaymentPaidStatus && !this.state.isPFShow)) &&
                                    <>
                                        <div Id="premiumFianceSection" className={"form-floating input-wrapper-payment payment-wrapper-input col card premiumFianceSection make-payment-card"}>
                                            <h4 className="label-lower">Premium Finance</h4>
                                            <hr />
                                            <div className="make-payment-body">
                                                {this.state.mdl.monthlyAmount === 0.00 &&
                                                    <div className="make-payment-inner">
                                                        <p className="card-text">Paid in Full</p>
                                                    </div>
                                                }
                                                {this.state.mdl.monthlyAmount !== 0.00 &&
                                                    <>
                                                        <div className="make-payment-inner">
                                                            <p className="make-payment-header">{JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLabel2}</p>
                                                            <span className="value"> {CustomCurrencyFormat(this.state.PFPaymentAmount)}</span>
                                                        </div>
                                                    </>
                                                }
                                                {this.state.DownPaymentPaidStatus === false &&
                                                    <>
                                                        <div className="make-payment-inner">
                                                            <p className="make-payment-header">{JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLabel1}</p>
                                                            <span className="value"> {CustomCurrencyFormat(this.state.PFDownPayAmount)}</span>
                                                        </div>
                                                    </>
                                                }

                                                {this.state.PFAPR !== 0.00 && this.state.PFAPR !== null &&
                                                    <>
                                                        <div className="make-payment-inner">
                                                            <p className="make-payment-header">APR </p>
                                                            <span className="value"> {this.state.PFAPR}%</span>
                                                        </div>
                                                    </>
                                                }
                                                <>
                                                    <div className="make-payment-inner">
                                                        <div className="payment-description">
                                                            <p className="make-payment-header">Installment Fee </p>
                                                            <p className="payment-date">per mo. for {this.state.isPFPaid ? this.state.termLength : this.state.PFTermLength} mo.</p>
                                                        </div>
                                                        <span className="value"> {CustomCurrencyFormat(this.state.ServiceFee)}</span>
                                                    </div>
                                                </>
                                            </div>
                                            <div className="make-payment-bottom">
                                                {this.state.Errors['IsEmpty_PFIsTermsandConditions'] === true ? <div className="has-err-msg">{this.state.Errors['PFIsTermsandConditionsEmptyMsg']}</div> : ''}
                                                <div className='input-wrapper-radio remove-bg payment-card-terms'>
                                                    <div className="col-12 TermsandConditions-modal-check">

                                                        <>
                                                            <input type="checkbox" id="radio-Active" name="PFIsTermsandConditions" checked={this.state.PFIsTermsandConditions} required className={(this.state.Errors['IsEmpty_PFIsTermsandConditions'] === true ? ' has-err form-check-input form-control mx-1' : 'form-check-input form-control mx-1')} onChange={this.PFIsTermsandConditions} />
                                                        </>
                                                        <label className="form-check-label">
                                                        <h6 className="mb-0 AutoPay-label-text">You agree to the <a href="" onClick={this.openTermsAndConditionsModal} style={{ textDecoration: 'underline' }}>terms and conditions</a>.</h6>
                                                        </label>
                                                    </div>
                                                </div>
                                                <button type="button" className="btn-newDesign make-payment-btn" onClick={() => this.onButtonEvent('PayMonthly')} disabled={""}>
                                                    <span className="label">Pay monthly</span>
                                                    <svg className="icon-arrow after">
                                                        <use xlinkHref="#arrow"></use>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                                {((this.state.isdirectbilling && this.state.DownPaymentPaidStatus && this.state.isDBShow) || (this.state.isdirectbilling && !this.state.DownPaymentPaidStatus && !this.state.isDBShow)) &&
                                    <>
                                        <div id="direct-billing-section" className="form-floating input-wrapper-payment payment-wrapper-input col card make-payment-card">
                                            <h4 className="label-lower">Direct Bill</h4>
                                            <hr />
                                            <div className="make-payment-body">
                                                {this.state.mdl.monthlyAmount !== 0.00 &&
                                                    <>
                                                        <div className="make-payment-inner">
                                                            <p className="make-payment-header">{JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLabel2}</p>
                                                            <span className="value"> {CustomCurrencyFormat(this.state.DBPaymentAmount)}</span>
                                                        </div>
                                                    </>
                                                }
                                                {this.state.DownPaymentPaidStatus === false &&
                                                    <>
                                                        <div className="make-payment-inner">
                                                            <p className="make-payment-header">{JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLabel1}</p>
                                                            <span className="value">{CustomCurrencyFormat(this.state.DBDownPayAmount)}</span>
                                                        </div>
                                                    </>
                                                }
                                            <>
                                                <div className="make-payment-inner">
                                                    <div className="payment-description">
                                                        <p className="make-payment-header">Installment Fee </p>
                                                        <p className="payment-date">per mo. for {this.state.isDBPaid ? this.state.termLength : this.state.DBTermLength} mo.</p>
                                                    </div>
                                                    <span className="value"> {CustomCurrencyFormat(this.state.ServiceFee)}</span>
                                                </div>
                                            </>
                                        </div>
                                        <div className="make-payment-bottom">
                                            {this.state.Errors['IsEmpty_DBIsTermsandConditions'] === true ? <div className="has-err-msg">{this.state.Errors['DBIsTermsandConditionsEmptyMsg']}</div> : ''}
                                            <div className='input-wrapper-radio remove-bg'>
                                                <div className="col-12 TermsandConditions-modal-check">
                                                    <input type="checkbox" id="radio-Active" name="DBIsTermsandConditions" checked={this.state.DBIsTermsandConditions} required className={(this.state.Errors['IsEmpty_DBIsTermsandConditions'] === true ? ' has-err form-check-input form-control mx-1' : 'form-check-input form-control mx-1')} onChange={this.DBIsTermsandConditions} />
                                                    <label className="form-check-label">
                                                        <h6 className="mb-0 AutoPay-label-text">You agree to the <a href="" onClick={this.openTermsAndConditionsModal} style={{ textDecoration: 'underline' }}>terms and conditions</a>.</h6>
                                                    </label>
                                                </div>
                                            </div>
                                            <button type="button" className="btn-newDesign make-payment-btn" onClick={() => this.onButtonEvent('DirectBill')}>
                                                <span className="label">Pay Direct</span>
                                                <svg className="icon-arrow after">
                                                    <use xlinkHref="#arrow"></use>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }

                                <div id="pay-in-full" className="form-floating input-wrapper-payment payment-wrapper-input col card payinFull make-payment-card">
                                    <h4 className="label-lower">Pay In Full</h4>
                                    <hr />
                                    <div className="make-payment-body">
                                        <div className="make-payment-inner">
                                            <p className="make-payment-header">Due Today</p>
                                            <span className="value"> {CustomCurrencyFormat(this.state.totalpremium)}</span>
                                        </div>
                                        <div className="make-payment-inner">
                                            <p className="make-payment-header">Service Fee</p>
                                            <span className="value">{CustomCurrencyFormat(this.state.ServiceFee)}</span>
                                        </div>
                                    </div>
                                    <div className="make-payment-bottom">
                                        {this.state.Errors['IsEmpty_PIFIsTermsandConditions'] === true ? <div className="has-err-msg">{this.state.Errors['PIFIsTermsandConditionsEmptyMsg']}</div> : ''}
                                        <div className='input-wrapper-radio remove-bg'>
                                            <div className="col-12 TermsandConditions-modal-check">

                                                <>
                                                    <input type="checkbox" id="radio-Active" name="PIFIsTermsandConditions" checked={this.state.PIFIsTermsandConditions} required className={(this.state.Errors['IsEmpty_PIFIsTermsandConditions'] === true ? ' has-err form-check-input form-control mx-1' : 'form-check-input form-control mx-1')} onChange={this.PIFIsTermsandConditions} />
                                                </>
                                                
                                                <label className="form-check-label">
                                                    <h6 className="mb-0 AutoPay-label-text">You agree to the <a href="" onClick={this.openTermsAndConditionsModal} style={{ textDecoration: 'underline' }}>terms and conditions</a>.</h6>
                                                </label>
                                            </div>
                                        </div>
                                        <button type="button" className="btn-newDesign make-payment-btn" onClick={() => this.onButtonEvent('PayFull')} disabled={""}>
                                            <span className="label">Pay in full</span>
                                            <svg className="icon-arrow after">
                                                <use xlinkHref="#arrow"></use>
                                            </svg>
                                        </button>
                                    </div>

                                </div>
                            </>



                        </fieldset>
                        </>
                    }
                    
                    {this.state.DownPaymentPaidStatus &&
                        <>
                        <fieldset className="step-form flex-wrap px-3 row payment-form-field payment-form payment-account-card" data-field="paymentType">
                            <div className="form-group">
                                <label htmlFor="paymentAccount">Payment Account</label>
                                <select id="paymentAccount" value={this.state.paymentAccount} onChange={this.handleAccountChange}>
                                    <option value="">Select an account</option>
                                    <option value="account1">Card ending in 4222</option>
                                    <option value="account2">ACH ending in 8751</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="scheduleDate">Schedule Date</label>
                                <input type="date" id="scheduleDate" value={this.state.scheduleDate} onChange={this.handleDateChange} />
                            </div>

                            <div className="form-group">
                                <label>Payment Amount</label>
                                <div className="radio-group">
                                    <div className="input-wrapper-radio-newDesign">
                                        
                                        {((this.state.ispremiumfinance && this.state.DownPaymentPaidStatus && this.state.isPFShow) || (this.state.ispremiumfinance && !this.state.DownPaymentPaidStatus && !this.state.isPFShow)) &&
                                            <>
                                                <label htmlFor="due">Payment Amount Due:</label>
                                                <p>{CustomCurrencyFormat(this.state.PFPaymentAmount + this.state.ServiceFee)}</p>
                                                <input
                                                type="radio"
                                                id="due"
                                                name="paymentAmount"
                                                value="due"
                                                checked={this.state.paymentAmount === 'due'}
                                                onChange={(e) => this.handleAmountChange(e, this.state.PFPaymentAmount + this.state.ServiceFee, "PFMonthly")}
                                                className={'form-check-input-newDesign form-control'} />
                                            </>
                                        }
                                        {((this.state.isdirectbilling && this.state.DownPaymentPaidStatus && this.state.isDBShow) || (this.state.isdirectbilling && !this.state.DownPaymentPaidStatus && !this.state.isDBShow)) &&
                                            <>
                                                <label htmlFor="due">Payment Amount Due:</label>
                                                <p>{CustomCurrencyFormat(this.state.DBPaymentAmount + this.state.ServiceFee)}</p>
                                                <input
                                                type="radio"
                                                id="due"
                                                name="paymentAmount"
                                                value="due"
                                                checked={this.state.paymentAmount === 'due'}
                                                onChange={(e) => this.handleAmountChange(e, this.state.DBPaymentAmount + this.state.ServiceFee, "DBMonthly")}
                                                className={'form-check-input-newDesign form-control'} />
                                            </>
                                        }
                                    </div>
                                    <div className="input-wrapper-radio-newDesign">
                                        <label htmlFor="outstanding">Total Outstanding Balance: </label>
                                        <p>{CustomCurrencyFormat(this.state.totalpremium + this.state.ServiceFee)}</p>
                                        <input
                                            type="radio"
                                            id="outstanding"
                                            name="paymentAmount"
                                            value="outstanding"
                                            checked={this.state.paymentAmount === 'outstanding'}
                                            onChange={(e) => this.handleAmountChange(e, this.state.totalpremium + this.state.ServiceFee, "PIF")}
                                            className={'form-check-input-newDesign form-control'} />
                                    </div>
                                    <div className="input-wrapper-radio-newDesign">
                                        <label htmlFor="other">Other Amount</label>
                                        <p></p>
                                        <input
                                            type="radio"
                                            id="other"
                                            name="paymentAmount"
                                            value="other"
                                            checked={this.state.paymentAmount === 'other'}
                                            onChange={(e) => this.handleAmountChange(e, this.state.totalpremium + this.state.ServiceFee)}
                                            className={'form-check-input-newDesign form-control'} />
                                    </div>
                                </div>
                            </div>

                            {this.state.paymentAmount === 'other' && (
                                <div className="form-group">
                                    <label htmlFor="otherAmount">Enter Other Amount</label>
                                    <input
                                        type="number"
                                        id="otherAmount"
                                        value={this.state.otherAmount}
                                        onChange={(e) => this.handleOtherAmountChange(e, this.state.otherAmount + this.state.ServiceFee)}
                                        min="0"
                                        max={this.state.totalpremium + this.state.ServiceFee}
                                    />
                                    {this.state.errorMessage && (
                                        <div className="has-err-msg">{this.state.errorMessage}</div>
                                    )}
                                </div>
                            )}

                            <button className="btn btn-newDesign next-btn" type="button" onClick={() => this._SelectPaymentMethod()}> Next </button>

                            <p className="description-payment">
                                Please call Customer Service at (314) 576-0007 for assistance.
                            </p>
                        </fieldset>

                        </>
                    }

                </form>

                <>
                    <form className="step-form-wrapper-newDesign position-relative w-75 step-makepayment-pay payment-details-form form-dismiss" onKeyDown={this._ModelPayment_EnterButton}>
                        <fieldset className="step-form flex-wrap px-3 row payment-form-field" data-field="verify" data-next="payement">
                            <div className="pt20px">
                                {this.state.DBDocusignLoaderStatus && <LoaderContents />}
                            </div>
                            <div id="payment-details" className="form-floating input-wrapper-payment payment-wrapper-input col card make-payment-card">
                                <h4 className="label-lower">Payment Details</h4>
                                <hr/>
                                <div className="make-payment-body">
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Insured Name: </p>
                                        <span className="value">{this.state.fullName ?? ""}</span>
                                    </div>
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Address: </p>
                                        <span className="value">
                                            {this.state.address ?? ""}
                                            {this.state.mdl.addressLine2 ? `, ${this.state.mdl.addressLine2}` : ''}
                                            {this.state.mdl.addressLine3 ? `, ${this.state.mdl.addressLine3}` : ''}
                                        </span>
                                    </div>
                                    <div className="make-payment-inner">
                                        {(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId != 3) &&
                                        <>
                                            <p className="make-payment-header">General Agent: </p> 
                                            <span className="value">{this.state.createdByName ?? ""}</span>
                                        </>
                                        }
                                        {(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId == 3) &&
                                        <>
                                            <p className="make-payment-header">GEICO Agent: </p>
                                            <span className="value">{this.state.createdByName ?? ""}</span>
                                        </>
                                        }
                                    </div>
                                    <div className="make-payment-inner">
                                        {this.state.isPFPaid &&
                                            <>
                                                <p className="make-payment-header">Coverage Type: </p>
                                                <span className="value">{this.state.PFCoverageType ?? ""}</span>
                                            </>
                                        }
                                        {this.state.isDBPaid &&
                                            <>
                                                <p className="make-payment-header">Coverage Type: </p>
                                                <span className="value">{this.state.DBCoverageType ?? ""}</span>
                                            </>
                                        }
                                        {([3, 5, 6].includes(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId) && this.state.isPaidInFull) &&
                                            <>
                                                <p className="make-payment-header">Coverage Type: </p> 
                                                <span className="value">{this.state.PFCoverageType ?? ""}</span>
                                            </>
                                        }
                                        {((JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId === 2) && this.state.isPaidInFull) &&
                                            <>
                                                <p className="make-payment-header">Coverage Type: </p>
                                                <span className="value">{this.state.DBCoverageType ?? ""}</span>
                                            </>
                                        }
        
                                        {/*{this.state.isPaidInFull &&*/}
                                        {/*    <><label><b>Coverage Type</b></label>: <label>{"Comprehensive Coverage"}</label></>*/}
                                        {/*}*/}
                                        
                                    </div>
                                    {(this.state.UserDetails !== null && this.state.UserDetails.FlowGroupId === FlowGroups.Insured) &&
                                        <div className="make-payment-inner">
                                            <p className="make-payment-header">Phone Number: </p>
                                            <span className="value">{this.state.mdl.phoneNumber ?? ""}</span>
                                        </div>
                                    }
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Email Address: </p> 
                                        <span className="value">{this.state.mdl.emailAddress ?? ""}</span>
                                    </div>
    
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Policy Status: </p>
                                        <span className="value">{"Pending"}</span>
                                    </div>
    
                                    <div className="make-payment-inner">
                                        <p className="make-payment-header">Policy Effective Date: </p> 
                                        <span className="value">{DateFormat(this.state.policyEffectiveDate) ?? ""}</span>
                                    </div>
    
                                <div className="make-payment-inner">
                                    <p className="make-payment-header">Payment Amount: </p>
                                    <span className="value">{CustomCurrencyFormat(this.state.pmtAmount) ?? ''}</span>
                                </div>
                                    
                                    {this.state.AllPolicyTaxes.filter(items => items.amount !== 0).map((items, index) => (
                                        <div className="make-payment-inner" key={index}>
                                            <p className="make-payment-header">{items.description}: </p>
                                            <span className="value">{CustomCurrencyFormat(items.amount) ?? ""}</span>
                                        </div>
                                    ))}
                                {/*{(this.state.SelectedType === "DirectBill" && this.state.isDBPaid) &&*/}
                                {/*    <>*/}
                                {/*        <div className="mx-auto mb-12 text-align-left input-wrapper-payment agree-check remove-bg">*/}
                                {/*            {this.state.Errors['IsEmpty_IsTermsandConditions'] === true ? <div className="has-err-msg">{this.state.Errors['IsTermsandConditionsEmptyMsg']}</div> : ''}*/}
                                {/*            <div className='input-wrapper-radio remove-bg' style={{ background: "transparent" }}>*/}
                                {/*                <div className="col-12 TermsandConditions-modal-check">*/}
                                {/*                    <input type="checkbox" id="radio-Active" name="IsTermsandConditions" checked={this.state.IsTermsandConditions} required className={'form-check-input form-control' + (this.state.Errors['IsEmpty_IsTermsandConditions'] === true ? ' has-err' : '')} onChange={this.IsTermsandConditions} />*/}
                                {/*                    <svg viewBox="0 0 21 21" className="promotion-svg">*/}
                                {/*                        <polyline points="5 10.75 8.5 14.25 16 6"></polyline>*/}
                                {/*                    </svg>*/}
                                {/*                    <label className="form-check-label" style={{ "marginTop": "9px" }}>*/}
                                {/*                        <h6 className="mb-0 AutoPay-label-text">You agree to the terms and conditions.</h6>*/}
    
                                {/*                    </label>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </>*/}
                                {/*}*/}
                                </div>
                                <div className="payment-details-bottom">
                                    {this.state.isDBPaid && ((!this.state.AlreadyAutoPay || !this.state.DownPaymentPaidStatus) && !this.state.isPaidInFull) &&
                                        <>
                                            <div className="input-wrapper-payment remove-bg">
                                                <div className='input-wrapper-radio remove-bg' style={{ background: "transparent" }}>
                                                    <div className="col-12 TermsandConditions-modal-check">
                                                        <input type="checkbox" id="radio-AutoPay" name="IsAutoPay" checked={this.state.UpdateAutoPayMdl.IsAutoPay} required className={'form-check-input form-control mx-1'} onChange={this.IsAutoPay} />
                                                        <label className="form-check-label" style={{ "marginTop": "9px" }}>
                                                            <h6 className="mb-0 AutoPay-label-text">Autopay</h6>
        
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {(this.state.UserDetails === null || this.state.UserDetails.FlowGroupId === FlowGroups.Insured) && this.state.isTextAct === false &&
                                        <div className="input-wrapper-payment">
                                            {this.state.Errors['IsEmpty_IsTextActive'] === true ? <div className="has-err-msg">{this.state.Errors['IsTextActiveEmptyMsg']}</div> : ''}
                                            <div className='input-wrapper-radio remove-bg'>
                                                <div className="col-12 promotional-modal-check">
                                                    <input type="checkbox" id="radio-Active" name="IsTextActive" checked={this.state.IsTextActive} required className={(this.state.Errors['IsEmpty_IsTextActive'] === true ? ' has-err form-check-input form-control' : 'form-check-input form-control')} onChange={this.IsTextActive} />
                                                    <label className="form-check-label" style={{ "marginTop": "2px" }}>
                                                        <h6 className="mb-0 AutoPay-label-text">You agree to recieve automated promotional messages. You
                                                            also agree to the <a href={this.state.url + "/termsofuse"}>  Terms of Service</a> and <a href={this.state.url + "/privacypolicy"}> Privacy Policy</a>.
                                                            This agreement isn't a condition of any purchase. 4 Msgs/Month.
                                                            Msg & Data rates may apply. Reply STOP to end or HELP for help. </h6>
                                                    </label>
        
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {(this.state.UserDetails !== null) && this.state.isTextAct === false &&
                                        <div className="input-wrapper-payment">
                                            {this.state.Errors['IsEmpty_IsTextActive'] === true ? <div className="has-err-msg">{this.state.Errors['IsTextActiveEmptyMsg']}</div> : ''}
                                            <div className='input-wrapper-radio remove-bg'>
                                                <div className="col-12 promotional-modal-check">
                                                    <input type="checkbox" id="radio-Active" name="IsTextActive" checked={this.state.IsTextActive} required className={(this.state.Errors['IsEmpty_IsTextActive'] === true ? ' has-err form-check-input form-control' : 'form-check-input form-control')} onChange={this.IsTextActive} />
                                                    <label className="form-check-label" style={{ "marginTop": "2px" }}>
                                                        <h6 className="mb-0 AutoPay-label-text">You agree to recieve automated promotional messages. You
                                                            also agree to the <a href="#">  Terms of Service</a> and <a href="#"> Privacy Policy</a>.
                                                            This agreement isn't a condition of any purchase. 4 Msgs/Month.
                                                            Msg & Data rates may apply. Reply STOP to end or HELP for help.</h6>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.isDBPaid &&
                                        <div className="input-wrapper-payment">
                                        {this.state.Errors['IsEmpty_DBDisclosureAgreementIsTermsandConditions'] === true ? <div className="has-err-msg">{this.state.Errors['DBDisclosureAgreementIsTermsandConditionsEmptyMsg']}</div> : ''}
                                            <div className='input-wrapper-radio remove-bg'>
                                                <div className="col-12 TermsandConditions-modal-check">
                                                <input type="checkbox" id="radio-Active" name="DBDisclosureAgreementIsTermsandConditions" checked={this.state.DBDisclosureAgreementIsTermsandConditions} required className={(this.state.Errors['IsEmpty_DBDisclosureAgreementIsTermsandConditions'] === true ? ' has-err form-check-input form-control mx-1' : 'form-check-input form-control mx-1')} onChange={this.DBDisclosureAgreementIsTermsandConditions} />
                                                    <label className="form-check-label" style={{ "margin-top": "9px" }}>
                                                    <h6 className="mb-0 AutoPay-label-text">You agree to the terms and conditions described in <a href={this.state.DBReport} target="_blank">Direct Billing Disclosure Agreement</a>.</h6>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="text-center d-flex justify-content-center gap-20">
                                        <button type="button" id="PayBack" className="btn-newDesign-foil make-payment-btn" onClick={() => this.onButtonEvent('PaymentTypeBack')}>
                                            <svg className="icon-arrow before">
                                                <use xlinkHref="#arrow-left"></use>
                                            </svg>
                                            <span className="label">Back</span>
                                        </button>
                                        <button type="button" id="MethodofPayment" className="btn-newDesign make-payment-btn" onClick={() => this._SelectPaymentMethod()}>
                                            <span className="label">Continue to payment</span>
                                            <svg className="icon-arrow after">
                                                <use xlinkHref="#arrow"></use>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        {this.state.DownPaymentPaidStatus &&
                            <form className="payment-form" >
                                <div className="form-group">
                                    <label htmlFor="paymentAccount">Payment Account</label>
                                    <select id="paymentAccount" value={this.state.paymentAccount} onChange={this.handleAccountChange}>
                                        <option value="">Select an account</option>
                                        <option value="account1">Account 1</option>
                                        <option value="account2">Account 2</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="scheduleDate">Schedule Date</label>
                                    <input type="date" id="scheduleDate" value={this.state.scheduleDate} onChange={this.handleDateChange} />
                                </div>

                                <div className="form-group">
                                    <label>Payment Amount</label>
                                    <div className="radio-group">
                                        <div>
                                            <input
                                                type="radio"
                                                id="due"
                                                name="paymentAmount"
                                                value="due"
                                                checked={this.state.paymentAmount === 'due'}
                                                onChange={this.handleAmountChange}
                                            />
                                            <label htmlFor="due">Payment Amount Due: $1,286.47</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="outstanding"
                                                name="paymentAmount"
                                                value="outstanding"
                                                checked={this.state.paymentAmount === 'outstanding'}
                                                onChange={this.handleAmountChange}
                                            />
                                            <label htmlFor="outstanding">Total Outstanding Balance: $1,286.47</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="other"
                                                name="paymentAmount"
                                                value="other"
                                                checked={this.state.paymentAmount === 'other'}
                                                onChange={this.handleAmountChange}
                                            />
                                            <label htmlFor="other">Other Amount</label>
                                        </div>
                                    </div>
                                </div>

                                <button className="button-payment" type="submit" onClick={() => this._SelectPaymentMethod()}> Next </button>

                                <p className="description-payment">
                                    Please call Customer Service at (314) 576-0007 for assistance.
                                </p>
                            </form>
                        }
                    </form>
                </>
                

                <div className="d-flex justify-content-center mt-4">
                    <svg style={{ display: "none" }}>
                        <defs>
                            <symbol id="arrow-left" viewBox="0 0 35 15">
                                <title>Arrow Left</title>
                                <path id="Layer" className="s0" d="m7.73 9.14l2.17 2.17l-2.83 2.83l-7.07-7.07l7.07-7.07l2.83 2.83l-2.31 2.31l27.31 0v4l-27.17 0z"></path>
                            </symbol>
                        </defs>
                    </svg>
                    <svg style={{ display: "none" }}>
                        <defs>
                            <symbol id="arrow" viewBox="0 0 35 15">
                                <title>Arrow</title>
                                <path d="M27.172 5L25 2.828 27.828 0 34.9 7.071l-7.07 7.071L25 11.314 27.314 9H0V5h27.172z " />
                            </symbol>
                        </defs>
                    </svg>
                </div>
                <div className="modal fade" tabIndex="-1" id="modalTermsandConditions" aria-labelledby="modal Terms and Condtion selection" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '60%' }}>
                        <div className="modal-content">
                            <div className="modal-header pt-4 border-0">
                                <h4 className="modal-title text-center">Terms & Conditions</h4>
                                <button type="button" id="btncloseMdl" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId) === PartnerId.rt &&
                                    <>
                                        <div>
                                            <div>
                                                <p style={{ fontSize: '20px', fontWeight: 'bold', display: 'inline' }}>Legal Disclaimer :</p>
                                                <p style={{ fontStyle: 'italic', display: 'inline' }}>
                                                    {' '}Ryan Specialty, LLC, a Delaware limited liability company, is the owner of RSG Specialty, LLC, and RSG Underwriting Managers, LLC, both Delaware limited liability companies which conduct business through their divisions, series, and licensed subsidiaries. In California: RSG Specialty Insurance Services, LLC (License #0G97516) and RSG Insurance Services, LLC License #0E50879.
                                                </p>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: '20px', fontWeight: 'bold', display: 'inline' }}>XGen Charges and Fees :</p>
                                                <p style={{ fontStyle: 'italic', display: 'inline' }}>
                                                    {' '}xGen charges a fee for your payment and a fee for each installment, if selected. Although you will see these charges to your credit card, this fee does not go to Ryan Specialty Group and is separate and distinct from payment of your invoice. This xGen service does not guarantee a same day payment receipt. Please refer to your invoice for other payment options.
                                                </p>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: '20px', fontWeight: 'bold', display: 'inline' }}>Authorization and Consent :</p>
                                                <p style={{ fontStyle: 'italic', display: 'inline' }}>
                                                    {' '}By clicking Send, I consent and authorize Ryan Specialty Group or its representative to collect my insurance premiums by charging my credit/debit card, including repeat payments if I have selected a repeat payment option. My authorization for recurring payments, if any, will remain in effect until I withdraw that authorization. If I wish to withdraw authorization for recurring payments, I will log into the xGen portal and turn off this feature under the Scheduled Payments tab. I agree that it is my responsibility to have sufficient funds in my bank account to cover the payments withdrawn from my account, and that otherwise my policy may be canceled or expire. I am responsible for reviewing any billing notices presented to me via mail or electronically at the email address on file with my insurance agent. If my email has changed, I will enter the new email into xGEN and will let my insurance agent know.
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                }
                                {(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId) !== PartnerId.rt &&
                                    <>
                                        <div>

                                            <h5>The fees associated with the payment option and payment method will be disclosed on the portal portrayed as a monthly installment fee.</h5>

                                            <ul>
                                                <li>
                                                    If you select the monthly installment option and default on your payment obligation, you hereby irrevocably appoint {JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomain} the power of attorney to cancel your insurance policy and collect
                                                    any unearned premiums or other amounts under your policy directly from the insurance carrier and apply any proceeds from the insurance carrier to any payment obligations that are rightly due to {JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomain}.</li>

                                                <li> You agree that {JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomain} may assign or transfer any and all of its interest of this agreement and your power of attorney to any other person or entity</li>
                                            </ul>
                                        </div>
                                    </>
                                }
                                <div className="mt-5 d-flex justify-content-center mt-4"><div className="d-flex ps-3"><Button type="button" className="btn-next center-aligned" onClick={() => this.onTermsandConditionsAccept()}><span className="label">Accept</span></Button></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );

    }

}

export default MakePayment;