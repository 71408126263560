//common 
import Reports from '../components/pages/Common/Reports';
import ReportsFilter from '../components/pages/Common/ReportsFilter';
import MakePayment from "../components/pages/Common/MakePayment";
import PaymentStatus from "../components/pages/Common/PaymentStatus";
import FppPaymentStatus from "../components/pages/Common/FppPaymentStatus";
import PaymentWidget from "../components/pages/Common/PaymentWidget";
import PaymentWidgetStatus from '../components/pages/Common/PaymentWidgetStatus';
import ManageWidget from '../components/pages/Common/ManageWidget';
import EnterPassCode from '../components/pages/Common/EnterPassCode';
import ChangePassword from '../components/pages/Common/ChangePassword';
import DocuSignComplete from '../components/pages/Common/DocuSignComplete';
import Login from '../components/pages/Common/Login';
import PrivacyPolicy from '../components/pages/Common/PrivacyPolicy';
import TermsOfUse from '../components/pages/Common/TermsOfUse';
import Compliance from '../components/pages/Common/Compliance';

//start pages for agent user
import CommonUsersDashboard from "../components/pages/CommonUsers/Dashboard";
import PaymentLinkSummary from "../components/pages/CommonUsers/PaymentLinkSummary";
import ManageUsersByCommonUsers from "../components/pages/CommonUsers/ManageUsersByAgent";
import CommonUsersMySettings from "../components/pages/CommonUsers/MySettings";
import ActiveAccounts from "../components/pages/CommonUsers/ActiveAccounts";
import AccountsPastDue from "../components/pages/CommonUsers/AccountsPastDue";
import CancelledAccounts from "../components/pages/CommonUsers/CancelledAccounts";

//start pages for super admin
import SuperAdminDashboard from "../components/pages/SuperAdmin/Dashboard";
import ManageUsersBySuperAdmin from "../components/pages/SuperAdmin/ManageUsers";
import ManageRoles from "../components/pages/SuperAdmin/ManageRoles";
import ManageCommission from "../components/pages/SuperAdmin/ManageCommission"
import ManagePermissions from "../components/pages/SuperAdmin/ManagePermissions";
import ManageEmailTextTemplates from '../components/pages/SuperAdmin/ManageEmailTextTemplates';
import ManagePartnersBySuperAdmin from "../components/pages/SuperAdmin/ManagePartners";

//start pages for insured user
import InsuredDashboard from "../components/pages/Insured/Dashboard";
import InsuredMySettings from "../components/pages/Insured/MySettings";
import InsuredAccountOverview from "../components/pages/Insured/AccountOverview";
import ViewBillingStatements from "../components/pages/Insured/ViewBillingStatements";
import AccountActivity from "../components/pages/Insured/AccountActivity";

//start pages for xgen owner
import XgenOwnerDashboard from "../components/pages/XgenOwner/Dashboard";
import ManagePartnersByXgenOwner from "../components/pages/XgenOwner/ManagePartners";
import ApiDocs from '../components/pages/Common/ApiDocs';

const pageComponents = {
    //agent routing
    CommonUsersDashboard: CommonUsersDashboard,
    CommonUsersMySettings: CommonUsersMySettings,
    PaymentLinkSummary: PaymentLinkSummary,
    ActiveAccounts: ActiveAccounts,
    AccountsPastDue: AccountsPastDue,
    CancelledAccounts: CancelledAccounts,
    PaymentWidget: PaymentWidget,
    PaymentWidgetStatus: PaymentWidgetStatus,
    ManageUsersByCommonUsers: ManageUsersByCommonUsers,
    ManageWidget: ManageWidget,
    //Insured routing
    InsuredDashboard: InsuredDashboard,
    InsuredMySettings: InsuredMySettings,
    InsuredAccountOverview: InsuredAccountOverview,
    ViewBillingStatements: ViewBillingStatements,
    AccountActivity: AccountActivity,
    //super admin routing
    SuperAdminDashboard: SuperAdminDashboard,
    ManageUsersBySuperAdmin: ManageUsersBySuperAdmin,
    ManageCommission: ManageCommission,
    ManageRoles: ManageRoles,
    ManagePermissions: ManagePermissions,
    ManageEmailTextTemplates: ManageEmailTextTemplates,
    ManagePartnersBySuperAdmin: ManagePartnersBySuperAdmin,
    //common
    MakePayment: MakePayment,
    PaymentStatus: PaymentStatus,
    FppPaymentStatus: FppPaymentStatus,
    Reports: Reports,
    ReportsFilter: ReportsFilter,
    EnterPassCode: EnterPassCode,
    ChangePassword: ChangePassword,
    DocuSignComplete: DocuSignComplete,
    Login: Login,
    PrivacyPolicy: PrivacyPolicy,
    TermsOfUse: TermsOfUse,
    Compliance: Compliance,
    //XgenOwner
    XgenOwnerDashboard: XgenOwnerDashboard,
    ManagePartnersByXgenOwner: ManagePartnersByXgenOwner,
    //ApiDocs
    ApiDocs: ApiDocs
};
export { pageComponents};