import styled from 'styled-components'
import { NavLink } from 'react-router-dom';


function Logout() {
    const SubDomain = JSON.parse(localStorage.getItem('SubDomain'));
    if (SubDomain) {
        if (SubDomain.SubDomainId !== "1") {
            localStorage.removeItem("UserCredential");
            window.open('/' + SubDomain.SubDomain + '', '_self');
            
        }
        else {
            localStorage.removeItem("UserCredential");
            window.open('/', '_self');
        }
    }
   
};
function showSuccess(message) {
    var x = document.getElementById("snackbar");
    x.innerHTML = message;
    x.className = "show";
    x.style.color = "white";
    x.style.backgroundColor = "Green";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);
}
function showError(message) {
    var x = document.getElementById("snackbar");
    x.innerHTML = message;
    x.className = "show";
    x.style.color = "white";
    x.style.backgroundColor = "Red"
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);
}

var Button = () => {
    return "";
};

var StyledActiveLink = () => {
    return "";
};

function authHeader() {
    const UserCredential = JSON.parse(localStorage.getItem('UserCredential'));
    
    if (UserCredential) {
        return { Authorization: UserCredential.Token, UserName: UserCredential.UserName, FlowGroupId: UserCredential.FlowGroupId, FullName: UserCredential.FullName, UserRoleIds: UserCredential.UserRoleIds, CustomerId: UserCredential.CustomerId };
    } else {
        return null;
    }
}

function LoggedOutDetails() {
    const LoggedOutDetailst = JSON.parse(localStorage.getItem('LoggedOutDetails'));
    if (LoggedOutDetailst) {
        return {
            LoggedOutSubDomainId: LoggedOutDetailst.LoggedOutSubDomainId, LoggedOutLogo: LoggedOutDetailst.LoggedOutLogo, LoggedOutColor: LoggedOutDetailst.LoggedOutColor, LoggedOutHoverFocus: LoggedOutDetailst.LoggedOutHoverFocus,
            LoggedOutSubDomain: LoggedOutDetailst.LoggedOutSubDomain, LoggedOutSubDomainUrl: LoggedOutDetailst.LoggedOutSubDomainUrl, LoggedOutLabel1: LoggedOutDetailst.LoggedOutLabel1, LoggedOutLabel2: LoggedOutDetailst.LoggedOutLabel2,
            isAISPF: LoggedOutDetailst.isAISPF, isAISDB: LoggedOutDetailst.isAISDB, isBDSecurePF: LoggedOutDetailst.isBDSecurePF, isFinanceProPF: LoggedOutDetailst.isFinanceProPF
        };
    } else {
        return null;
    }
}

function subDomain() {
    const SubDomain = JSON.parse(localStorage.getItem('SubDomain'));
    if (SubDomain) {
        if (SubDomain.SubDomainId !== "1") {
            Button = styled.button.attrs(() => ({ tabIndex: 0 }))`
              background: linear-gradient(${SubDomain.Color}, ${SubDomain.Color}) !important;
                background-color: ${SubDomain.Color} !important;
                border-color: ${SubDomain.Color} !important;
                color: #fff !important;

              &:hover {
                background: linear-gradient(${SubDomain.HoverFocus}, ${SubDomain.HoverFocus}) !important;
                background-color: ${SubDomain.HoverFocus} !important;
                border-color: ${SubDomain.HoverFocus} !important;
              }

              &:focus {
                background: linear-gradient(${SubDomain.HoverFocus}, ${SubDomain.HoverFocus}) !important;
                background-color: ${SubDomain.HoverFocus} !important;
                border-color: ${SubDomain.HoverFocus} !important;
              }
            `

            StyledActiveLink = styled(NavLink).attrs(() => ({ tabIndex: 0 }))`
              background: linear-gradient(${SubDomain.Color}, ${SubDomain.Color}) !important;
                background-color: ${SubDomain.Color} !important;
                border-color: ${SubDomain.Color} !important;
                color: #fff !important;

              &:hover {
                background: linear-gradient(${SubDomain.HoverFocus}, ${SubDomain.HoverFocus}) !important;
                background-color: ${SubDomain.HoverFocus} !important;
                border-color: ${SubDomain.HoverFocus} !important;
              }

              &:focus {
                background: linear-gradient(${SubDomain.HoverFocus}, ${SubDomain.HoverFocus}) !important;
                background-color: ${SubDomain.HoverFocus} !important;
                border-color: ${SubDomain.HoverFocus} !important;
              }
            `
        }
        else {
            Button = "button";
            StyledActiveLink = "NavLink";
        }

        return { SubDomainId: SubDomain.SubDomainId, SubDomain: SubDomain.SubDomain, LogoPath: SubDomain.LogoPath, FontFamily: SubDomain.FontFamily, Label1: SubDomain.Label1, Label2: SubDomain.Label2, Color: SubDomain.Color, HoverFocus: SubDomain.HoverFocus, PaymentProcessId: SubDomain.PaymentProcessId, isAISPF: SubDomain.isAISPF, isAISDB: SubDomain.isAISDB, isBDSecurePF: SubDomain.isBDSecurePF, isFinanceProPF: SubDomain.isFinanceProPF  };
    } else {
        return null;
    }
}

function ExternalDomain(color, hoverFocus, Id) {
        if (Id !== 1) {
            return styled.button.attrs(() => ({ tabIndex: 1 }))`
              background: linear-gradient(${color}, ${color}) !important;
                background-color: ${color} !important;
                border-color: ${color} !important;
                color: #fff !important;

              &:hover {
                background: linear-gradient(${hoverFocus}, ${hoverFocus}) !important;
                background-color: ${hoverFocus} !important;
                border-color: ${hoverFocus} !important;
              }

              &:focus {
                background: linear-gradient(${hoverFocus}, ${hoverFocus}) !important;
                background-color: ${hoverFocus} !important;
                border-color: ${hoverFocus} !important;
              }
            `
        }
        else {
            return "button";
        }
}

function ExternalDomainNavLink(color, hoverFocus, Id) {
    if (Id !== 1) {
        return styled(NavLink).attrs(() => ({ tabIndex: 0 }))`
               background: linear-gradient(${color}, ${color}) !important;
                background-color: ${color} !important;
                border-color: ${color} !important;
                color: #fff !important;

              &:hover {
                background: linear-gradient(${hoverFocus}, ${hoverFocus}) !important;
                background-color: ${hoverFocus} !important;
                border-color: ${hoverFocus} !important;
              }

              &:focus {
                background: linear-gradient(${hoverFocus}, ${hoverFocus}) !important;
                background-color: ${hoverFocus} !important;
                border-color: ${hoverFocus} !important;
              }
            `
    }
    else {
        return "NavLink";
    }
}


export { Logout, showSuccess, showError, authHeader, subDomain, Button, ExternalDomain, StyledActiveLink, ExternalDomainNavLink, LoggedOutDetails };

    