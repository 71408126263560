import React, { Component, useState, useEffect } from 'react';
import { SiteFooter } from "../../layout/SiteFooter";
import { authHeader, showError, subDomain, StyledActiveLink, ExternalDomainNavLink } from "../../../common/Common";
import { NavLink } from 'react-router-dom';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { PaymentSuccess, GetInsuredDetails } from '../../../services/PaymentServices';
import { useStripe, useElements } from '@stripe/react-stripe-js';

export default function PaymentStatus() {
    const stripe = useStripe();
    const elements = useElements();
    const UserDetails = authHeader();
    const SubDomain = subDomain();
    const queryParams = new URLSearchParams(window.location.search);
    const subDomainUrl = (window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === "payment-status" ? "Xgen" : window.location.pathname.replace(/^\/([^\/]*).*$/, '$1'));
    const confirmationNumber = (new URLSearchParams(window.location.search)).get("cN");
    const piClientSecret = (new URLSearchParams(window.location.search)).get("payment_intent_client_secret");
    const policyNumber = (new URLSearchParams(window.location.search)).get("pN");
    const fullName = (new URLSearchParams(window.location.search)).get("fN");
    const textActive = Boolean((new URLSearchParams(window.location.search)).get("textActive"));
    const paymentId = (new URLSearchParams(window.location.search)).get("paymentId");
    const transferGroup = (new URLSearchParams(window.location.search)).get("tG");
    const customerId = (new URLSearchParams(window.location.search)).get("cId");
    const isAutoPay = (new URLSearchParams(window.location.search)).get("iAp");
    const invoiceNumber = (new URLSearchParams(window.location.search)).get("invoiceNumber");
    const isPFPaid = (new URLSearchParams(window.location.search)).get("isPFPaid");
    const isDBPaid = (new URLSearchParams(window.location.search)).get("isDBPaid");
    const isPaidInFull = (new URLSearchParams(window.location.search)).get("isPaidInFull");
    const isPayFull = (new URLSearchParams(window.location.search)).get("isPayFull");
    const isDownPayment = (new URLSearchParams(window.location.search)).get("isDownPayment");
    const isMonthlyPayment = (new URLSearchParams(window.location.search)).get("isMonthlyPayment");
    const [paymentIntent, setPaymentIntent] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [result, setResult] = useState({});

    useEffect(() => {
        if (paymentStatus !== 'succeeded' && paymentStatus !== 'requires_payment_method') {
            handleSubmit();
        }
    }, [paymentStatus, result, stripe]);
    useEffect(() => {
        if (paymentStatus === 'succeeded') {
            _PaymentSuccess();
        }

        if (paymentStatus === 'requires_payment_method') {
            _PaymentSuccess();
        }

    }, [paymentStatus]);

    const handleSubmit = () => {
        if (stripe) {
            const { error } = stripe
                .retrievePaymentIntent(piClientSecret)
                .then(function (result) {
                    // Handle result.error or result.paymentIntent
                    setPaymentIntent(result.paymentIntent);
                    setPaymentStatus(result.paymentIntent.status);
                    setResult(result);
                    if (result.error) {
                        showError(result.error);
                    }
                });
            if (error) {
                showError(error.message);
            }
        }
    }

   
    const _PaymentSuccess = async () => {
        let stripePaymentMdl = {
            amount: parseInt(paymentIntent.amount), //Amount in cents
            paymentId: paymentId,
            paymentIntentMethod: paymentIntent.payment_method,
            policyNumber: policyNumber,
            fullName: fullName,
            status: paymentStatus !== 'succeeded' ? false : true,
            isTextActive: textActive,
            confirmationNumber: confirmationNumber,
            transferGroup: transferGroup,
            paymentIntentId: paymentIntent.id,
            logo: null,
            subDomain: null,
            subDomainId: 0,
            customerId: customerId,
            isAutoPay: isAutoPay === 'true' ? true : false,
            invoiceNumber: invoiceNumber,
            isPFPaid: isPFPaid === 'true' ? true : false,
            isDBPaid: isDBPaid === 'true' ? true : false,
            isPaidInFull: isPaidInFull === 'true' ? true : false,
            isPayFull: isPayFull == 'true' ? true : false,
            isDownPayment: isDownPayment == 'true' ? true : false,
            isMonthlyPayment: isMonthlyPayment == 'true' ? true : false
        }
        if (UserDetails !== null) {
            stripePaymentMdl.subDomain = SubDomain.SubDomain;
            stripePaymentMdl.logo = SubDomain.LogoPath;
            stripePaymentMdl.subDomainId = parseInt(SubDomain.SubDomainId);
        }
        else {
                stripePaymentMdl.subDomain = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl;
                stripePaymentMdl.logo = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo;
                stripePaymentMdl.subDomainId = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId;
        }
        await PaymentSuccess(stripePaymentMdl);

    }

    var Button = ExternalDomainNavLink(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutColor, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutHoverFocus, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId);

    return (
        <>
            {UserDetails !== null ?
                <div className="main-wrapper-newDesign w-100">
                    <InternalLayoutHeader />	 {/*internal header user wise */}
                    <div className="content-main p-lg-4 p-3">
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12 text-center">
                                <div className="Paymentcard">
                                    {subDomainUrl.toLowerCase() === 'payment-status' &&
                                        <>
                                        <div className="PaymentLogoWrapper">
                                            <img src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} width="" height="" alt="Partner Logo" className="PaymentLogoImg" />
                                        </div>
                                        {paymentStatus === 'succeeded' &&
                                            <>
                                                <h1 className="pt-2 pb-2 text-center">Your payment is successful !</h1>
                                            <p className="text-center">Thank you so much for your payment! Your confirmation number is {confirmationNumber}. We are so excited to have you on board. You can download a copy of your receipt <NavLink target="_blank" to={subDomainUrl.toLowerCase() === 'flux' ? "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinderflux.pdf" : "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinder.pdf"} download>here</NavLink>. If you have any questions, please contact our agency. </p>
                                            </>
                                            }
                                        {paymentStatus === 'processing' &&
                                            <>
                                                <h1 className="pt-2 pb-2 text-center">Your payment is processing !</h1>
                                                <p className="text-center">Thank you so much for your payment! Your confirmation number is {confirmationNumber}. Your Payment is still processing, So Please refresh this page after sometime. If you have any questions, please contact our agency. </p>
                                            </>
                                        }
                                        {paymentStatus === 'requires_payment_method' &&
                                            <>
                                                <h1 className="pt-2 pb-2 text-center">We are unable to process your payment. </h1>
                                                <p className="text-center">Please re-enter your payment information or use an alternative payment method. </p>
                                            </>
                                        }
                                            
                                            <div className="button-container">
                                            <StyledActiveLink to="/" className="btn btn-newDesign btn-large">Back to dashboard</StyledActiveLink>
                                            </div>
                                        </>
                                    }
                                    {subDomainUrl.toLowerCase() === 'xgen' &&
                                        <>
                                        <div className="PaymentLogoWrapper">
                                            <img src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} width="" height="" alt="Partner Logo" className="PaymentLogoImg" />
                                        </div>
                                            {paymentStatus === 'succeeded' &&
                                                <>
                                                    <h1 className="pt-2 pb-2 text-center">Your payment is successful !</h1>
                                            <p className="text-center">Thank you so much for your payment! Your confirmation number is {confirmationNumber}. We are so excited to have you on board. You can download a copy of your receipt <NavLink target="_blank" to={subDomainUrl.toLowerCase() === 'flux' ? "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinderflux.pdf" : "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinder.pdf"} download>here</NavLink>. If you have any questions, please contact our agency. </p>
                                                </>
                                            }
                                            {paymentStatus === 'processing' &&
                                                <>
                                                    <h1 className="pt-2 pb-2 text-center">Your payment is processing !</h1>
                                                    <p className="text-center">Thank you so much for your payment! Your confirmation number is {confirmationNumber}. Your Payment is still processing, So Please refresh this page after sometime. If you have any questions, please contact our agency. </p>
                                                </>
                                            }
                                            {paymentStatus === 'requires_payment_method' &&
                                                <>
                                                    <h1 className="pt-2 pb-2 text-center">We are unable to process your payment. </h1>
                                                    <p className="text-center">Please re-enter your payment information or use an alternative payment method. </p>
                                                </>
                                            }

                                            <div className="button-container">
                                                <NavLink to="/" className="btn btn-newDesign btn-large">Back to dashboard</NavLink>
                                            </div>
                                        </>
                                    }
                                    {subDomainUrl.toLowerCase() !== 'payment-status' && subDomainUrl.toLowerCase() !== 'xgen' &&
                                        <>
                                        <div className="PaymentLogoWrapper">
                                            <img src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} width="" height="" alt="Partner Logo" className="PaymentLogoImg" />
                                        </div>
                                        {paymentStatus === 'succeeded' &&
                                                <>
                                                    <h1 className="pt-2 pb-2 text-center">Your payment is successful !</h1>
                                            <p className="text-center">Thank you so much for your payment! Your confirmation number is {confirmationNumber}. We are so excited to have you on board. You can download a copy of your receipt <NavLink target="_blank" to={subDomainUrl.toLowerCase() === 'flux' ? "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinderflux.pdf" : "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinder.pdf"} download>here</NavLink>. If you have any questions, please contact our agency. </p>
                                                </>
                                        }
                                        {paymentStatus === 'processing' &&
                                                <>
                                                    <h1 className="pt-2 pb-2 text-center">Your payment is processing !</h1>
                                                    <p className="text-center">Thank you so much for your payment! Your confirmation number is {confirmationNumber}. Your Payment is still processing, So Please refresh this page after sometime. If you have any questions, please contact our agency. </p>
                                                </>
                                        }
                                        {paymentStatus === 'requires_payment_method' && 
                                            <>
                                            <h1 className="pt-2 pb-2 text-center">We are unable to process your payment. </h1>
                                            <p className="text-center">Please re-enter your payment information or use an alternative payment method. </p>
                                            </>
                                        }
                                        <div className="button-container">
                                            <Button to={"/" + subDomainUrl} className="btn btn-newDesign btn-large">Back to dashboard</Button>
                                        </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <SiteFooter />  {/*this call for site footer */}
                </div>
                :
                <>
                    <main>
                        <div className="container form-space p-space">
                            <div className="row justify-content-md-center">
                                <div className="col-md-auto">
                                    <div className="Paymentcard">
                                        {subDomainUrl.toLowerCase() === 'payment-status' &&
                                            <>
                                                <div>
                                                    <svg id="done" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="green">
                                                        <path d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z" />
                                                    </svg>
                                                </div>
                                            {paymentStatus === 'succeeded' && 
                                                <>
                                                <h1 className="pt-2 pb-2 text-center">Your payment is successful !</h1>
                                                <p className="text-center">Your confirmation number is {confirmationNumber}</p>
                                                </>
                                                }
                                            {paymentStatus === 'processing' &&
                                                <>
                                                <h1 className="pt-2 pb-2 text-center">Your payment is processing !</h1>
                                                <p className="text-center">Your confirmation number is {confirmationNumber}</p>
                                                </>
                                            }
                                            {paymentStatus === 'requires_payment_method' &&
                                                <>
                                                    <h1 className="pt-2 pb-2 text-center">We are unable to process your payment. </h1>
                                                    <p className="text-center">Please re-enter your payment information or use an alternative payment method. </p>
                                                </>
                                            }
                                                
                                                <div className="button-container">
                                                <StyledActiveLink to="/login" className="btn btn-newDesign btn-large">Back to Login</StyledActiveLink>
                                                </div>     
                                            </>
                                        }
                                        {subDomainUrl.toLowerCase() === 'xgen' &&
                                            <>
                                                <div className="PaymentLogoWrapper">
                                                    <img src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} width="" height="" alt="Partner Logo" className="PaymentLogoImg" />
                                                </div>
                                                {paymentStatus === 'succeeded' &&
                                                    <>
                                                        <h1 className="pt-2 pb-2 text-center">Your payment is successful !</h1>
                                                <p className="text-center">Thank you so much for your payment! Your confirmation number is {confirmationNumber}. We are so excited to have you on board. You can download a copy of your receipt <NavLink target="_blank" to={subDomainUrl.toLowerCase() === 'flux' ? "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinderflux.pdf" : "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinder.pdf"} download>here</NavLink>. If you have any questions, please contact our agency. </p>
                                                    </>
                                                }
                                                {paymentStatus === 'processing' &&
                                                    <>
                                                        <h1 className="pt-2 pb-2 text-center">Your payment is processing !</h1>
                                                        <p className="text-center">Thank you so much for your payment! Your confirmation number is {confirmationNumber}. Your Payment is still processing, So Please refresh this page after sometime. If you have any questions, please contact our agency. </p>
                                                    </>
                                                }
                                                {paymentStatus === 'requires_payment_method' &&
                                                    <>
                                                        <h1 className="pt-2 pb-2 text-center">We are unable to process your payment. </h1>
                                                        <p className="text-center">Please re-enter your payment information or use an alternative payment method. </p>
                                                    </>
                                                }
                                                <div className="button-container">
                                                <NavLink to="/" className="btn btn-newDesign btn-large">Back to Login</NavLink>
                                                </div>
                                            </>
                                        }
                                        {subDomainUrl.toLowerCase() !== 'payment-status' && subDomainUrl.toLowerCase() !== 'xgen' &&
                                            <>
                                                <div className="PaymentLogoWrapper">
                                                    <img src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} width="" height="" alt="Partner Logo" className="PaymentLogoImg" />
                                                </div>
                                                {paymentStatus === 'succeeded' && 
                                                        <>
                                                            <h1 className="pt-2 pb-2 text-center">Your payment is successful !</h1>
                                                <p className="text-center">Thank you so much for your payment! Your confirmation number is {confirmationNumber}. We are so excited to have you on board. You can download a copy of your receipt <NavLink target="_blank" to={subDomainUrl.toLowerCase() === 'flux' ? "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinderflux.pdf" : "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinder.pdf"} download>here</NavLink>. If you have any questions, please contact our agency. </p>
                                                        </>
                                                }
                                                {paymentStatus === 'processing' &&
                                                        <>
                                                            <h1 className="pt-2 pb-2 text-center">Your payment is processing !</h1>
                                                            <p className="text-center">Thank you so much for your payment! Your confirmation number is {confirmationNumber}. Your Payment is still processing, So Please refresh this page after sometime. If you have any questions, please contact our agency. </p>
                                                        </>
                                                }
                                                {paymentStatus === 'requires_payment_method' &&
                                                    <>
                                                        <h1 className="pt-2 pb-2 text-center">We are unable to process your payment. </h1>
                                                        <p className="text-center">Please re-enter your payment information or use an alternative payment method. </p>
                                                    </>
                                                }
                                                <div className="button-container">
                                                    <Button to={"/" + subDomainUrl} className="btn btn-newDesign btn-large">Back to Login</Button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <SiteFooter /> {/*this call for site footer */}
                </> 
            }
        </>
    )
}