import React, { Component, Fragment } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import validator from 'validator';
import { authHeader, showError, showSuccess, subDomain, Button, StyledActiveLink } from '../../../common/Common';
import { Error, CustomCurrencyFormat, DateFormat } from '../../../common/Setting';
import { GetAllPaymentLinks, SavePaymentLink, SendPaymentLink, SendTextPaymentLink, GetLogsByPaymentId, ModifyPaymentLink, GetPolicyFees, GetPolicyPremium, GetPaymentLinksById, GetAllGCodes } from '../../../services/PaymentServices';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import { LoaderContents } from "../../layout/LoaderContents";
import ReactPaginate from 'react-paginate';
import CurrencyFormat from 'react-currency-format';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetUserProfile, GetState, GetCoverageTypes } from '../../../services/UserServices';
import Select from 'react-select';
import { GetAllCompany } from '../../../services/InsuranceCompanyService';

class PaymentLinkSummary extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            AllPaymentLinksData: [],
            Errors: {},
            PaymentAmount: null,
            MonthlyAmount: null,
            DownPayment: null,
            ServiceFee: null,
            APR: null,
            QuoteFlag: false,
            financeCharge: null,
            searchMdl: {
                Id: null, //use for payment id
                GeneralSearch: null,
                LimitTo: 10,
                PageNumber: 1,
                SelectedDate: null,
                ToDate: null,
                PageLoaderStatus: false,
                SaveLoaderStatus: false,
            },
            SendLoader: false,
            PaymentId: "",
            SentTo: "",
            UserDetails: authHeader(),
            Pagecount: 0,
            DueDateOfPayment: null,
            PolicyEffectiveDate: null,
            expanded: {},
            Logs: [],
            isPaymentAmountEmpty: true,
            SubDomain: subDomain(),
            userId: null,
            isExpired: false,
            InsuranceCompany: "",
            AllCompanyData: [],
            selectedComapany: null,
            selectedCoverageType: null,
            linkexpirydate: null,
            StateList: [],
            CoverageTypeList: [],
            selectedState: null,
            editStateValue: [],
            isPremiumfinance: false,
            modifyLink: false,
            isDirectBilling: false,
            Note: null,
            isAISPF: false,
            isAISDB: false,
            isBDSecurePF: false,
            isFinanceProPF: false,
            policyTaxesAndFees: [],
            PolicyPaymentAmount: [],
            showForm: false,
            isFinancePro: false,
            policyExpiryDate: null,
            policyExpDateFormat: null,
            MEPPercentage: null,
            AgencyID: null,
            AgencyName: null,
            AgencyPhoneNumber: null,
            AgencyAffiliateID: null,
            AgencyAffiliateName: null,
            CarrierID: null,
            LineDescription: null,
            StaticTaxesAndFees: [
                { Type: "F", Description: "Policy Fee", Amount: 0 },
                { Type: "F", Description: "Tax/Stamp Fee", Amount: 0 },
                { Type: "F", Description: "Broker Fee", Amount: 0},
                { Type: "F", Description: "Inspection Fee", Amount: 0 }
            ],
            AllGCodesData: [],
            selectedGCode: null,
            GeneralAgent: ""
            //TaxTypes: [{ label: "T", value: "T" }, { label: "F", value: "F" }]
        };
        this._handleInputChange = this._handleInputChange.bind(this);
        this.openForm = this.openForm.bind(this);
        this._addPolicy = this._addPolicy.bind(this);
        this._removePolicy = this._removePolicy.bind(this);
        this._handleInputPaymentChange = this._handleInputPaymentChange.bind(this);
        this.openForm = this.openForm.bind(this);
        this._addPayment = this._addPayment.bind(this);
        this._removePayment = this._removePayment.bind(this);
        this.getTotalPremium = this.getTotalPremium.bind(this);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this.setState({ PageLoaderStatus: true, DueDateOfPayment: new Date() }); // show loader befour load data
        const storedData = localStorage.getItem('SubDomain');
        if (storedData) {
            const { isPremiumfinance, isDirectBilling, isAISPF, isAISDB, isBDSecurePF, isFinanceProPF } = JSON.parse(storedData);
            this.setState({ isPremiumfinance, isDirectBilling, isAISPF, isAISDB, isBDSecurePF, isFinanceProPF }, () => {
            });
        }
        this._GetCompanyCode();
        this._GetAllGCodesList();
        this._GetCoverageTypeList();
        this._GetStateList();
        this._isMounted = true;
        if (this._isMounted) {
            const user = JSON.parse(localStorage.getItem("UserCredential"));
            if (user != null) {
                this._GetUserProfile();
            }

            //for phone number  formatting
            $("input[name='phoneNumber'], input[name='AgencyPhoneNumber']").keyup(function () {
                $(this).val($(this).val().replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3"));
            });

        }
    };

    _handleInputPaymentChange(e, index) {
        let { name, value } = e.target;
        if (name === "Written_Premium") {
            value = value.replace(/[^0-9.-]+/g, "");
        }
        const PolicyPaymentAmount = [...this.state.PolicyPaymentAmount];
        PolicyPaymentAmount[index][name] = value;
        this.setState({ PolicyPaymentAmount });
        this.getTotalPremium(PolicyPaymentAmount);
    }

    _addPayment() {
        this.setState((prevState) => ({
            PolicyPaymentAmount: [
                ...prevState.PolicyPaymentAmount,
                { Line_Code: '', Written_Premium: '', Tran_Code: '' }
            ],
        }));
    }

    _removePayment(index) {
        this.setState((prevState) => ({
            PolicyPaymentAmount: prevState.PolicyPaymentAmount.filter((_, i) => i !== index),
        }));
    }

    getTotalPremium = (PolicyPaymentAmount) => {
        let PolicyPremiumAmount = 0;
        if (PolicyPaymentAmount != null && PolicyPaymentAmount.length > 0) {
            for (let i = 0; i < PolicyPaymentAmount.length; i++) {
                PolicyPremiumAmount += parseInt(PolicyPaymentAmount[i].Written_Premium);
            }
        }
        this.setState({ PaymentAmount: PolicyPremiumAmount });
    }


    _handleInputChange(e, index) {
        let { name, value } = e.target;
        if (name === "Amount") {
            value = value.replace(/[^0-9.-]+/g, "");
        }
        const policyTaxesAndFees = [...this.state.policyTaxesAndFees];
        policyTaxesAndFees[index][name] = value;
        this.setState({ policyTaxesAndFees });
    }
    _handleInputChange1(e, index) {
        let { name, value } = e.target;
        if (name === "Amount") {
            value = value.replace(/[^0-9.-]+/g, "");
        }
        let updatedFees = [...this.state.StaticTaxesAndFees];
        updatedFees[index] = {
            ...updatedFees[index],
            [name]: value
        };
        this.setState({ StaticTaxesAndFees: updatedFees, policyTaxesAndFees: updatedFees });

    }
    _addPolicy() {
        this.setState((prevState) => ({
            policyTaxesAndFees: [
                ...prevState.policyTaxesAndFees,
                { Type: '', Description: '', Amount: '', Line_Code: '' }
            ],
        }));
    }

    _removePolicy(index) {
        this.setState((prevState) => ({
            policyTaxesAndFees: prevState.policyTaxesAndFees.filter((_, i) => i !== index),
        }));
    }

    openForm() {
        this.setState({ showForm: true });
    }

    _GetStateList = () => {
        GetState().then(data => {
            let response = data.data;
            let listOption = [];
            if (response.status === true) {
                for (let i = 0; i < response.data.length; i++) {
                    listOption.push({ 'value': response.data[i].code, 'label': response.data[i].description });
                }
                this.setState({ StateList: listOption });
            }
            else {
                showError(response.message);
            }
        });
    }

    _GetCoverageTypeList = () => {
        GetCoverageTypes().then(data => {
            let response = data.data;
            let coveragetypesList = [];
            if (response.status === true) {
                for (let i = 0; i < response.data.length; i++) {
                    coveragetypesList.push({ 'value': response.data[i].coverageType, 'label': response.data[i].coverageType });
                }
                this.setState({ CoverageTypeList: coveragetypesList });
            }
            else {
                showError(response.message);
            }
        })
    }

    _GetUserProfile = async () => {
        GetUserProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.state.searchMdl.userId = response.data.id;
                        this.GetAllPyamentsLinksData(this.state.searchMdl);
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });
    };
    _SendPaymentLink = async (PaymentId) => {
        try {
            const paymentLinksMdl = {
                PaymentId,
                SentBy: this.state.UserDetails.UserName,
                SentTo: this.state.SentTo,
                PaymentAmount: this.state.PaymentAmount,
                SubDomain: this.state.SubDomain.SubDomain,
                Logo: this.state.SubDomain.LogoPath,
                SubDomainId: this.state.SubDomain.SubDomainId,
                InsuranceCompany: this.state.InsuranceCompany
            };

            this.setState({ SendLoader: true });
            $('#btnsendmail, #btncanclemail').prop('disabled', true);
            const response = await SendPaymentLink(paymentLinksMdl);

            if (response.message !== 'Error') {
                if (response.status === true) {
                    window.$('#confirmsendmail').modal('hide');
                    $("#" + PaymentId + "_IsEmail").html("Resend Email");
                    showSuccess(response.message);
                } else {
                    showError(response.message);
                }
                this.setState({ SendLoader: false });
                $('#btnsendmail, #btncanclemail').prop('disabled', false);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    _SendTextPaymentLink = async (PaymentId) => {
        let paymentLinksMdl = {
            PaymentId: PaymentId,
            SentBy: this.state.UserDetails.UserName,
            SentTo: this.state.SentTo,
            PaymentAmount: this.state.PaymentAmount,
            SubDomain: this.state.SubDomain.SubDomain,
            SubDomainId: this.state.SubDomain.SubDomainId
        }
        this.setState({ SendLoader: true }); // show loader befour send message payment link
        $('#btnsendtext, #btncancletext').prop('disabled', true);
        const response = await SendTextPaymentLink(paymentLinksMdl);

        if (response.message !== Error) {
            if (response.status === true) {
                window.$('#confirmsendtext').modal('hide');
                $("#" + PaymentId + "_IsText").html("Resend Text");
                showSuccess(response.message);
            } else {
                showError(response.message);
            }
            this.setState({ SendLoader: false }); // hide loader after send message
            $('#btnsendtext, #btncancletext').prop('disabled', false);
        }
    }
    GetAllPyamentsLinksData = (searchMdl) => {
        this.setState({ SaveLoaderStatus: true }); // show loader befour load data
        GetAllPaymentLinks(searchMdl).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AllPaymentLinksData: response.data.data });
                    this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
                } else {
                    showError(response.message);
                }
                this.setState({ PageLoaderStatus: false, SaveLoaderStatus: false }); // hide loader after load data
            }
        });
    }
    //form submit on enter key down
    _SavePaymentLink_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._SavePaymentLink();
        };
    };

    resetStateValues = () => {
        this.setState({ modifyLink: false, PolicyEffectiveDate: null, PaymentAmount: null, DownPayment: null, MonthlyAmount: null, APR: null, ServiceFee: null, financeCharge: null, policyExpiryDate: null, policyExpDateFormat: null, isPaymentAmountEmpty: true, selectedCoverageType: null, MEPPercentage: null, AgencyID: null, AgencyName: null, AgencyPhoneNumber: null, AgencyAffiliateID: null, AgencyAffiliateName: null, CarrierID: null, LineDescription: null, selectedGCode: null, Errors: {} });
    };

    _SavePaymentLink = async () => {
        let ExpirationDate = new Date();
        let PolicyExpirationDate = new Date(this.state.PolicyEffectiveDate);
        // Modify the dates
        ExpirationDate.setDate(ExpirationDate.getDate() + 20);
        PolicyExpirationDate.setFullYear(PolicyExpirationDate.getFullYear() + 1);
        this.setState({ linkexpirydate: ExpirationDate });
        this.setState({ policyExpiryDate: PolicyExpirationDate });
        // POST request using fetch with async/await
        let PaymentLinksMdl = {
            InsuredName: this.refs.Name.value,
            AgentID: JSON.parse(localStorage.getItem("UserCredential")).CustomerId,
            EmailAddress: this.refs.EmailAddress.value,
            TotalPremium: this.state.PaymentAmount,
            DueDate: this.state.DueDateOfPayment,
            PhoneNumber: this.refs.PhoneNumber.value,
            ExpirationDate: this.state.policyExpiryDate,
            //InvoiceNumber: this.refs.InvoiceNumber.value,
            PolicyStatus: "Active",
            //CoverageType: this.refs.CoverageType.value,
            PolicyEffectiveDate: this.state.PolicyEffectiveDate,
            Address: this.refs.Address.value,
            AddressLine2: this.refs.AddressLine2.value,
            AddressLine3: this.refs.AddressLine3.value,
            City: this.refs.City.value,
            State: this.state.selectedState !== null ? this.state.selectedState.value : this.state.State,
            Zip: this.refs.Zip.value,
            PolicyNumber: this.refs.PolicyNumber?.value,
            DownPayment: this.state.DownPayment,
            MonthlyAmount: this.state.MonthlyAmount,
            //TermLength: parseInt(this.refs.TermLength?.value),
            Logo: this.state.SubDomain.LogoPath,
            //TermLength: parseInt(this.refs.TermLength?.value),
            //Name: this.refs.Name.value,
            //InsuranceCompany: this.refs.InsuranceCompany.value,      
            PaymentAmount: this.state.PaymentAmount,
            //MonthlyAmount: this.state.MonthlyAmount,
            //ServiceFee: this.state.ServiceFee,
            //FinanceCharge: this.state.financeCharge,
            //APR: this.state.APR,
            //DownPayment: this.state.DownPayment,
            //DueDateOfPayment: this.state.DueDateOfPayment,
            SentBy: this.state.UserDetails.UserName?.trim(),
            SentTo: this.refs.EmailAddress.value,
            //SubDomain: this.state.SubDomain.SubDomain,
            SubDomainId: this.state.SubDomain.SubDomainId,
            //Logo: this.state.SubDomain.LogoPath,
            //IsExpired: this.state.isExpired,
            //SubDomainId: this.state.SubDomain.SubDomainId,
            //AISCompanyCode: this.state.modifyLink ? this.state.AISCompanyCode : "",//this.state.selectedComapany !== null ? this.state.selectedComapany.value : this.state.AISCompanyCode,
            LinkExpiryDate: this.state.linkexpirydate,
            QuoteId: this.refs.QuoteId?.value || '',
            ContractId: this.refs.ContractId?.value || '',
            ProcId: this.refs.ProcId?.value || '',
            EntityId: this.refs.EntityId?.value || '',
            ContractSetId: this.refs.ContractsetId?.value || '',
            PrinBrokEntityId: this.refs.PrinBrokEntityId?.value || '',
            //QuoteFlag: this.state.QuoteFlag,
            modifyLink: this.state.modifyLink,
            PaymentId: this.state.PaymentId,
            //IsPremium: this.state.isPremiumfinance,
            //IsDirectBilling: this.state.isDirectBilling,
            //Note: this.state.Note,
            //IsApi: false,
            //isAIS: this.state.isAIS,
            //isBDSecure: this.state.isBDSecure,
            Book_Entity_Id: this.refs.BookEntityId?.value || '',
            Book_Loc_Id: this.refs.BookLocId?.value || '',
            Line_code: this.refs.LineCode?.value || '',
            Bill_to_Role_Type: this.refs.BillToRoleType?.value || '',
            Bill_to_Entity: this.refs.BillToEntity?.value || '',
            Receivable_Role: this.refs.ReceivableRole?.value || '',
            Contract_Line_Id: this.refs.ContractLineId?.value || '',
            Contract_Num: this.refs.ContractNum?.value || '',
            Contract_State: this.state.selectedState !== null ? this.state.selectedState.value : this.state.State,
            PolicyTaxesAndFees: this.state?.policyTaxesAndFees || this.state.StaticTaxesAndFees,
            PolicyPremium: this.state.PolicyPaymentAmount,
            Expiration_Date_Policy: this.state.policyExpiryDate,
            MEPPercentage: (this.state.MEPPercentage == null) ? 0 : this.state.MEPPercentage,
            InsuranceCompany: this.state.selectedComapany !== null ? this.state.selectedComapany?.value : this.state.selectedComapany,
            CoverageType: this.state.selectedCoverageType !== null ? this.state.selectedCoverageType?.value : this.state.selectedCoverageType,
            AgencyID: parseInt(this.refs.AgencyID?.value) || 0,
            AgencyName: this.refs.AgencyName?.value || '',
            AgencyPhoneNumber: this.refs.AgencyPhoneNumber?.value || '',
            AgencyAffiliateID: parseInt(this.refs.AgencyAffiliateID?.value) || 0,
            AgencyAffiliateName: this.refs.AgencyAffiliateName?.value || '',
            CarrierID: parseInt(this.refs.CarrierID?.value) || 0,
            LineDescription: this.refs.LineDescription?.value || '',
            Term: parseInt(this.refs.TermLength?.value) || 10,
            GCode: this.state.selectedGCode !== null ? this.state.selectedGCode?.value : this.state.selectedGCode,
            GeneralAgent: this.refs.GeneralAgent?.value || '',
        }
        let status = await this.PaymentLinkValidation(PaymentLinksMdl);
        if (status) {
            this.setState({ SaveLoaderStatus: true }); // show loader befour  data save
            try {
                let response;
                if (PaymentLinksMdl.modifyLink) {
                    response = await ModifyPaymentLink(PaymentLinksMdl);
                } else {
                    response = await SavePaymentLink(PaymentLinksMdl);
                }

                if (response.message !== "Error") {
                    if (response.status === true) {
                        $("#btncloseMdl").click(); // close model after  data save
                        showSuccess(response.message);
                        $("#frmpaymentlink").trigger("reset");
                        this.GetAllPyamentsLinksData(this.state.searchMdl);
                        this.resetStateValues();
                    } else {
                        showError(response.message);
                    }
                }

            } catch (error) {
                console.log(error);
                showError("An error occurred while saving payment link.");
            } finally {
                this.setState({ SaveLoaderStatus: false });
            }
        }
    }

    handleeffectivedatechange = (date) => {
        this.setState({ PolicyEffectiveDate: date });
        let expirationDate = new Date(date);
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        this.setState({ policyExpiryDate: expirationDate });
        this.state.policyExpDateFormat = new Date(expirationDate);
    }

    handlePageClick = (data) => {
        let PageNumber = data.selected + 1;
        this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
        this.GetAllPyamentsLinksData(this.state.searchMdl);
    }
    handleShowChange = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
        this.GetAllPyamentsLinksData(this.state.searchMdl);
    }
    handleSearchData = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
        this.GetAllPyamentsLinksData(this.state.searchMdl);
    }
    IsPremiumFinance = (event) => {
        if (event.target.checked) {
            this.setState(Object.assign(this.state.IsQuoteFlag, { premiumFinance: true }));
        }
        else {
            this.setState(Object.assign(this.state.PartnerMdl, { premiumFinance: false }));
        }
    }
    handleChangeTest = (event) => {
        this.setState({ Note: event.target.value });
    }

    validateRequiredFields = (data, fieldName, errors) => {
        if (data === null || data === undefined || data === "") {
            errors[`IsEmpty_${fieldName}`] = true;
            errors[`${fieldName}EmptyMsg`] = `Please fill the required field: ${fieldName}`;
            return 1
        } else {
            return 0
        }
        return count
    };
    PaymentLinkValidation = async (PaymentLinksMdl) => {
        let errors = {};
        let count = 0;

        const validatePhoneNumber = () => {
            if (PaymentLinksMdl.PhoneNumber || PaymentLinksMdl.PhoneNumber.trim() !== "") {
                const pattern = new RegExp(/^((\+0?1\s)?)\(?\d{3}\)?[\s.\s]\d{3}[\s.-]\d{4}$/);
                if (!pattern.test(PaymentLinksMdl.PhoneNumber)) {
                    errors['IsEmpty_PhoneNumber'] = true;
                    errors['PhoneNumberEmptyMsg'] = "Please enter a valid phone number";
                    count++;
                }
            }
            //if (!PaymentLinksMdl.PhoneNumber || PaymentLinksMdl.PhoneNumber.trim() === "") {
            //    errors['IsEmpty_PhoneNumber'] = true;
            //    errors['PhoneNumberEmptyMsg'] = "Please fill the required field: Phone Number";
            //    count++;
            //} else {
            //    //
            //}
        };

        const validateEmailAddress = () => {
            if (!PaymentLinksMdl.EmailAddress || PaymentLinksMdl.EmailAddress.trim() === "") {
                errors['IsEmpty_EmailAddress'] = true;
                errors['EmailAddressEmptyMsg'] = "Please fill the required field: Email Address";
                count++;
            } else if (!validator.isEmail(PaymentLinksMdl.EmailAddress)) {
                errors['IsEmpty_EmailAddress'] = true;
                errors['EmailAddressEmptyMsg'] = "Please enter a valid email address";
                count++;
            }
        };

        validatePhoneNumber();
        validateEmailAddress();
        for (let item of PaymentLinksMdl.PolicyTaxesAndFees) {
            if (typeof item !== "object" || item === null) {
                return;
            }
            for (let key in item) {
                if (this.state.SubDomain.SubDomainId == 3 && key === 'Line_Code') {
                    continue; // Skip the validation for this condition
                }
                 count += this.validateRequiredFields(item[key], key, errors);
            }
        }
        if (this.state.PolicyPaymentAmount !== null && this.state.PolicyPaymentAmount.length > 0) {
            for (let i = 0; i < this.state.PolicyPaymentAmount.length; i++) {
                count += this.validateRequiredFields(this.state.PolicyPaymentAmount[i].Line_Code, 'Line_Code', errors);
                count += this.validateRequiredFields(this.state.PolicyPaymentAmount[i].Written_Premium, 'Written_Premium', errors);
                count += this.validateRequiredFields(this.state.PolicyPaymentAmount[i].Tran_Code, 'Tran_Code', errors);
            }
        }

        count += this.validateRequiredFields(PaymentLinksMdl.TotalPremium, 'PaymentAmount', errors);
        //count += this.validateRequiredFields(PaymentLinksMdl., 'PaymentAmount', errors);
        //if (this.state.isPremiumfinance && !PaymentLinksMdl.QuoteFlag) {
        //    count += this.validateRequiredFields(PaymentLinksMdl.FinanceCharge, 'FinanceCharge', errors);
        //}

        //if (!PaymentLinksMdl.QuoteFlag) {
            
            //if (this.state.isPremiumfinance) {
                
                //count += this.validateRequiredFields(PaymentLinksMdl.APR, 'APR', errors);
            //}
            //if (isNaN(PaymentLinksMdl.TermLength)) {
            //    errors['IsEmpty_TermLength'] = true;
            //    errors['TermLengthEmptyMsg'] = "Please fill the required fields : # of Payments";
            //    count++;
            //}
        if (!this.state.SubDomain.isAISDB && !this.state.SubDomain.isBDSecurePF && !this.state.SubDomain.isFinanceProPF) {
                count += this.validateRequiredFields(PaymentLinksMdl.DownPayment, 'DownPayment', errors);
                count += this.validateRequiredFields(PaymentLinksMdl.MonthlyAmount, 'MonthlyPayment', errors);
                if (parseFloat(PaymentLinksMdl.DownPayment) > parseFloat(this.state.PaymentAmount)) {
                    errors['IsEmpty_DownPayment'] = true;
                    errors['DownPaymentEmptyMsg'] = "Down Payment must not be greater than Total Premium";
                    count++;
                }
            }
            
        //}
        //count += this.validateRequiredFields(PaymentLinksMdl.ServiceFee, 'ServiceFee', errors);
        //count += this.validateRequiredFields(PaymentLinksMdl.InvoiceNumber, 'InvoiceNumber', errors);
        //count += this.validateRequiredFields(PaymentLinksMdl.AISCompanyCode, 'CompanyName', errors);
        if (this.state.SubDomain.SubDomainId == 2) {
            count += this.validateRequiredFields(PaymentLinksMdl.ProcId, 'ProcId', errors);
            //count += this.validateRequiredFields(PaymentLinksMdl.QuoteId, 'QuoteId', errors);
            count += this.validateRequiredFields(PaymentLinksMdl.ContractId, 'ContractId', errors);
            //count += this.validateRequiredFields(PaymentLinksMdl.EntityId, 'EntityId', errors);
            //count += this.validateRequiredFields(PaymentLinksMdl.ContractSetId, 'ContractsetId', errors);
            //count += this.validateRequiredFields(PaymentLinksMdl.PrinBrokEntityId, 'PrinBrokEntityId', errors);
            count += this.validateRequiredFields(PaymentLinksMdl.Book_Entity_Id, 'BookEntityId', errors);
            count += this.validateRequiredFields(PaymentLinksMdl.Book_Loc_Id, 'BookLocId', errors);
            count += this.validateRequiredFields(PaymentLinksMdl.Line_code, 'LineCode', errors);
            count += this.validateRequiredFields(PaymentLinksMdl.Bill_to_Role_Type, 'BillToRoleType', errors);
            count += this.validateRequiredFields(PaymentLinksMdl.Bill_to_Entity, 'BillToEntity', errors);
            count += this.validateRequiredFields(PaymentLinksMdl.Receivable_Role, 'ReceivableRole', errors);
            count += this.validateRequiredFields(PaymentLinksMdl.Contract_Line_Id, 'ContractLineId', errors);
            count += this.validateRequiredFields(PaymentLinksMdl.Contract_Num, 'ContractNum', errors);
        }

        count += this.validateRequiredFields(PaymentLinksMdl.InsuredName, 'Name', errors);
        //count += this.validateRequiredFields(PaymentLinksMdl.PolicyNumber, 'PolicyNumber', errors);
        count += this.validateRequiredFields(PaymentLinksMdl.City, 'City', errors);
        //count += this.validateRequiredFields(PaymentLinksMdl.DueDate, 'DueDateOfPayment', errors);
        count += this.validateRequiredFields(PaymentLinksMdl.Address, 'Address', errors);
        //count += this.validateRequiredFields(PaymentLinksMdl.ExpirationDate, 'LinkExpiryDate', errors);
        count += this.validateRequiredFields(PaymentLinksMdl.PolicyEffectiveDate, 'PolicyEffectiveDate', errors);
        count += this.validateRequiredFields(PaymentLinksMdl.State, 'State', errors);
        count += this.validateRequiredFields(PaymentLinksMdl.Zip, 'Zip', errors);
        if (['3', '5', '6'].includes(this.state.SubDomain.SubDomainId)) {
            count += this.validateRequiredFields(PaymentLinksMdl.InsuranceCompany, 'InsuranceCompany', errors);
            count += this.validateRequiredFields(PaymentLinksMdl.CoverageType, 'CoverageType', errors);
        }

        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }

    ViewLogs = (paymentId) => {
        this.setState({
            expanded: {
                [paymentId]: !this.state.expanded[paymentId]
            }
        });
        this.setState({ Logs: [] })
        GetLogsByPaymentId(paymentId).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ Logs: response.data });
                }
                else {
                    showError(response.message);
                }
                this.setState({ SaveLoaderStatus: false }); // hide loader after  data edit
            }
        });
    };

    clearFormOnClose = () => {
        $("#frmpaymentlink").trigger("reset");
        this.setState({
            modifyLink: false,
            selectedState: null,
            selectedComapany: null,
            selectedGCode: null,
            Errors: {},
            isPaymentAmountEmpty: true,
            //InvoiceNumber: null,
            TermLength: null,
            Name: null,
            PolicyNumber: null,
            CoverageType: null,
            Address: null,
            AddressLine2: null,
            AddressLine3: null,
            City: null,
            State: null,
            Zip: null,
            InsuranceCompany: null,
            PhoneNumber: null,
            EmailAddress: null,
            PaymentAmount: null,
            MonthlyAmount: null,
            ServiceFee: null,
            financeCharge: null,
            APR: null,
            DownPayment: null,
            PolicyEffectiveDate: null,
            IsExpired: false,
            AISCompanyCode: null,
            CoverageType: null,
            //LinkExpiryDate: null,
            QuoteId: null,
            ContractId: null,
            ProcId: null,
            QuoteFlag: false,
            PaymentId: null,
            EntityId: null,
            ContractSetId: null,
            PrinBrokEntityId: null,
            Note: null,
            BookEntityId: null,
            BookLocId: null,
            LineCode: null,
            ExpirationDatePolicy: null,
            Term: null,
            ContractState: null,
            BillToRoleType: null,
            BillToEntity: null,
            ReceivableRole: null,
            ContractLineId: null,
            ContractNum: null,
            policyTaxesAndFees: [],
            StaticTaxesAndFees: [
                { Type: "F", Description: "Policy Fee", Amount: 0 },
                { Type: "F", Description: "Tax/Stamp Fee", Amount: 0 },
                { Type: "F", Description: "Broker Fee", Amount: 0 },
                { Type: "F", Description: "Inspection Fee", Amount: 0 }
            ],
            PolicyPaymentAmount: [],
            MEPPercentage: null,
            AgencyID: null,
            AgencyName: null,
            AgencyPhoneNumber: null,
            AgencyAffiliateID: null,
            AgencyAffiliateName: null,
            CarrierID: null,
            LineDescription: null,
            selectedCoverageType: null,
            showForm: false,
            generalAgent: null,
            GeneralAgent: null
        });
    }

    _GetCompanyCode = () => {
        GetAllCompany().then(data => {
            let response = data;
            let companyList = [];
            if (response.status === true) {
                for (let i = 0; i < response.data.length; i++) {
                    companyList.push({ 'value': response.data[i].uspF_C_Code, 'label': response.data[i].insuranceCompanyName});
                }
                this.setState({ AllCompanyData: companyList });
            }
            else {
                showError(response.message);
            }
        });
    }

    _GetAllGCodesList = () => {
        GetAllGCodes().then(data => {
            let response = data;
            let agencyList = [];
            if (response.status === true) {
                for (let i = 0; i < response.data.length; i++) {
                    agencyList.push({ 'value': response.data[i].uspfGCode, 'label': response.data[i].uspfName });
                }
                this.setState({ AllGCodesData: agencyList });
            }
            else {
                showError(response.message);
            }
        });
    }

    handleGCode = (selectedGCode) => {
        this.setState({ selectedGCode: selectedGCode });
    };

    handleChange = (selectedComapany) => {
        this.setState({ selectedComapany: selectedComapany });
    };
    coveragetypehandlechange = (selectedCoverageType) => {
        this.setState({ selectedCoverageType: selectedCoverageType });
    };
    IsQuoteFlag = (event) => {
        if (event.target.checked)
            this.setState({ QuoteFlag: true });
        else
            this.setState({ QuoteFlag: false });
    }

    stateHandleChange = (selectedState) => {
        this.setState({ selectedState: selectedState });
    };
    convertKeysToPascalCase(obj) {
        const newObj = {};
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newKey = key.charAt(0).toUpperCase() + key.slice(1);
                newObj[newKey] = obj[key];
            }
        }
        return newObj;
    }

    generatelink = (target) => {
        this.setState({ SaveLoaderStatus: true });
        GetPolicyFees(target.paymentId).then(res => {
            let response = res;
            if (response.status === true) {
                const convertedData = response.data.map(item => this.convertKeysToPascalCase(item));
                this.setState({ policyTaxesAndFees: convertedData, StaticTaxesAndFees: convertedData });
                convertedData.length > 1 ? "" : this.setState({
                    StaticTaxesAndFees: [
                        { Type: "F", Description: "Policy Fee", Amount: 0 },
                        { Type: "F", Description: "Tax/Stamp Fee", Amount: 0 },
                        { Type: "F", Description: "Broker Fee", Amount: 0 },
                        { Type: "F", Description: "Inspection Fee", Amount: 0 }
                    ]
                });
                const totalAmount = convertedData.reduce((acc, fee) => acc + fee.Amount, 0);
                this.setState({ PaymentAmount: target.paymentAmount - totalAmount });
            } else {
                showError(response.message);
            }
        });
        GetPolicyPremium(target.paymentId).then(res => {
            let response = res;
            if (response.status === true) {
                const convertedData = response.data.map(item => this.convertKeysToPascalCase(item));
                this.setState({ PolicyPaymentAmount: convertedData });
            } else {
                showError(response.message);
            }
        });
        GetPaymentLinksById(target.paymentId).then(res => {
            let response = res;
            if (response.status === true) {
                this.setState({
                    selectedCoverageType: this.state.CoverageTypeList.find(coveragetype => coveragetype.value === response.data.pfCoverageType),
                    selectedComapany: this.state.AllCompanyData.find(company => company.value === response.data.insuranceCompany),
                    selectedGCode: this.state.AllGCodesData.find(gcode => gcode.value === response.data.gCode),
                    MEPPercentage: response.data.mepPercentage,
                    PolicyNumber: (response.data.policyNumberFP || response.data.policyNumberDB),
                })
            } else {
                showError(response.message);
            }
            this.setState({ SaveLoaderStatus: false });
        });
        this.setState({ modifyLink: true })
        this.setState({
            //InvoiceNumber: target.invoiceNumber,
            TermLength: parseInt(target.termLength),
            Name: target.name,
            //CoverageType: target.coverageType,
            Address: target.address,
            AddressLine2: target.addressLine2,
            AddressLine3: target.addressLine3,
            City: target.city,
            State: target.state,
            Zip: target.zip,
            //InsuranceCompany: target.insuranceCompany,
            PhoneNumber: target.phoneNumber.replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3"),
            EmailAddress: target.emailAddress,
            //PaymentAmount: target.paymentAmount,
            MonthlyAmount: target.monthlyAmount,
            ServiceFee: target.serviceFee,
            FinanceCharge: target.financeCharge,
            APR: target.apr,
            DownPayment: target.downPayment,
            DueDateOfPayment: new Date(),
            PolicyEffectiveDate: new Date(target.policyEffectiveDate),
            policyExpDateFormat: new Date(target.expiration_Date_Policy),
            IsExpired: target.isExpired,
            AISCompanyCode: target.aisCompanyCode,
            //LinkExpiryDate: new Date(target.linkExpiryDate),
            QuoteId: target.quoteId,
            ContractId: target.contractId,
            ProcId: target.procId,
            QuoteFlag: target.quoteFlag,
            PaymentId: target.paymentId,
            financeCharge: target.financeCharge,
            EntityId: target.entityId,
            ContractSetId: target.contractSetId,
            PrinBrokEntityId: target.prinBrokEntityId,
            Note: target?.note,
            BookEntityId: target?.book_Entity_Id,
            BookLocId: target?.book_Loc_Id,
            LineCode: target?.line_code,
            BillToRoleType: target?.bill_to_Role_Type,
            BillToEntity: target?.bill_to_Entity,
            ReceivableRole: target?.receivable_Role,
            ContractLineId: target?.contract_Line_ID,
            ContractNum: target?.contract_Num,
            AgencyID: target?.agencyID,
            AgencyName: target?.agencyName,
            AgencyAffiliateID: target?.agencyAffiliateID,
            AgencyAffiliateName: target?.agencyAffiliateName,
            AgencyPhoneNumber: target?.agencyPhoneNumber,
            CarrierID: target?.carrierID,
            LineDescription: target?.lineDescription,
            QuoteId: target?.quoteId,
            EntityId: target?.entityId,
            PrinBrokEntityId: target?.prinBrokEntityId,
            GeneralAgent: target?.generalAgent,
        });
        window.$('#modalGenerateLink').modal('show');
    }

    render() {
        const { Logs } = this.state;
        return (
            <>
                <div className="main-wrapper-newDesign w-100">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-xxl-6 col-lg-4 col-md-4 col-sm-5 col-12">
                                <h1 className="h3 mb-0 fw-normal"><strong>Payment Links</strong></h1>
                            </div>
                            {this.props.props !== true &&
                                <div className="col-xl-6 col-xxl-6 col-lg-8 col-md-8 col-sm-7 col-12 text-end">
                                    <button type="button" id="Login" className="btn btn-newDesign btn-header" data-bs-target="#modalGenerateLink" onClick={() => this.clearFormOnClose()} data-bs-toggle="modal">
                                        <span className="label">Generate New Payment Link</span>
                                    </button>
                                </div>
                            }

                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <div className="card flex-fill mt-4 border-light w-100 dashboard-card-newDesign">
                                    <div className="card-body">
                                        <div className="dashboard-options d-flex">
                                            <NavLink to="#" onClick={() => this.GetAllPyamentsLinksData(this.state.searchMdl)} className="p-2 refresh-icons">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#008592" width="24" height="24" viewBox="0 0 24 24">
                                                        <path d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z" />
                                                    </svg>
                                                </span>
                                            </NavLink>
                                            <div className="card-header bg-white border-white p-2 dashboard-input-size">
                                                <input type="text" className="form-control text-search-newDesign" onChange={this.handleSearchData} id="search" placeholder="Search..." />
                                                {this.state.SaveLoaderStatus &&
                                                    <span style={{ position: "absolute", right: "222px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                                }
                                            </div>
                                            <div className="card-header bg-white border-white p-2">
                                                <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleShowChange}>
                                                    <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-100 table-responsive">
                                            <table className="table insured-table mb-0">
                                                <thead>
                                                    <tr>
                                                        {/*<th>Invoice #</th>*/}
                                                        <th>Name</th>
                                                        {/*<th>Policy Number</th>*/}
                                                        {/* <th>CoverageType</th>*/}
                                                        {/*<th>Phone Number</th>*/}
                                                        <th>Email</th>
                                                        <th>Amount</th>
                                                        {/*<th>Term Length</th>*/}
                                                        <th>Due Date</th>
                                                        {/*<th>Policy Effective Date</th>*/}
                                                        <th>Status</th>
                                                        <th>Final Status</th>
                                                        {/*<th>Note</th>*/}
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.AllPaymentLinksData.map((items) => (
                                                        <Fragment key={items.id}>
                                                            <tr>
                                                                {/*<td>{items.invoiceNumber}</td>*/}
                                                                <td>{items.name}</td>
                                                                {/*<td>{items.policyNumber}</td>*/}
                                                                {/*<td>{items.coverageType}</td>*/}
                                                                {/*<td>{items.phoneNumber}</td>*/}
                                                                <td>{items.emailAddress}</td>
                                                                <td>{CustomCurrencyFormat(items.paymentAmount)}</td>
                                                                {/*<td>{items.termLength}</td>*/}
                                                                <td>{DateFormat(items.dueDateOfPayment)}</td>
                                                                {/*<td>{DateFormat(items.policyEffectiveDate)}</td>*/}
                                                                <td>{items.paymentStatus ? (<span className="paid">Complete</span>) : (new Date(items.dueDateOfPayment) >= new Date() ? ("OnTime") : ("Late"))}</td>
                                                                <td>{items.paymentStatus ? (<span className="paid">Paid</span>) : ("Unpaid")}</td>
                                                                {/* <td>{items.note}</td> */}
                                                                <td>
                                                                    <button className={this.state.expanded[items.paymentId] ? 'active btn btn-newDesign' : 'btn btn-newDesign'} onClick={() => this.ViewLogs(items.paymentId)} >
                                                                        View
                                                                    </button>
                                                                </td>
                                                                {this.props.props !== true &&
                                                                    <>
                                                                    <td>
                                                                        <button className="btn btn-newDesign-foil" type="button" id={items.paymentId + "_IsEmail"} data-bs-toggle="modal" data-bs-target="#confirmsendmail" onClick={() => this.setState({ PaymentId: items.paymentId, SentTo: items.emailAddress, PaymentAmount: items.paymentAmount })}>
                                                                            Send Email
                                                                            {items.isEmailSent === 'True' ? ("") : ("")} 
                                                                        </button>
                                                                        </td>
                                                                    <td colSpan="2">
                                                                        <button className="btn btn-newDesign-foil" type="button" id={items.paymentId + "_IsText"} data-bs-toggle="modal" data-bs-target="#confirmsendtext" onClick={() => this.setState({ PaymentId: items.paymentId, SentTo: items.phoneNumber, PaymentAmount: items.paymentAmount })}>
                                                                            Text Link
                                                                            {items.isTextSent === 'True' ? ("") : ("")}
                                                                        </button>
                                                                    </td>
                                                                    {subDomain().SubDomain.toLowerCase() !== 'xgen' && !items.paymentStatus &&
                                                                        <td>
                                                                            <button className="btn btn-newDesign-foil" type="button" data-bs-toggle="modal" data-bs-target="#modalGenerateLink" onClick={() => this.generatelink(items)}>
                                                                                Modify Link
                                                                            </button>
                                                                        </td>
                                                                    }
                                                                    {subDomain().SubDomain.toLowerCase() === 'xgen' && !items.paymentStatus &&
                                                                        <td>
                                                                            <button className="btn btn-newDesign-foil" type="button" data-bs-toggle="modal" data-bs-target="#modalGenerateLink" onClick={() => this.generatelink(items)}>
                                                                                Modify Link
                                                                            </button>
                                                                        </td>
                                                                    }
                                                                    {subDomain().SubDomain.toLowerCase() === 'xgen' && items.paymentStatus &&
                                                                        <td>
                                                                        </td>
                                                                    }
                                                                    {subDomain().SubDomain.toLowerCase() !== 'xgen' && items.paymentStatus &&
                                                                        <td>
                                                                        </td>
                                                                    }
                                                                    </>
                                                                }
                                                            </tr>
                                                            {this.state.expanded[items.paymentId] && Logs.length !== 0 &&
                                                                <tr className="table-bg">
                                                                    <td colSpan="10" className="pd-space">
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Sent By</th>
                                                                                    <th>Sent To</th>
                                                                                    <th>Sent At</th>
                                                                                    <th>Payment Amount</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {Logs.map((logItems, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{logItems.sentBy}</td>
                                                                                        <td>{logItems.sentTo}</td>
                                                                                        <td>{logItems.createdDate}</td>
                                                                                        <td>{CustomCurrencyFormat(logItems.paymentAmount)}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {this.state.expanded[items.paymentId] && Logs.length === 0 &&
                                                                <tr className="table-bg">
                                                                    <td colSpan="10" className="pd-space">No Data</td>
                                                                </tr>
                                                            }
                                                        </Fragment>
                                                    ))
                                                    }

                                                </tbody>
                                            </table>

                                        </div>
                                        <p className="text-center">
                                            {(this.state.Pagecount === 0 && this.state.PageLoaderStatus === false) && "No Record Found"}
                                        </p>
                                        <div>
                                            { /*loader show befoure load contents*/}
                                            {this.state.PageLoaderStatus && <LoaderContents />}
                                        </div>
                                        <div className="d-flex paginate-div">
                                            <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={this.state.Pagecount ?? 0}
                                            marginPagesDisplayed={"4"}
                                            pageRangeDisplayed={"3"}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination justify-content-start"}
                                            pageClassName={"page-item page-item-none"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item page-item-none"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                            disabledLinkClassName={"disabled"} />
                                            <div className="pagination-page">
                                                Page {this.state.searchMdl.PageNumber} of {this.state.Pagecount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SiteFooter /> {/*this call for site footer */}
                </div>
                { /*model for create payment link */}
                <div className="modal fade" tabIndex="-1" id="modalGenerateLink" aria-labelledby="GenerateLink" aria-hidden="true">
                    <div className="modal-dialog  modal-xl  modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center pt-4 border-0">
                                <h5 className="modal-title text-center">{this.state.modifyLink ? "Modify Link" : "Generate Link"}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="pt2px">
                                    { /*loader show befoure load contents*/}
                                    {this.state.SaveLoaderStatus && <LoaderContents />}
                                </div>
                                <form className="m-auto form-box" id="frmpaymentlink" onKeyDown={this._SavePaymentLink_EnterButton}>
                                    <div className="row">
                                        <div className="modal-header pt-4 border-0">
                                            <h5 className="modal-title text-left">{['3', '5', '6'].includes(this.state.SubDomain.SubDomainId) ? "Insured Info" : ""}</h5>
                                        </div>
                                        
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                            <div className="form-floating input-wrapper-payment mb-3">
                                                <input type="text" className={(this.state.Errors['IsEmpty_Name'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="Name" id="Name" name="Name" defaultValue={this.state.Name} placeholder="Name*" required />
                                                {this.state.Errors['IsEmpty_Name'] === true ? <div className="has-err-msg">{this.state.Errors['NameEmptyMsg']}</div> : ''}
                                                <label htmlFor="Name">Name</label>
                                            </div>
                                        </div>

                                        {/*<div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*    <div className="form-floating input-wrapper-payment mb-3">*/}
                                        {/*        <input type="text" className="form-control" ref="InsuranceCompany" id="insuranceCompany" aria-describedby="insuranceCompany" defaultValue={this.state.InsuranceCompany} disabled={this.state.modifyLink} placeholder="Insurance Company" />*/}
                                        {/*        <label htmlFor="insuranceCompany">Insurance Company</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                            <div className="form-floating input-wrapper-payment mb-3">
                                                <input type="text" className={(this.state.Errors['IsEmpty_Address'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="Address" id="Address" name="Address" aria-describedby="address" defaultValue={this.state.Address} placeholder="Address*" required />
                                                {this.state.Errors['IsEmpty_Address'] === true ? <div className="has-err-msg">{this.state.Errors['AddressEmptyMsg']}</div> : ''}
                                                <label htmlFor="address">Address</label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                            <div className="form-floating input-wrapper-payment mb-3">
                                                <input type="text" className="input-newDesign form-control" ref="AddressLine2" id="AddressLine2" aria-describedby="AddressLine2" defaultValue={this.state.AddressLine2} placeholder="Address Line 2" />
                                                <label htmlFor="AddressLine2">Address Line 2</label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                            <div className="form-floating input-wrapper-payment mb-3">
                                                <input type="text" className="input-newDesign form-control" ref="AddressLine3" id="AddressLine3" aria-describedby="AddressLine3" defaultValue={this.state.AddressLine3} placeholder="Address Line 3" />
                                                <label htmlFor="AddressLine3">Address Line 3</label>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                            <div className="form-floating input-wrapper-payment mb-3">
                                                <input type="text" className={(this.state.Errors['IsEmpty_City'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="City" id="City" name="City" aria-describedby="City" defaultValue={this.state.City} placeholder="City*" required />
                                                {this.state.Errors['IsEmpty_City'] === true ? <div className="has-err-msg">{this.state.Errors['CityEmptyMsg']}</div> : ''}
                                                <label htmlFor="city">City</label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                            <div className="input-wrapper-payment text-left mb-3">
                                                <Select
                                                    value={this.state.modifyLink ? this.state.StateList.find(state => state.value === this.state.State) : this.state.selectedState === null ? this.state.editStateValue : this.state.selectedState}
                                                    onChange={this.stateHandleChange}
                                                    options={this.state.StateList}
                                                    false
                                                    placeholder={'State'}
                                                    closeMenuOnSelect={true}
                                                    true
                                                    id={'State'}
                                                    ref={'State'}
                                                    className={(this.state.Errors['IsEmpty_State'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                />
                                                {this.state.Errors['IsEmpty_State'] === true ? <div className="has-err-msg">{this.state.Errors['StateEmptyMsg']}</div> : ''}
                                            </div>
                                        </div>
                                        {/*<div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*	<div className="form-floating input-wrapper-payment mb-3">*/}
                                        {/*		<input type="text" className="form-control" ref="State" id="State" aria-describedby="state" placeholder="State" defaultValue="CA" readOnly />*/}
                                        {/*		<label htmlFor="state">State</label>*/}
                                        {/*	</div>*/}
                                        {/*</div>*/}
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                            <div className="form-floating input-wrapper-payment mb-3">
                                                <input type="text" className={(this.state.Errors['IsEmpty_Zip'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="Zip" id="Zip" aria-describedby="zip" defaultValue={this.state.Zip} placeholder="Zip" maxLength="5" />
                                                {this.state.Errors['IsEmpty_Zip'] === true ? <div className="has-err-msg">{this.state.Errors['ZipEmptyMsg']}</div> : ''}
                                                <label htmlFor="zip">Zip</label>
                                            </div>
                                        </div>
                                        
                                        {/*<div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*    <div className="form-floating input-wrapper-payment mb-3">*/}
                                        {/*        <input type="text" className={(this.state.Errors['IsEmpty_CoverageType'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="CoverageType" id="coverageType" aria-describedby="coverageType" defaultValue={this.state.CoverageType} disabled={this.state.modifyLink} placeholder="Coverage Type" />*/}
                                        {/*        {this.state.Errors['IsEmpty_CoverageType'] === true ? <div className="has-err-msg">{this.state.Errors['CoverageTypeEmptyMsg']}</div> : ''}*/}
                                        {/*        <label htmlFor="coverageType">Coverage Type</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                            <div className="form-floating input-wrapper-payment mb-3">
                                                <input type="text" className={(this.state.Errors['IsEmpty_PhoneNumber'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="PhoneNumber" id="PhoneNumber" name="phoneNumber" defaultValue={this.state.PhoneNumber} placeholder="Phone Number*" maxLength="14" required />
                                                {this.state.Errors['IsEmpty_PhoneNumber'] === true ? <div className="has-err-msg">{this.state.Errors['PhoneNumberEmptyMsg']}</div> : ''}
                                                <label htmlFor="PhoneNumber">Phone Number</label>
                                            </div>
                                        </div>

                                        {/*<div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*    <div className="form-floating input-wrapper-payment mb-3">*/}
                                        {/*        <input type="text" className="form-control" ref="CoverageType" id="coverageType" aria-describedby="coverageType" placeholder="Coverage Type" />*/}
                                        {/*        <label htmlFor="coverageType">Coverage Type</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                            <div className="form-floating input-wrapper-payment mb-3">
                                                <input type="email" className={(this.state.Errors['IsEmpty_EmailAddress'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="EmailAddress" id="EmailAddress" defaultValue={this.state.EmailAddress} placeholder="Email Address*" required />
                                                {this.state.Errors['IsEmpty_EmailAddress'] === true ? <div className="has-err-msg">{this.state.Errors['EmailAddressEmptyMsg']}</div> : ''}
                                                <label htmlFor="EmailAddress">Email Address</label>
                                            </div>
                                        </div>
                                        {/*<div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*    <div className="form-floating input-wrapper-payment mb-3">*/}
                                        {/*        <CurrencyFormat*/}
                                        {/*            thousandSeparator={true}*/}
                                        {/*            decimalSeparator="."*/}
                                        {/*            prefix={'$'}*/}
                                        {/*            placeholder="Pure Premium*"*/}
                                        {/*            value={this.state.PaymentAmount ?? ''}*/}
                                        {/*            decimalScale={2}*/}
                                        {/*            fixedDecimalScale={true}*/}
                                        {/*            className={(this.state.Errors['IsEmpty_PaymentAmount'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}*/}
                                        {/*            name="PaymentAmount"*/}
                                        {/*            id="PaymentAmount"*/}
                                        {/*            onValueChange={(values) => {*/}
                                        {/*                this.setState({ PaymentAmount: values.value ?? 0 });*/}
                                        {/*            }}*/}
                                        {/*            required*/}
                                        {/*        />*/}
                                        {/*        */}{/* <input type="number" className={(this.state.Errors['IsEmpty_PaymentAmount'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="PaymentAmount" id="PaymentAmount" placeholder="Payment Amount*" required />*/}
                                        {/*        {this.state.Errors['IsEmpty_PaymentAmount'] === true ? <div className="has-err-msg">{this.state.Errors['PaymentAmountEmptyMsg']}</div> : ''}*/}
                                        {/*        <label htmlFor="PaymentAmount">Pure Premium</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                            <div className="form-floating input-wrapper-payment react-date-picker-box mb-3">
                                                <DatePicker
                                                    selected={this.state.DueDateOfPayment ?? ''}
                                                    //onChange={(date) => this.setstate({ duedateofpayment: date })}
                                                    dateFormat="MMM dd, yyyy"
                                                    locale="en-US"
                                                    ref="duedate"
                                                    placeholderText="Due date of payment"
                                                    id="DueDateOfPayment"
                                                    className={(this.state.Errors['IsEmpty_DueDateOfPayment'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                    disabled={true}
                                                />
                                                {this.state.Errors['IsEmpty_DueDateOfPayment'] === true ? <div className="has-err-msg">{this.state.Errors['DueDateOfPaymentEmptyMsg']}</div> : ''}
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                            <div className="form-floating input-wrapper-payment react-date-picker-box mb-3">
                                                <DatePicker
                                                    selected={this.state.PolicyEffectiveDate ?? ''}
                                                    onChange={(date1) => this.handleeffectivedatechange(date1)}
                                                    dateFormat="MMM dd, yyyy"
                                                    locale="en-US"
                                                    ref="duedate"
                                                    placeholderText="Policy Effective Date"
                                                    id="policyeffectivedate"
                                                    className={(this.state.Errors['IsEmpty_PolicyEffectiveDate'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                    minDate={new Date()} 
                                                />
                                                {this.state.Errors['IsEmpty_PolicyEffectiveDate'] === true ? <div className="has-err-msg">{this.state.Errors['PolicyEffectiveDateEmptyMsg']}</div> : ''}
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4"></div>
                                        {(this.state.SubDomain.SubDomainId == 2) &&
                                            <>
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                <div className="input-wrapper mx-auto mb-3">

                                                    <div className="ml-3">
                                                        <div
                                                            className="btn btn-sm px-5 py-3"
                                                            onClick={this._addPayment}
                                                            disabled={this.state.PolicyPaymentAmount.length >= 4}
                                                        >
                                                            Add Pure Premium +
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.PolicyPaymentAmount.map((fee, index) => (
                                                <div className="row mb-3" key={index}>
                                                    <div className="col-md-2">
                                                        <input
                                                            type="text"
                                                            name="Line_Code"
                                                            className={(this.state.Errors['IsEmpty_Line_Code'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                            placeholder="Line Code"
                                                            value={fee.Line_Code}
                                                            onChange={(e) => this._handleInputPaymentChange(e, index)}
                                                        />
                                                        {this.state.Errors['IsEmpty_Line_Code'] === true ? <div className="has-err-msg">{this.state.Errors['Line_CodeEmptyMsg']}</div> : ''}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <CurrencyFormat
                                                            thousandSeparator={true}
                                                            decimalSeparator="."
                                                            prefix={'$'}
                                                            name="Written_Premium"
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            className={(this.state.Errors['IsEmpty_Written_Premium'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                            placeholder="Written Premium"
                                                            value={fee.Written_Premium}
                                                            onChange={(e) => this._handleInputPaymentChange(e, index)}
                                                        />
                                                        {this.state.Errors['IsEmpty_Written_Premium'] === true ? <div className="has-err-msg">{this.state.Errors['Written_PremiumEmptyMsg']}</div> : ''}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <input
                                                            type="text"
                                                            name="Tran_Code"
                                                            className={(this.state.Errors['IsEmpty_Tran_Code'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                            placeholder="Tran Code"
                                                            value={fee.Tran_Code}
                                                            onChange={(e) => this._handleInputPaymentChange(e, index)}
                                                        />
                                                        {this.state.Errors['IsEmpty_Tran_Code'] === true ? <div className="has-err-msg">{this.state.Errors['Tran_CodeEmptyMsg']}</div> : ''}
                                                    </div>
                                                    <div className="col-md-1">
                                                        <Button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={() => this._removePayment(index)}
                                                        >
                                                            -
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                            </>
                                        }
                                        {(this.state.SubDomain.SubDomain == 'insuredio') &&
                                            <>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <CurrencyFormat
                                                            thousandSeparator={true}
                                                            decimalSeparator="."
                                                            prefix={'$'}
                                                            placeholder="Pure Premium*"
                                                            value={this.state.PaymentAmount ?? ''}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            className={(this.state.Errors['IsEmpty_PaymentAmount'] === true ? ' has-err input-newDesign form-control w-100' : 'input-newDesign form-control w-100')}
                                                            name="PaymentAmount"
                                                            id="PaymentAmount"
                                                            onValueChange={(values) => {
                                                                this.setState({ PaymentAmount: values.value ?? 0 });
                                                            }}
                                                            required
                                                        />
                                                        {this.state.Errors['IsEmpty_PaymentAmount'] === true ? <div className="has-err-msg">{this.state.Errors['PaymentAmountEmptyMsg']}</div> : ''}
                                                        <label htmlFor="PaymentAmount">Pure Premium</label>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {(['3', '5', '6'].includes(this.state.SubDomain.SubDomainId)) &&
                                            <>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <CurrencyFormat
                                                            thousandSeparator={true}
                                                            decimalSeparator="."
                                                            prefix={'$'}
                                                            placeholder="Pure Premium*"
                                                            value={this.state.PaymentAmount ?? ''}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            className={(this.state.Errors['IsEmpty_PaymentAmount'] === true ? ' has-err input-newDesign form-control w-100' : 'input-newDesign form-control w-100')}
                                                            name="PaymentAmount"
                                                            id="PaymentAmount"
                                                            onValueChange={(values) => {
                                                                this.setState({ PaymentAmount: values.value ?? 0 });
                                                            }}
                                                            required
                                                        />
                                                        {this.state.Errors['IsEmpty_PaymentAmount'] === true ? <div className="has-err-msg">{this.state.Errors['PaymentAmountEmptyMsg']}</div> : ''}
                                                        <label htmlFor="PaymentAmount">Pure Premium</label>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <CurrencyFormat
                                                            thousandSeparator={true}
                                                            decimalSeparator="."
                                                            suffix={'%'}
                                                            placeholder="MEP Percentage"
                                                            value={this.state.MEPPercentage ?? ''}
                                                            decimalScale={0}
                                                            fixedDecimalScale={true}
                                                            className={(this.state.Errors['IsEmpty_MEPPercentage'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                            name="MEPPercentage"
                                                            id="MEPPercentage"
                                                            onValueChange={(values) => {
                                                                const { value } = values;
                                                                if (value === '') {
                                                                    this.setState({ MEPPercentage: null });
                                                                } else {
                                                                    const newValue = parseInt(value, 10);
                                                                    if (newValue >= 0 && newValue <= 100) {
                                                                        this.setState({ MEPPercentage: newValue });
                                                                    }
                                                                }
                                                            }}
                                                            isAllowed={(values) => {
                                                                const { floatValue, formattedValue } = values;
                                                                return formattedValue === '' || (floatValue >= 0 && floatValue <= 100);
                                                            }}
                                                            required
                                                    />
                                                    <label htmlFor="MEPPercentage">MEP Percentage</label>
                                                    </div>
                                                </div>
                                            </>

                                        }

                                        {/*{(this.state.isPremiumfinance || this.state.isDirectBilling) &&*/}
                                        {/*    <>*/}
                                        {/*        <div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*            <div className="form-floating mb-3">*/}
                                        {/*                <input type="checkbox" className="form-check-input input-wrapper-radio" id="QuoteFlag" aria-describedby="QuoteFlag"*/}
                                        {/*                    checked={this.state.QuoteFlag} disabled={this.state.modifyLink} onChange={this.IsQuoteFlag} />*/}
                                        {/*                <label className="form-check-label checkbox-label" htmlFor="QuoteFlag">Quote Flag </label>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </>*/}
                                        {/*}*/}


                                        {(!this.state.SubDomain.isAISDB && !this.state.SubDomain.isBDSecurePF && !this.state.SubDomain.isFinanceProPF) &&
                                            <>
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-4">

                                                <div className="form-floating input-wrapper-payment mb-3">
                                                    <CurrencyFormat
                                                        thousandSeparator={true}
                                                        decimalSeparator="."
                                                        prefix={'$'}
                                                        placeholder="Down Payment*"
                                                        value={this.state.QuoteFlag ? "" : this.state.DownPayment ?? ''}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        className={(this.state.Errors['IsEmpty_DownPayment'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                        name="DownPayment"
                                                        id="DownPayment"
                                                        onValueChange={(values) => {
                                                            this.setState({ DownPayment: values.value ?? 0 });
                                                        }}
                                                        required
                                                        disabled={this.state.QuoteFlag}
                                                    />
                                                    {this.state.Errors['IsEmpty_DownPayment'] === true ? <div className="has-err-msg">{this.state.Errors['DownPaymentEmptyMsg']}</div> : ''}
                                                    <label htmlFor="DownPayment">Down Payment</label>
                                                </div>
                                            </div>
                                            {/*{this.state.isPremiumfinance &&*/}
                                            {/*    <>*/}
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-4">

                                                <div className="form-floating input-wrapper-payment mb-3">
                                                    <CurrencyFormat
                                                        thousandSeparator={true}
                                                        decimalSeparator="."
                                                        prefix={'$'}
                                                        placeholder="monthly Amount*"
                                                        value={this.state.QuoteFlag ? "" : this.state.MonthlyAmount ?? ''}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        className={(this.state.Errors['IsEmpty_MonthlyPayment'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                        name="MonthlyAmount"
                                                        id="MonthlyAmount"
                                                        onValueChange={(values) => {
                                                            this.setState({ MonthlyAmount: values.value ?? 0 });
                                                        }}
                                                        required
                                                        disabled={this.state.QuoteFlag || this.state.modifyLink}
                                                    />
                                                    {this.state.Errors['IsEmpty_MonthlyPayment'] === true ? <div className="has-err-msg">{this.state.Errors['MonthlyPaymentEmptyMsg']}</div> : ''}
                                                    <label htmlFor="MonthlyAmount">Monthly Amount</label>
                                                </div>
                                            </div>
                                            {/*    </>*/}
                                            {/*}*/}
                                            </>
                                        }
                                        {/*<div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*    <div className="form-floating input-wrapper-payment mb-3">*/}
                                        {/*        <input type="text"*/}
                                        {/*            className={(this.state.Errors['IsEmpty_TermLength'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}*/}
                                        {/*            ref="TermLength"*/}
                                        {/*            id="TermLength"*/}
                                        {/*            value={this.state.QuoteFlag ? "" : this.state.TermLength}*/}
                                        {/*            placeholder="# of Payments"*/}
                                        {/*            required*/}
                                        {/*            disabled={this.state.QuoteFlag || this.state.modifyLink} />*/}
                                        {/*        {this.state.Errors['IsEmpty_TermLength'] === true ? <div className="has-err-msg">{this.state.Errors['TermLengthEmptyMsg']}</div> : ''}*/}
                                        {/*        <label htmlFor="TermLength"># of Payments</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*{this.state.isPremiumfinance &&*/}
                                        {/*    <>*/}
                                        {/*        <div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*            <div className="form-floating input-wrapper-payment mb-3">*/}
                                        {/*                <CurrencyFormat*/}
                                        {/*                    thousandSeparator={true}*/}
                                        {/*                    decimalSeparator="."*/}
                                        {/*                    prefix={'$'}*/}
                                        {/*                    placeholder="APR"*/}
                                        {/*                    value={this.state.QuoteFlag ? "" : this.state.APR ?? ''}*/}
                                        {/*                    decimalScale={2}*/}
                                        {/*                    fixedDecimalScale={true}*/}
                                        {/*                    className={(this.state.Errors['IsEmpty_APR'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}*/}
                                        {/*                    name="APR"*/}
                                        {/*                    id="APR"*/}
                                        {/*                    onValueChange={(values) => {*/}
                                        {/*                        this.setState({ APR: values.value ?? 0 });*/}
                                        {/*                    }}*/}
                                        {/*                    required*/}
                                        {/*                    disabled={this.state.QuoteFlag || this.state.modifyLink}*/}
                                        {/*                />*/}
                                        {/*                {this.state.Errors['IsEmpty_APR'] === true ? <div className="has-err-msg">{this.state.Errors['APREmptyMsg']}</div> : ''}*/}
                                        {/*                <label htmlFor="APR">APR</label>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}

                                        {/*        <div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}

                                        {/*            <div className="form-floating input-wrapper-payment mb-3">*/}
                                        {/*                <CurrencyFormat*/}
                                        {/*                    thousandSeparator={true}*/}
                                        {/*                    decimalSeparator="."*/}
                                        {/*                    prefix={'$'}*/}
                                        {/*                    placeholder="Finance Charge"*/}
                                        {/*                    value={this.state.QuoteFlag ? "" : this.state.financeCharge ?? ''}*/}
                                        {/*                    decimalScale={2}*/}
                                        {/*                    fixedDecimalScale={true}*/}
                                        {/*                    className={(this.state.Errors['IsEmpty_FinanceCharge'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}*/}
                                        {/*                    name="FinanceCharge"*/}
                                        {/*                    id="FinanceCharge"*/}
                                        {/*                    onValueChange={(values) => {*/}
                                        {/*                        this.setState({ financeCharge: values.value ?? 0 });*/}
                                        {/*                    }}*/}
                                        {/*                    required*/}
                                        {/*                    disabled={this.state.QuoteFlag || this.state.modifyLink}*/}
                                        {/*                />*/}
                                        {/*                {this.state.Errors['IsEmpty_FinanceCharge'] === true ? <div className="has-err-msg">{this.state.Errors['FinanceChargeEmptyMsg']}</div> : ''}*/}
                                        {/*                <label htmlFor="FinanceCharge">Finance Charge</label>*/}
                                        {/*            </div>*/}

                                        {/*        </div>*/}
                                        {/*    </>*/}
                                        {/*}*/}
                                        {/*<div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}

                                        {/*    <div className="form-floating input-wrapper-payment mb-3">*/}
                                        {/*        <CurrencyFormat*/}
                                        {/*            thousandSeparator={true}*/}
                                        {/*            decimalSeparator="."*/}
                                        {/*            prefix={'$'}*/}
                                        {/*            placeholder="Service Fee"*/}
                                        {/*            value={this.state.ServiceFee ?? ''}*/}
                                        {/*            decimalScale={2}*/}
                                        {/*            fixedDecimalScale={true}*/}
                                        {/*            className={(this.state.Errors['IsEmpty_ServiceFee'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}*/}
                                        {/*            name="ServiceFee"*/}
                                        {/*            id="ServiceFee"*/}
                                        {/*            onValueChange={(values) => {*/}
                                        {/*                this.setState({ ServiceFee: values.value ?? 0 });*/}
                                        {/*            }}*/}
                                        {/*            disabled={this.state.modifyLink}*/}
                                        {/*            required*/}
                                        {/*        />*/}
                                        {/*        {this.state.Errors['IsEmpty_ServiceFee'] === true ? <div className="has-err-msg">{this.state.Errors['ServiceFeeEmptyMsg']}</div> : ''}*/}
                                        {/*        <label htmlFor="ServiceFee">Service Fee</label>*/}
                                        {/*    </div>*/}

                                        {/*</div>*/}
                                        {/*<div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*    <div className="form-floating input-wrapper-payment mb-3">*/}
                                        {/*        <input type="text" className={(this.state.Errors['IsEmpty_InvoiceNumber'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="InvoiceNumber" id="InvoiceNumber" defaultValue={this.state.InvoiceNumber} placeholder="Invoice number" required />*/}
                                        {/*        {this.state.Errors['IsEmpty_InvoiceNumber'] === true ? <div className="has-err-msg">{this.state.Errors['InvoiceNumberEmptyMsg']}</div> : ''}*/}
                                        {/*        <label htmlFor="InvoiceNumber">Invoice Number</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<>*/}
                                        {/*    <div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*        <div className="input-wrapper mx-auto mb-3">*/}
                                        {/*            <Select*/}
                                        {/*                value={this.state.modifyLink ? this.state.AllCompanyData.find(company => company.value === this.state.AISCompanyCode) : this.state.selectedComapany}*/}
                                        {/*                defaultValue={0}*/}
                                        {/*                onChange={this.handleChange}*/}
                                        {/*                options={this.state.AllCompanyData}*/}
                                        {/*                false*/}
                                        {/*                placeholder={'Company Name'}*/}
                                        {/*                closeMenuOnSelect={true}*/}
                                        {/*                true*/}
                                        {/*                className={'input-select-focus' + (this.state.Errors['IsEmpty_CompanyName'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}*/}
                                        {/*                isDisabled={this.state.modifyLink}*/}
                                        {/*            />*/}
                                        {/*            {this.state.Errors['IsEmpty_CompanyName'] === true ? <div className="has-err-msg">{this.state.Errors['CompanyNameEmptyMsg']}</div> : ''}*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</>*/}
                                        {/*<div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*    <div className="form-floating input-wrapper-payment react-date-picker-box mb-3">*/}
                                        {/*        <DatePicker*/}
                                        {/*            selected={this.state.LinkExpiryDate ?? ''}*/}
                                        {/*            onChange={(date2) => this.setState({ LinkExpiryDate: date2 })}*/}
                                        {/*            dateFormat="MMM dd, yyyy"*/}
                                        {/*            locale="en-US"*/}
                                        {/*            ref="LinkExpiryDate"*/}
                                        {/*            placeholderText="Link Expiration Date*"*/}
                                        {/*            id="LinkExpiryDate"*/}
                                        {/*            className={'w-100 form-control' + (this.state.Errors['IsEmpty_LinkExpiryDate'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}*/}
                                        {/*        />*/}
                                        {/*        {this.state.Errors['IsEmpty_LinkExpiryDate'] === true ? <div className="has-err-msg">{this.state.Errors['LinkExpiryDateEmptyMsg']}</div> : ''}*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                       
                                        {(this.state.SubDomain.SubDomainId != 3) &&
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                            <div className="input-wrapper mx-auto mb-3">
                                                
                                                <div className="ml-3">
                                                    <div
                                                        className="btn btn-sm px-5 py-3"
                                                        onClick={this._addPolicy}
                                                        disabled={this.state.policyTaxesAndFees.length >= 4}
                                                    >
                                                        Add Taxes and Fees +
                                                    </div>
                                                    </div>
                                            </div>
                                            </div>
                                        }

                                        {(this.state.SubDomain.SubDomainId == 3) && this.state.StaticTaxesAndFees.map((fee, index) => (
                                            <div className="row mb-3" key={index}>
                                                    <div className="col-md-2">
                                                        <input
                                                            type="text"
                                                            name="Type"
                                                            className={(this.state.Errors['IsEmpty_Type'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                            placeholder="Type"
                                                            defaultValue={fee.Type}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <input
                                                            type="text"
                                                            name="Description"
                                                            className={(this.state.Errors['IsEmpty_Description'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                            placeholder="Description"
                                                            defaultValue={fee.Description}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <CurrencyFormat
                                                            thousandSeparator={true}
                                                            decimalSeparator="."
                                                            prefix={'$'}
                                                            name="Amount"
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            className={(this.state.Errors['IsEmpty_Amount'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                            placeholder="Amount"
                                                            value={fee.Amount}
                                                            onChange={(e) => this._handleInputChange1(e, index)}
                                                        />
                                                        {this.state.Errors['IsEmpty_Amount'] === true ? <div className="has-err-msg">{this.state.Errors['AmountEmptyMsg']}</div> : ''}
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        {(this.state.SubDomain.SubDomainId != 3) && this.state.policyTaxesAndFees.map((fee, index) => (                                         
                                            <div className="row mb-3" key={index}>

                                                <div className="col-md-2">
                                                    {this.state.SubDomain.SubDomainId == 2 ? (
                                                        <select
                                                            name="Type"
                                                            className={(this.state.Errors['IsEmpty_Type'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                            value={fee.Type}
                                                            onChange={(e) => this._handleInputChange(e, index)}
                                                        >
                                                            <option value="" disabled selected hidden>Type</option>
                                                            <option value="T">T</option>
                                                            <option value="F">F</option>
                                                        </select>
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            name="Type"
                                                            className={(this.state.Errors['IsEmpty_Type'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                            placeholder="Type"
                                                            value={fee.Type}
                                                            onChange={(e) => this._handleInputChange(e, index)}
                                                        />
                                                    )}
                                                    {this.state.Errors['IsEmpty_Type'] === true ? <div className="has-err-msg">{this.state.Errors['TypeEmptyMsg']}</div> : ''}
                                            </div>
                                            <div className="col-md-2">
                                                <input
                                                    type="text"
                                                    name="Description"
                                                    className={(this.state.Errors['IsEmpty_Description'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                    placeholder="Description"
                                                    value={fee.Description}
                                                    onChange={(e) => this._handleInputChange(e, index)}
                                                />
                                            {this.state.Errors['IsEmpty_Description'] === true ? <div className="has-err-msg">{this.state.Errors['DescriptionEmptyMsg']}</div> : ''}
                                            </div>
                                            <div className="col-md-2">
                                                <CurrencyFormat
                                                    thousandSeparator={true}
                                                    decimalSeparator="."
                                                    prefix={'$'}
                                                    name="Amount"
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className={(this.state.Errors['IsEmpty_Amount'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                    placeholder="Amount"
                                                    value={fee.Amount}
                                                        onChange={(e) => this._handleInputChange(e, index)}
                                                 />
                                            {this.state.Errors['IsEmpty_Amount'] === true ? <div className="has-err-msg">{this.state.Errors['AmountEmptyMsg']}</div> : ''}
                                            </div>
                                            <div className="col-md-2">
                                                <input
                                                    type="text"
                                                    name="Line_Code"
                                                    className={(this.state.Errors['IsEmpty_Line_Code'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                    placeholder="Line Code"
                                                    value={fee.Line_Code}
                                                    onChange={(e) => this._handleInputChange(e, index)}
                                                />
                                                    {this.state.Errors['IsEmpty_Line_Code'] === true ? <div className="has-err-msg">{this.state.Errors['Line_CodeEmptyMsg']}</div> : ''}
                                            </div>
                                            <div className="col-md-1">
                                                <Button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => this._removePolicy(index)}
                                                >
                                                    -
                                                </Button>
                                            </div>
                                                </div>
                                    ))}
                                        {(this.state.SubDomain.SubDomainId == 2 ) &&
                                            <>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="text" className={(this.state.Errors['IsEmpty_ProcId'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="ProcId" id="ProcId" aria-describedby="ProcId" defaultValue={this.state.ProcId} placeholder="Proc Id" required />
                                                        {this.state.Errors['IsEmpty_ProcId'] === true ? <div className="has-err-msg">{this.state.Errors['ProcIdEmptyMsg']}</div> : ''}
                                                        <label htmlFor="ProcId">Proc Id</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="text" className={(this.state.Errors['IsEmpty_ContractId'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="ContractId" id="ContractId" aria-describedby="ContractId" defaultValue={this.state.ContractId} placeholder="Contract Id" />
                                                        <label htmlFor="ContractId">Contract Id</label>
                                                        {this.state.Errors['IsEmpty_ContractId'] === true ? <div className="has-err-msg">{this.state.Errors['ContractIdEmptyMsg']}</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="text" className={(this.state.Errors['IsEmpty_BookEntityId'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="BookEntityId" id="BookEntityId" defaultValue={this.state.BookEntityId} placeholder="Book Entity Id" />
                                                        <label htmlFor="BookEntityId">Book Entity Id</label>
                                                        {this.state.Errors['IsEmpty_BookEntityId'] === true ? <div className="has-err-msg">{this.state.Errors['BookEntityIdEmptyMsg']}</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="text" className={(this.state.Errors['IsEmpty_BookLocId'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="BookLocId" id="BookLocId" aria-describedby="BookLocId" defaultValue={this.state.BookLocId} placeholder="Book Loc Id" required />
                                                        <label htmlFor="BookLocId">Book Loc Id</label>
                                                        {this.state.Errors['IsEmpty_BookLocId'] === true ? <div className="has-err-msg">{this.state.Errors['BookLocIdEmptyMsg']}</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="text" className={(this.state.Errors['IsEmpty_LineCode'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="LineCode" id="LineCode" aria-describedby="LineCode" defaultValue={this.state.LineCode} placeholder="Line Code" />
                                                        <label htmlFor="LineCode">Line Code</label>
                                                        {this.state.Errors['IsEmpty_LineCode'] === true ? <div className="has-err-msg">{this.state.Errors['LineCodeEmptyMsg']}</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="text" className={(this.state.Errors['IsEmpty_BillToRoleType'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="BillToRoleType" id="BillToRoleType" aria-describedby="BillToRoleType" defaultValue={this.state.BillToRoleType} placeholder="Bill To Role Type" />
                                                        <label htmlFor="BillToRoleType">Bill To Role Type</label>
                                                        {this.state.Errors['IsEmpty_BillToRoleType'] === true ? <div className="has-err-msg">{this.state.Errors['BillToRoleTypeEmptyMsg']}</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="text" className={(this.state.Errors['IsEmpty_BillToEntity'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="BillToEntity" id="BillToEntity" aria-describedby="BillToEntity" defaultValue={this.state.BillToEntity} placeholder="Bill To Entity" />
                                                        <label htmlFor="BillToEntity">Bill To Entity</label>
                                                        {this.state.Errors['IsEmpty_BillToEntity'] === true ? <div className="has-err-msg">{this.state.Errors['BillToEntityEmptyMsg']}</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="text" className={(this.state.Errors['IsEmpty_ReceivableRole'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="ReceivableRole" id="ReceivableRole" aria-describedby="ReceivableRole" defaultValue={this.state.ReceivableRole} placeholder="Receivable Role" required />
                                                        <label htmlFor="ReceivableRole">Receivable Role</label>
                                                        {this.state.Errors['IsEmpty_ReceivableRole'] === true ? <div className="has-err-msg">{this.state.Errors['ReceivableRoleEmptyMsg']}</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="text" className={(this.state.Errors['IsEmpty_ContractLineId'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="ContractLineId" id="ContractLineId" aria-describedby="ContractLineId" defaultValue={this.state.ContractLineId} placeholder="Contract Line Id" />
                                                        <label htmlFor="ContractLineId">Contract Line Id</label>
                                                        {this.state.Errors['IsEmpty_ContractLineId'] === true ? <div className="has-err-msg">{this.state.Errors['ContractLineIdEmptyMsg']}</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="text" className={(this.state.Errors['IsEmpty_ContractNum'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="ContractNum" id="ContractNum" aria-describedby="ContractNum" defaultValue={this.state.ContractNum} placeholder="Contract Num" />
                                                        <label htmlFor="ContractNum">Contract Num</label>
                                                        {this.state.Errors['IsEmpty_ContractNum'] === true ? <div className="has-err-msg">{this.state.Errors['ContractNumEmptyMsg']}</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="text"
                                                            className={(this.state.Errors['IsEmpty_Term'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                            ref="TermLength"
                                                            id="TermLength"
                                                            value={this.state.QuoteFlag ? "" : this.state.TermLength}
                                                            placeholder="Term"
                                                            required />
                                                        {this.state.Errors['IsEmpty_Term'] === true ? <div className="has-err-msg">{this.state.Errors['TermEmptyMsg']}</div> : ''}
                                                        <label htmlFor="Term">Term</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                    <input type="number" className={(this.state.Errors['IsEmpty_AgencyID'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="AgencyID" id="AgencyID" aria-describedby="AgencyID" defaultValue={this.state.AgencyID} placeholder="Agency ID" />
                                                    <label htmlFor="AgencyID">Agency ID</label>
                                                    {/*{this.state.Errors['IsEmpty_AgencyID'] === true ? <div className="has-err-msg">{this.state.Errors['AgencyIDEmptyMsg']}</div> : ''}*/}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                    <input type="text" className={(this.state.Errors['IsEmpty_AgencyName'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="AgencyName" id="AgencyName" aria-describedby="AgencyName" defaultValue={this.state.AgencyName} placeholder="AgencyName" />
                                                    <label htmlFor="AgencyName">Agency Name</label>
                                                    {/*{this.state.Errors['IsEmpty_AgencyName'] === true ? <div className="has-err-msg">{this.state.Errors['AgencyNameEmptyMsg']}</div> : ''}*/}
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                    <input type="text" className={(this.state.Errors['IsEmpty_AgencyPhoneNumber'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="AgencyPhoneNumber" id="AgencyPhoneNumber" name="AgencyPhoneNumber" defaultValue={this.state.AgencyPhoneNumber} placeholder="Agency Phone Number" maxLength="14" />
                                                        {/*{this.state.Errors['IsEmpty_AgencyPhoneNumber'] === true ? <div className="has-err-msg">{this.state.Errors['AgencyPhoneNumberEmptyMsg']}</div> : ''}*/}
                                                    <label htmlFor="AgencyPhoneNumber">Agency Phone Number</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                    <input type="number" className={(this.state.Errors['IsEmpty_AgencyAffiliateID'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="AgencyAffiliateID" id="AgencyAffiliateID" aria-describedby="AgencyAffiliateID" defaultValue={this.state.AgencyAffiliateID} placeholder="Agency Affiliate ID" />
                                                    <label htmlFor="AgencyAffiliateID">Agency Affiliate ID</label>
                                                    {/*{this.state.Errors['IsEmpty_AgencyAffiliateID'] === true ? <div className="has-err-msg">{this.state.Errors['AgencyAffiliateIDEmptyMsg']}</div> : ''}*/}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                    <input type="text" className={(this.state.Errors['IsEmpty_AgencyAffiliateName'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="AgencyAffiliateName" id="AgencyAffiliateName" aria-describedby="AgencyAffiliateName" defaultValue={this.state.AgencyAffiliateName} placeholder="Agency Affiliate Name" />
                                                    <label htmlFor="AgencyAffiliateName">Agency Affiliate Name</label>
                                                    {/*{this.state.Errors['IsEmpty_AgencyAffiliateName'] === true ? <div className="has-err-msg">{this.state.Errors['AgencyAffiliateNameEmptyMsg']}</div> : ''}*/}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                    <input type="number" className={(this.state.Errors['IsEmpty_CarrierID'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="CarrierID" id="CarrierID" aria-describedby="CarrierID" defaultValue={this.state.CarrierID} placeholder="Carrier ID" />
                                                    <label htmlFor="CarrierID">Carrier ID</label>
                                                    {/*{this.state.Errors['IsEmpty_CarrierID'] === true ? <div className="has-err-msg">{this.state.Errors['CarrierIDEmptyMsg']}</div> : ''}*/}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                    <input type="text" className={(this.state.Errors['IsEmpty_LineDescription'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="LineDescription" id="LineDescription" aria-describedby="LineDescription" defaultValue={this.state.LineDescription} placeholder="Line Description" />
                                                    <label htmlFor="LineDescription">Line Description</label>
                                                    {/*{this.state.Errors['IsEmpty_LineDescription'] === true ? <div className="has-err-msg">{this.state.Errors['LineDescriptionEmptyMsg']}</div> : ''}*/}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                    <input type="text" className={(this.state.Errors['IsEmpty_QuoteId'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="QuoteId" id="QuoteId" aria-describedby="QuoteId" defaultValue={this.state.QuoteId} placeholder="Quote Id" />
                                                    <label htmlFor="QuoteId">Quote Id</label>
                                                    {/*{this.state.Errors['IsEmpty_QuoteId'] === true ? <div className="has-err-msg">{this.state.Errors['QuoteIdEmptyMsg']}</div> : ''}*/}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                    <input type="text" className={(this.state.Errors['IsEmpty_EntityId'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="EntityId" id="EntityId" aria-describedby="EntityId" defaultValue={this.state.EntityId} placeholder="Entity Id" />
                                                    <label htmlFor="EntityId">Entity Id</label>
                                                    {/*{this.state.Errors['IsEmpty_EntityId'] === true ? <div className="has-err-msg">{this.state.Errors['EntityIdEmptyMsg']}</div> : ''}*/}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                    <input type="text" className={(this.state.Errors['IsEmpty_ContractsetId'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="ContractsetId" id="ContractsetId" aria-describedby="ContractsetId" defaultValue={this.state.ContractSetId} placeholder="Contractset Id" />
                                                    <label htmlFor="ContractsetId">Contractset Id</label>
                                                    {/*{this.state.Errors['IsEmpty_ContractsetId'] === true ? <div className="has-err-msg">{this.state.Errors['ContractsetIdEmptyMsg']}</div> : ''}*/}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4    ">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                    <input type="text" className={(this.state.Errors['IsEmpty_PrinBrokEntityId'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="PrinBrokEntityId" id="PrinBrokEntityId" aria-describedby="PrinBrokEntityId" defaultValue={this.state.PrinBrokEntityId} placeholder="Prin Brok Entity Id" />
                                                    <label htmlFor="PrinBrokEntityId">Prin Brok Entity Id</label>
                                                    {/*{this.state.Errors['IsEmpty_PrinBrokEntityId'] === true ? <div className="has-err-msg">{this.state.Errors['PrinBrokEntityIdEmptyMsg']}</div> : ''}*/}
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {(['3', '5', '6'].includes(this.state.SubDomain.SubDomainId)) &&
                                            <>
                                                <div className= "modal-header pt-4 border-0">
                                                <h5 className="modal-title text-left">{['3', '5', '6'].includes(this.state.SubDomain.SubDomainId) ? "Policy Information" : ""}</h5>
                                                </div>
                                           
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                    <input type="text" className={(this.state.Errors['IsEmpty_PolicyNumber'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="PolicyNumber" id="policyNumber" name="policyNumber" defaultValue={this.state.PolicyNumber} placeholder="Policy Number*" required />
                                                        {/*{this.state.Errors['IsEmpty_PolicyNumber'] === true ? <div className="has-err-msg">{this.state.Errors['PolicyNumberEmptyMsg']}</div> : ''}*/}
                                                        <label htmlFor="policyNumber">Policy Number</label>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                <div className="form-floating input-wrapper-payment mb-3">
                                                    {this.state.SubDomain.SubDomainId != 3 &&
                                                        <>
                                                            <input type="text" className="input-newDesign form-control" id="generalagent" aria-describedby="generalagent" ref="GeneralAgent" id="generalAgent" name="generalAgent" defaultValue={this.state.GeneralAgent} placeholder="General Agent" />
                                                            <label htmlFor="generalagent">General Agent</label>
                                                        </>
                                                    }
                                                    {this.state.SubDomain.SubDomainId == 3 &&
                                                        <>
                                                            <input type="text" className="input-newDesign form-control" id="generalagent" aria-describedby="generalagent" ref="GeneralAgent" id="generalAgent" name="generalAgent" defaultValue={this.state.GeneralAgent} placeholder="Geico Agent" />
                                                            <label htmlFor="generalagent">Geico Agent</label>
                                                        </>
                                                    }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                <div className="input-wrapper-payment mb-3 text-left">
                                                        {/*<input type="text" className="form-control" id="gcode" aria-describedby="gcode" defaultValue="G110951" disabled placeholder="G Code" readOnly />*/}
                                                        {/*<label htmlFor="gcode">G Code</label>*/}
                                                        <Select
                                                            value={this.state.selectedGCode}
                                                            onChange={this.handleGCode}
                                                            options={this.state.AllGCodesData}
                                                            false
                                                            placeholder={'G Code'}
                                                            closeMenuOnSelect={true}
                                                            true
                                                            className={'input-select-focus input-newDesign form-control'}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                <div className="form-floating input-wrapper mb-3">
                                                    <DatePicker
                                                        selected={this.state.policyExpDateFormat ?? ''}
                                                        dateFormat="MMM dd, yyyy"
                                                        locale="en-US"
                                                        ref="expirationdate"
                                                        id="expirationdate"
                                                        placeholderText="Expiration Date"
                                                        className={(this.state.Errors['IsEmpty_Expirationdate'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                        disabled={true }
                                                    />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                <div className="input-wrapper-payment mb-3 text-left">
                                                        <Select
                                                            value={this.state.selectedComapany}
                                                            onChange={this.handleChange}
                                                            options={this.state.AllCompanyData}
                                                            false
                                                            placeholder={'Insurance Company'}
                                                            closeMenuOnSelect={true}
                                                            true
                                                            className={(this.state.Errors['IsEmpty_InsuranceCompany'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                        />
                                                        {this.state.Errors['IsEmpty_InsuranceCompany'] === true ? <div className="has-err-msg">{this.state.Errors['InsuranceCompanyEmptyMsg']}</div> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                <div className="input-wrapper-payment mb-3 text-left">
                                                    <Select
                                                            value={this.state.selectedCoverageType}
                                                            onChange={this.coveragetypehandlechange}
                                                            options={this.state.CoverageTypeList}
                                                            placeholder={'Coverage Type'}
                                                            closeMenuOnSelect={true}
                                                            className={(this.state.Errors['IsEmpty_CoverageType'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                                        />
                                                        {this.state.Errors['IsEmpty_CoverageType'] === true ? <div className="has-err-msg">{this.state.Errors['CoverageTypeEmptyMsg']}</div> : ''}
                                                    </div>
                                                </div>

                                            </>
                                        }

                                        {/*<div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*    <div className="input-wrapper mx-auto mb-3">*/}
                                        {/*        <select*/}
                                        {/*            value={this.state.modifylink ? this.state.statelist.find(state => state.value === this.state.state) : this.state.selectedstate === null ? this.state.editstatevalue : this.state.selectedstate}*/}
                                        {/*            onchange={this.statehandlechange}*/}
                                        {/*            options={this.state.statelist}*/}
                                        {/*            false*/}
                                        {/*            placeholder={'state'}*/}
                                        {/*            closemenuonselect={true}*/}
                                        {/*            true*/}
                                        {/*            id={'state'}*/}
                                        {/*            ref={'state'}*/}
                                        {/*            classname={'input-select-focus' + (this.state.errors['isempty_state'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}*/}
                                        {/*            isdisabled={this.state.modifylink}*/}
                                        {/*        />*/}
                                        {/*        {this.state.Errors['IsEmpty_State'] === true ? <div className="has-err-msg">{this.state.Errors['StateEmptyMsg']}</div> : ''}*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*}*/}
                                        {/*<div className="col-12 col-sm-6 col-md-6 col-lg-4">*/}
                                        {/*    <div className="input-wrapper mx-auto mb-3">*/}
                                        {/*        */}{/*<select className="select-wrapper" value={this.state.Note} onChange={this.handleChangeTest}>*/}
                                        {/*        */}{/*    <option value="" style={{ color: '#00000070' }}>Note</option>*/}
                                        {/*        */}{/*    <option value="Billed to Carrier">Billed to Carrier</option>*/}
                                        {/*        */}{/*</select>*/}
                                        {/*        <select value={this.state.Note} onChange={this.handleChangeTest} disabled={this.state.modifyLink} className="form-select"*/}
                                        {/*            style={{*/}
                                        {/*                height: '53px',*/}
                                        {/*                fontSize: '15px',*/}
                                        {/*                color: this.state.Note === "Direct Bill from Carrier" ? "hsl(0, 0%, 20%)" : "darkgray"*/}
                                        {/*            }}*/}
                                        {/*        >*/}
                                        {/*            <option value=""  >Select Notes</option>*/}
                                        {/*            <option value="Direct Bill from Carrier">Direct Bill from Carrier</option>*/}
                                        {/*        </select>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                    {/*    <textarea className="form-control" rows="3" ref="Note" placeholder="Note" id="Note" autoComplete="nope" autoCorrect="off" spellCheck="off" name="Note"></textarea>*/}
                                    {/*    <label htmlFor="Note">Note</label>*/}
                                    {/*</div>*/}
                                    <div className="text-center d-flex justify-content-center">
                                        <button type="button" id="btncloseMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2 btn-large" onClick={() => this.clearFormOnClose()}>Close</button>
                                        <button type="button" className={`btn btn-newDesign btn-header btn-large`} onClick={() => this._SavePaymentLink()}>{this.state.modifyLink ? "Modify" : "Generate"}</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                { /*model for confirm send mail payment links */}

                <div className="modal fade" id="confirmsendmail" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">Email Payment Link</h5>
                                {/*    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                            </div>
                            <div className="modal-body">
                                Do you want to email this payment link ? {this.state.SendLoader && <i className="fa fa-refresh fa-spin fa-1x"></i>}
                            </div>
                            <div className="button-container">
                                <button type="button" id="btncanclemail" className={`btn btn-newDesign-reverse `} data-bs-dismiss="modal">Cancel</button>
                                <button type="button" id="btnsendmail" className={`btn btn-newDesign `} onClick={() => this._SendPaymentLink(this.state.PaymentId)}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
                { /*model for confirm send text payment links */}
                <div className="modal fade" id="confirmsendtext" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">Text Payment Link</h5>
                                {/*    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                            </div>
                            <div className="modal-body">
                                Do you want to text this payment link ? {this.state.SendLoader && <i className="fa fa-refresh fa-spin fa-1x"></i>}
                            </div>
                            <div className="button-container">
                                <button type="button" id="btncancletext" className={`btn btn-newDesign-reverse `} data-bs-dismiss="modal">Cancel</button>
                                <button type="button" id="btnsendtext" className={`btn btn-newDesign `} onClick={() => this._SendTextPaymentLink(this.state.PaymentId)}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PaymentLinkSummary;