import React, { Component } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { AddEditTag, GetAllTag, GetTagNameById, DeleteTagById, GetAllTemplates, GetTemplateNameById, GetAllCompleteTag, SaveTemplate } from '../../../services/TemplatesServices';
import ReactPaginate from 'react-paginate';
import { LoaderContents } from "../../layout/LoaderContents";
import validator from 'validator';
import { showError, showSuccess, subDomain, Button } from '../../../common/Common';
import Parser from 'html-react-parser';

class ManageEmailTextTemplates extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            Errors: {},
            AllTagData: [],
            AllCompleteTagData: [],
            AllTemplatesData: [],
            TemplatedataLoaderStatus: false,
            TagdataLoaderStatus: false,
           
            TagMdl: {
                id: 0,
                tagName: null,
                tagValue: null,
                systemGenerated: null

            },
            TemplateMdl: {
                id: null,
                templatename: null,
                templatevalue: null
            },
            searchMdl: {
                Id: null,
                GeneralSearch: null,
                LimitTo: 10,
                PageNumber: 1,
                SelectedDate: null,
                ToDate: null,
                 SaveLoaderStatus: false,
                Is_EmailMessageTemplete: true,
                Is_TextMessageTemplete: false
            },
            SubDomain: subDomain(),
            TemplatePageLoaderStatus: false,
            TagPageLoaderStatus: false,
        }
    }

    componentDidMount() {
        this.setState({ TemplatePageLoaderStatus: true }); // show loader befour load data
        this.setState({ TagPageLoaderStatus: true }); // show loader befour load data
        this._GetAllTag(this.state.searchMdl, this.state.SubDomain.SubDomainId);
        this._GetAllTemplates(this.state.searchMdl, this.state.SubDomain.SubDomainId);
        this._GetAllCompleteTag(this.state.SubDomain.SubDomainId);
    };

    _AllTestMessageTemplatesData() {
        this.setState(Object.assign(this.state.searchMdl, { Is_EmailMessageTemplete: false, Is_TextMessageTemplete: true }));
        this._GetAllTemplates(this.state.searchMdl, this.state.SubDomain.SubDomainId);
        let textButton = document.getElementById("nav-profile-tab");
        textButton.classList.remove("btn-newDesign-foil");
        textButton.classList.add("btn-newDesign");
        let emailButton = document.getElementById("nav-home-tab");
        emailButton.classList.add("btn-newDesign-foil");
        emailButton.classList.remove("btn-newDesign");
        let hideTab = document.getElementById("nav-home");
        hideTab.classList.remove('active', 'show');
        let showTab = document.getElementById("nav-profile");
        showTab.classList.add('active', 'show');
    }
    _AllEmailMessageTemplatesData() {
        this.setState(Object.assign(this.state.searchMdl, { Is_EmailMessageTemplete: true, Is_TextMessageTemplete: false }));
        this._GetAllTemplates(this.state.searchMdl, this.state.SubDomain.SubDomainId);
        let textButton = document.getElementById("nav-profile-tab");
        textButton.classList.add("btn-newDesign-foil");
        textButton.classList.remove("btn-newDesign");
        let emailButton = document.getElementById("nav-home-tab");
        emailButton.classList.remove("btn-newDesign-foil");
        emailButton.classList.add("btn-newDesign");
        let hideTab = document.getElementById("nav-profile");
        hideTab.classList.remove('active', 'show');
        let showTab = document.getElementById("nav-home");
        showTab.classList.add('active', 'show');
    }
     //Tag submit on enter key down
    _AddEditTag_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this.AddNewTags();
        };
    };

    //form submit on enter key down
    _EditTemplate_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this.EditTemplate();
        };
    };

    AddNewTags = () => {
        this.setState({
            Errors: [],
            reponseMessage: '',
            ModelTitle: "Add New Tag",
            TagMdl: {
                id: 0,
                TagName: null,
                TagValue: null,
                SystemGenerated: null,
            }
        });
      
        $("#frmaddnewtag").trigger("reset");
        window.$('#modalAddNewTag').modal('show');
    }

    AddNewTemplates = () => {
        this.setState({
            Errors: [],
            reponseMessage: '',
            ModelTitle: "Add New Template",
            TemplateMdl: {
                id: 0,
                TemplateName: null,
                TemplateValue: null
            }
        });

        $("#frmaddnewtemplate").trigger("reset");
        window.$('#modalAddNewTemplate').modal('show');
    }

    AddEditTagValidation = (TagMdl) => {
        let errors = {};
        let count = 0;
        if (TagMdl.TagName === null || TagMdl.TagName === undefined || TagMdl.TagName.trim() === "") {
            errors['IsEmpty_TagName'] = true;
            errors['TagNameEmptyMsg'] = "Please fill the required fields : Tag Name";
            count++;
        }
        if (TagMdl.TagValue === null || TagMdl.TagValue === undefined || TagMdl.TagValue.trim() === "") {
            errors['IsEmpty_TagValue'] = true;
            errors['TagValueEmptyMsg'] = "Please fill the required fields : Tag Value";
            count++;
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }

    
    _AddEditTag = () => {

        let TagMdl = {
          
            Id: this.state.TagMdl.id,
            TagName: this.refs.TagName.value,
            TagValue: this.refs.TagValue.value,
            SystemGenerated: this.state.TagMdl.systemGenerated,
            SubDomainId: this.state.SubDomain.SubDomainId
        }
        let status = this.AddEditTagValidation(TagMdl);
        if (status) {
            this.setState({ SaveLoaderStatus: true }); //  loader before  data save
            AddEditTag(TagMdl).then(data => {
                let response = data;
                if (response == undefined) {
                    window.$('#modalAddNewTag').modal('hide');
                    for (name in CKEDITOR.instances) {
                        CKEDITOR.instances[name].destroy(true);
                    }
                    this.setState({ SaveLoaderStatus: false });
                }
                else {
                    if (response.message !== Error) {
                        if (response.status === true) {
                            window.$('#modalAddNewTag').modal('hide');
                            this.AddNewTags();
                            this._GetAllTag(this.state.searchMdl, this.state.SubDomain.SubDomainId);
                            this._GetAllCompleteTag(this.state.SubDomain.SubDomainId);
                            for (name in CKEDITOR.instances) {
                                CKEDITOR.instances[name].destroy(true);
                            }
                            showSuccess(response.message);
                        }
                        else {
                            showError(response.message);
                        }
                        this.setState({ SaveLoaderStatus: false }); // hide loader after  data save
                    }
                }
            });
        }
    }

    resetAddNewTags = () => {
            this.setState({
                Errors: [],
                reponseMessage: '',
                ModelTitle: "Reset Tag",
                TagMdl: {
                    id: 0,
                    tagName: null,
                    tagValue: null
                
            }
        });
    $("#frmaddnewtag").trigger("reset");
        window.$('#modalAddNewTag').modal('show');
    }
    
    _editTag = (id) => {
        this.resetAddNewTags();
        this.setState({ Errors: [] });
        this.setState({ SaveLoaderStatus: true }); // show loader before  data edit
        GetTagNameById(id).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ TagMdl: response.data });
                    this._GetAllCompleteTag(this.state.SubDomain.SubDomainId);
                    for (name in CKEDITOR.instances) {
                        CKEDITOR.instances[name].destroy(true);
                    }
                    window.$('#modalAddNewTag').modal('hide');
                }
                else {
                    
                    showError(response.message);
                    window.$('#modalAddNewTag').modal('hide');
                }
                this.setState({ SaveLoaderStatus: false }); // hide loader after  data edit
            }
        });
    }

    resetNewTemplates = () => {
        this.setState({
            Errors: [],
            reponseMessage: '',
            ModelTitle: "Edit Template",
            TemplateMdl: {
                id: null,
                templateName: null,
                templateValue: null

            }
        });
        $("#frmedittemplate").trigger("reset");
        window.$('#modalEditTemplate').modal('show');
    }

    _editTemplate = (id, AllCompleteTagData, SubDomainId) => {
        this.resetNewTemplates();
        this.setState({ Errors: [] });
        this.setState({ SaveLoaderStatus: true }); // show loader before  data edit
        this.setState({ AllCompleteTagData: AllCompleteTagData });
        GetTemplateNameById(id, SubDomainId).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ TemplateMdl: response.data });

                    CKEDITOR.replace('editor2', {
                        on: {
                            pluginsLoaded: function () {
                                var editor = this,
                                    config = editor.config;

                                editor.ui.addRichCombo('my-combo', {
                                    label: 'Tag List',
                                    title: 'Tag List',
                                    toolbar: 'basicstyles,0',
                                    language_list: ["ar:Arabic:rtl", "fr:French", "es:Spanish", "en:English"],
                                    panel: {
                                        css: "https://app-staging.xgenbilling.com/assets/Content/css/ckeditor.css",
                                        multiSelect: false,
                                        attributes: { 'aria-label': 'Tag List' }
                                    },

                                    init: function () {
                                        //this.startGroup('<p>Choose Tag');
                                        for (var i = 0; i < AllCompleteTagData.length; i++) {
                                            this.add(AllCompleteTagData[i]['tagName'], AllCompleteTagData[i]['tagName']);
                                        }
                                    },
                                    onClick: function (value) {
                                        editor.focus();
                                        editor.fire('saveSnapshot');
                                        editor.insertHtml(value);
                                        editor.fire('saveSnapshot');
                                    }
                                });
                            }
                        }
                    });

                    CKEDITOR.instances['editor2'].setData(this.state.TemplateMdl.templatevalue);
                    window.$('#modalEditTemplate').modal('show');
                }
                else {
                    showError(response.message);
                }
                this.setState({ SaveLoaderStatus: false }); // hide loader after  data edit
            }
        });
    }

    _deleteTag = (id) => {
        
        this.setState({ Errors: [] });
       
        DeleteTagById(id).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ TagMdl: response.data });
                    this._GetAllTag(this.state.searchMdl, this.state.SubDomain.SubDomainId);
                    this._GetAllCompleteTag(this.state.SubDomain.SubDomainId);
                    for (name in CKEDITOR.instances) {
                        CKEDITOR.instances[name].destroy(true);
                    }
                }
                else {
                    showError(response.message);
                }
                
            }
       });
    }

    handleTagPageClick = (data) => {
        let PageNumber = data.selected + 1;
        this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
        this._GetAllTag(this.state.searchMdl, this.state.SubDomain.SubDomainId);
    }

    handleTagSearchData = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
        this._GetAllTag(this.state.searchMdl, this.state.SubDomain.SubDomainId);
    }
    handleTagShowChange = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
        this._GetAllTag(this.state.searchMdl, this.state.SubDomain.SubDomainId);
    }

    handleTemplatePageClick = (data) => {
        let PageNumber = data.selected + 1;
        this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
        this._GetAllTemplates(this.state.searchMdl, this.state.SubDomain.SubDomainId);
    }

    handleTemplateSearchData = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
        this._GetAllTemplates(this.state.searchMdl, this.state.SubDomain.SubDomainId);
    }
    handleTemplateShowChange = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
        this._GetAllTemplates(this.state.searchMdl, this.state.SubDomain.SubDomainId);
    }

    _GetAllTag = (searchMdl, SubDomainId) => {
        this.setState({ TagdataLoaderStatus: true }); // show loader befour load data
        GetAllTag(searchMdl, SubDomainId).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AllTagData: response.data.data });
                    this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
                } else {
                    showError(response.message);
                }
                this.setState({ TagdataLoaderStatus: false }); // hide loader after load data
            }
        });
    }

    _GetAllCompleteTag = (SubDomainId) => {
        this.setState({ TagdataLoaderStatus: true }); // show loader befour load data
        GetAllCompleteTag(SubDomainId).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AllCompleteTagData: response.data.data });

                } else {
                    showError(response.message);
                }
                this.setState({ TagdataLoaderStatus: false }); // hide loader after load data
                this.setState({ TagPageLoaderStatus: false });
            }
        });
    }

    _GetAllTemplates = (searchMdl, SubDomainId) => {
        this.setState({ TemplatedataLoaderStatus: true }); // show loader befour load data
      
        GetAllTemplates(searchMdl, SubDomainId, searchMdl.Is_EmailMessageTemplete, searchMdl.Is_TextMessageTemplete).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AllTemplatesData: response.data.data });
                    this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });

                } else {
                    showError(response.message);
                }
                this.setState({ TemplatedataLoaderStatus: false }); // hide loader after load data
                this.setState({ TemplatePageLoaderStatus: false });
            }
        });
    }
    

    handleTextAreaChange = evt => {
        const value = evt.currentTarget.value;
        dispatch({ type: 'textareaData', payload: value });
    };

    handleFocus = () => {
        dispatch({ type: 'textareaFocus' });
    };

    _SaveTemplate = (SubDomainId) => {
        let TemplateMdl = {

            Id: this.state.TemplateMdl.id,
            TemplateValue: CKEDITOR.instances.editor2.getData(),
            SubDomainId: SubDomainId
        }

        this.setState({ SaveLoaderStatus: true }); //  loader before  data save
        SaveTemplate(TemplateMdl).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    window.$('#modalEditTemplate').modal('hide');
                    //this.AddNewTags();
                    this._GetAllTemplates(this.state.searchMdl, SubDomainId);
                    for (name in CKEDITOR.instances) {
                        CKEDITOR.instances[name].destroy(true);
                    }
                    showSuccess(response.message);
                }
                else {
                    showError(response.message);
                }
                this.setState({ SaveLoaderStatus: false }); // hide loader after  data save
            }
        });
    }

    render() {
        const { AllTagData, AllTemplatesData, AllCompleteTagData } = this.state;
      
        return (
            <>
                <div className="main-wrapper-newDesign w-100">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-xxl-6 col-lg-6 col-sm-5 col-12">
                                <h1 className="h3 mb-3 fw-normal"><strong>Manage Tags</strong></h1>
                            </div>
                            <div className="col-xl-6 col-xxl-6  col-lg-6 col-sm-7 col-12 text-end">
                                <button type="button" id="addNewTag" className="btn btn-newDesign btn-header" onClick={() => this.AddNewTags()}>
                                    <span className="label">Add New Tags</span>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <div className="card flex-fill mt-4 border w-100 dashboard-card-newDesign">
                                    <div className="card-body">
                                        <div className="dashboard-options">
                                            <div className="card-header bg-white border-white p-2 dashboard-input-size">
                                                <input type="text" className="form-control text-search-newDesign" onChange={this.handleTagSearchData} id="search" placeholder="Search by Description" />
                                                {this.state.SaveLoaderStatus &&
                                                    <span style={{ position: "absolute", right: "222px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                                }
                                            </div>
            
                                            <div className="card-header bg-white border-white p-2">
                                                <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleTagShowChange}>
                                                    <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-100 table-responsive">
                                            <table className="table insured-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Tag Name</th>
                                                    <th>Tag Value</th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {AllTagData.map((items, index) => (
                                                    <tr key={index}>
                                                        
                                                        <td>{items.tagName}</td>
                                                        <td>{items.tagValue}</td>
                                                        {items.systemGenerated == true ? <td><span className="red-text center-aligned"> System Generated </span></td> : <td><Button type="button" id={items.id} className="btn-next center-aligned copylinkbutton btn-min-width" onClick={() => this._editTag(items.id)}>Edit</Button>
                                                            <Button type="button" id={items.id} className="btn-next center-aligned copylinkbutton btn-min-width" onClick={() => this._deleteTag(items.id)}>Delete</Button></td> }
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        </table>
            
                                        <div className="pt20px">
                                            { /*loader show befoure load contents*/}
                                            {this.state.TagPageLoaderStatus && <LoaderContents />}
                                        </div>
                                            <div className="d-flex paginate-div">
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination justify-content-start"}
                                                    pageCount={this.state.Pagecount ?? 0}
                                                    pageClassName={"page-item page-item-none"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item page-item-none"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                    disabledLinkClassName={"disabled"} />
                                                    <div className="pagination-page">
                                                        Page {this.state.searchMdl.PageNumber} of {this.state.Pagecount}
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                                
                                
                        <div className="row">
                            <div className="col-xl-4 col-xxl-4">
                                <h1 className="h3 mb-3 mt-5 fw-normal"><strong>Manage Templates</strong></h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <div className="card flex-fill mt-4 border-light w-100  dashboard-card-newDesign">
                                    <div className="card-body low-rows-fix">
                                        <div className="table-header">                                       
                                            <div className="button-container" id="nav-tab" role="tablist">
                                                <button className="btn btn-newDesign btn-header active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => this._AllEmailMessageTemplatesData()}>Email message templates</button>
                                                <button className="btn btn-newDesign-foil btn-header" id="nav-profile-tab" type="button" role="tab" onClick={() => this._AllTestMessageTemplatesData()}>Text message templates</button>
                                            </div>
                                            <div className="dashboard-options">
                                                <div className="search-align bg-white border-white dashboard-input-size-lg">
                                                    <input type="text" className="form-control text-search-newDesign" onChange={this.handleTemplateSearchData} id="search" placeholder="Search by Description" />
                                                    {this.state.SaveLoaderStatus &&
                                                        <span style={{ position: "absolute", right: "222px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                                    }
                                                </div>
                                                <div className="search-align bg-white border-white">
                                                    <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleTemplateShowChange}>
                                                        <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <div className="row dashboard-card-newDesign">
                                                <div className="w-100 table-responsive">
                                                    <table className="table insured-table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Template Name</th>
                                                                <th>Template Value</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {AllTemplatesData.map((items, index) => (
                                                                items.is_EmailMessageTemplete &&
                                                                <tr key={index}>
                                                                    <td>{items.templateName}</td>
                                                                    <td>{Parser(items.templateValue)}</td>
                                                                    <td>
                                                                        <button type="button" id={items.id} className="btn btn-newDesign" onClick={() => this._editTemplate(items.id, AllCompleteTagData, this.state.SubDomain.SubDomainId)}>
                                                                            <span>
                                                                                Edit
                                                                            </span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="pt20px">
                                                    { /*loader show befoure load contents*/}
                                                    {this.state.TemplatePageLoaderStatus && <LoaderContents />}
                                                </div>
                                            </div>
                                        </div>
                                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                <div className="w-100 table-responsive">
                                                    <table className="table insured-table mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Template Name</th>
                                                                    <th>Template Value</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {AllTemplatesData.map((items, index) => (
                                                                    items.is_TextMessageTemplete &&
                                                                    <tr key={index}>
                                                                        <td>{items.templateName}</td>
                                                                        <td>{Parser(items.templateValue)}</td>
                                                                        <td><button type="button" id={items.id} className="btn btn-newDesign" onClick={() => this._editTemplate(items.id, AllCompleteTagData, this.state.SubDomain.SubDomainId)}><span>Edit</span></button></td>
                                                                    </tr>
                                                                ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                   </div>
                                                    <div className="pt20px">
                                                        { /*loader show befoure load contents*/}
                                                        {this.state.TemplatePageLoaderStatus && <LoaderContents />}
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="d-flex paginate-div">
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={"pagination justify-content-start"}
                                                pageCount={this.state.Pagecount ?? 0}
                                                pageClassName={"page-item page-item-none"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item page-item-none"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                disabledLinkClassName={"disabled"} />
                                                <div className="pagination-page">
                                                    Page {this.state.searchMdl.PageNumber} of {this.state.Pagecount}
                                                </div>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SiteFooter /> 
                </div>

                { /*model for Add/Edit new tag */}
                <div className="modal fade" tabIndex="-1" id="modalAddNewTag" aria-labelledby="AddNewTag" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center pt-4 border-0">
                                <h5 className="modal-title text-center">{this.state.ModelTitle ?? ""}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="pt20px">
                                    { /*loader show before load contents*/}
                                    {this.state.SaveLoaderStatus && <LoaderContents />}
                                </div>
                                <form className="position-relative step-register" id="frmaddnewtag" onKeyDown={this._AddEditTag_EnterButton}>
                                    <fieldset className="step-form">
                                        <h2 className="mb-5 text-center">Enter Tag Name</h2>
                                        <div className="form-floating input-wrapper mx-auto mb-3">
                                            <input className={this.state.Errors['IsEmpty_TagName'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Tag name" ref="TagName" type="text" defaultValue={this.state.TagMdl.tagName ?? ''} id="TagName" required />
                                            <label htmlFor="TagName">Tag name</label>
                                            {this.state.Errors['IsEmpty_TagName'] === true ? <div className="has-err-msg">{this.state.Errors['TagNameEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="form-floating input-wrapper mx-auto mb-3">
                                            <input className={this.state.Errors['IsEmpty_TagValue'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Tag value" ref="TagValue" type="text" defaultValue={this.state.TagMdl.tagValue ?? ''} id="TagValue" required />
                                            <label htmlFor="TagValue">Tag Value</label>
                                            {this.state.Errors['IsEmpty_TagValue'] === true ? <div className="has-err-msg">{this.state.Errors['TagValueEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="d-flex justify-content-center mt-4">
                                            <div className="button-container">
                                                <button type="button" id="btncloseMdl" data-bs-dismiss="modal" aria-label="Close" className="btn btn-newDesign-reverse">
                                                    <span className="label">Close</span>
                                                </button>
                                                <button type="button" className="btn-newDesign" onClick={() => this._AddEditTag()}>
                                                    <span className="label">Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

                { /*model for Edit Templates */}
                <div className="modal fade" tabIndex="-1" id="modalEditTemplate" aria-labelledby="EditTemplate" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header pt-4 border-0">
                                <h5 className="modal-title text-center">{this.state.ModelTitle ?? ""}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="pt20px">
                                    { /*loader show before load contents*/}
                                    {this.state.SaveLoaderStatus && <LoaderContents />}
                                </div>
                                <form className="position-relative step-register" id="frmedittemplate" onKeyDown={this._EditTemplate_EnterButton} action="https://d1.ckeditor.com/savetextarea/savetextarea.php" method="post">
                                    <fieldset className="step-form">
                                        
                                        <div className="input-wrapper mx-auto mb-3">
                                            <textarea cols="25" rows = "15" type="text" defaultValue={this.state.TemplateMdl.templateValue ?? ''} id="editor2" name="editor2" required />
                                        </div>
                                        <div className="button-container">
                                            <button type="button" id="btncloseMdl" data-bs-dismiss="modal" aria-label="Close" className="btn btn-newDesign-reverse">
                                                <span className="label">Close</span>
                                            </button>
                                            <button type="button" className="btn btn-newDesign" onClick={() => this._SaveTemplate(this.state.SubDomain.SubDomainId)}>
                                                <span className="label">Submit</span>
                                            </button>
                                        </div>
                                    </fieldset>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
export default ManageEmailTextTemplates;